import axios from 'axios';
import moment from 'moment';
import addExpenseActionTypes from './addExpenseActionTypes';
import apiService from '../../../shared/service/apiService';
import history from '../../../shared/service/history';
import constants from '../../../shared/constants';
import {
  getAddExpenseDetails,
  getCategories,
  selectItemData,
  selectNewItemFormValues,
  selectExpenseUpdateResponse,
  selectExpenseApprovalResponse,
} from './addExpenseSelector';
import { getValue } from '../../../shared/service/localStorage';
import { setToast } from '../../home/homeActions';

const expensePending = () => {
  return {
    type: addExpenseActionTypes.TASK_EXPENSE.pending,
  };
};

const expenseFulfilled = () => {
  return {
    type: addExpenseActionTypes.TASK_EXPENSE.fulfilled,
  };
};

const expenseRejected = () => {
  return {
    type: addExpenseActionTypes.TASK_EXPENSE.rejected,
  };
};

const getTaskExpense = task => {
  return dispatch => {
    dispatch(expensePending());
    const fetchExpenseForApprovalUrl = apiService.endpoints.app.generateFetchExpenseForApprovalUrl();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_EXPENSE_FOR_APPROVAL,
        id: task.claimId,
      },
    };

    return axios
      .post(fetchExpenseForApprovalUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addExpenseActionTypes.SET_ENTITY_DETAILS,
          entityDetails: response.data,
        });
        dispatch({
          type: addExpenseActionTypes.SET_EXPENSE_FORM_VALUES,
          expenseFormValues: response.data,
        });
        dispatch({
          type: addExpenseActionTypes.SET_ITEM_DATA,
          itemData: response.data && response.data.itemsList,
        });
        dispatch(expenseFulfilled());
      })
      .catch(err => {
        dispatch(expenseRejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchAddExpenseDetails = () => {
  return dispatch => {
    dispatch({ type: addExpenseActionTypes.ADD_EXPENSE_DETAILS.pending });

    const fetchAddExpenseDetailsUrl = apiService.endpoints.app.generateFetchAddExpenseDetailsUrl();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_ADV_OR_CLAIM_DETAIL,
        entityList: [],
      },
    };

    return axios
      .post(fetchAddExpenseDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addExpenseActionTypes.SET_ADD_EXPENSE_DETAILS,
          addExpenseDetails: response.data,
        });
        dispatch({
          type: addExpenseActionTypes.SET_DYNAMIC_COLUMNS,
          dynamicColumns: response.data && response.data.dynamicColumns,
        });
        dispatch({ type: addExpenseActionTypes.ADD_EXPENSE_DETAILS.fulfilled });
      })
      .catch(err => {
        dispatch({ type: addExpenseActionTypes.ADD_EXPENSE_DETAILS.rejected });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchEntityDetails = (entityId, projectCodeId = '') => {
  return (dispatch, getState) => {
    const fetchEntityDetailsUrl = apiService.endpoints.app.generateFetchEntityDetailsUrl();
    dispatch({
      type: addExpenseActionTypes.IS_FETCHING_ENTITY_DETAILS,
      isFetchingEntityDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        entityId,
        projectCodeId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        workflowTypeId: getAddExpenseDetails(getState()).workflowTypeId,
        allViewAccess:
          getValue(constants.LOCAL_STORAGE.ALL_VIEW_ACCESS) ||
          constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_ADV_OR_CLAIM_ENTITY_CHANGE_TYPE_API,
      },
    };

    return axios
      .post(fetchEntityDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addExpenseActionTypes.SET_ENTITY_DETAILS,
          entityDetails: response.data,
        });
        dispatch({
          type: addExpenseActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addExpenseActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchAdvanceNumber = (view, entity) => {
  return dispatch => {
    const fetchEntityDetailsUrl = apiService.endpoints.app.generateFetchAdvanceDetailsUrl();
    dispatch({
      type: addExpenseActionTypes.IS_FETCHING_ADVANCE_DETAILS,
      isFetchingAdvanceDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        searchStr: '',
        entityId: entity,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        viewId: view,
        apiType: constants.API_TYPES.FETCH_ADVANCE_DETAILS_API,
      },
    };

    return axios
      .post(fetchEntityDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addExpenseActionTypes.SET_ADVANCE_DETAILS,
          advanceDetails: response.data,
        });
        dispatch({
          type: addExpenseActionTypes.IS_FETCHING_ADVANCE_DETAILS,
          isFetchingAdvanceDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addExpenseActionTypes.IS_FETCHING_ADVANCE_DETAILS,
          isFetchingAdvanceDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const setItemData = (data, indexExists, index, history) => {
  return (dispatch, getState) => {
    const itemData = selectItemData(getState());
    let newData = [...itemData];
    if (indexExists) {
      newData[index] = data;
    } else {
      newData = [...itemData, data];
      newData[newData.length - 1].attachmentRef = newData.length;
    }
    dispatch({ type: addExpenseActionTypes.SET_ITEM_DATA, itemData: newData });
    history.goBack();
  };
};

const deleteInItemData = (index, history) => {
  return (dispatch, getState) => {
    const itemData = selectItemData(getState());
    const newData = [...itemData];
    newData.splice(index, 1);
    dispatch({ type: addExpenseActionTypes.SET_ITEM_DATA, itemData: newData });
    history.goBack();
  };
};

const resetItemData = () => {
  return dispatch => {
    dispatch({ type: addExpenseActionTypes.RESET_ITEM_DATA });
  };
};

const setNewItemFormValues = newItemFormValues => {
  return dispatch => {
    dispatch({
      type: addExpenseActionTypes.SET_NEW_ITEM_FORM_VALUES,
      newItemFormValues,
    });
  };
};

const setNewExpenseFormValues = values => {
  return dispatch => {
    dispatch({
      type: addExpenseActionTypes.SET_NEW_EXPENSE_FORM_VALUES,
      newExpenseFormValues: values,
    });
  };
};

const resetNewExpenseFormValues = () => {
  return dispatch => {
    dispatch({ type: addExpenseActionTypes.RESET_NEW_EXPENSE_FORM_VALUES });
  };
};

const setSelectedCategory = selectedCategory => {
  return (dispatch, getState) => {
    dispatch({
      type: addExpenseActionTypes.SET_SELECTED_CATEGORY,
      selectedCategory,
    });
    const initialValues = selectNewItemFormValues(getState());
    const categories = getCategories(getState());
    const cat = categories.find(cat => {
      return cat.id === selectedCategory.value;
    });
    const category = cat.text || '';
    const values = { ...initialValues, category };
    dispatch(setNewItemFormValues(values));
  };
};

const setExpenseUpdateResponse = expense => {
  return {
    type: addExpenseActionTypes.SET_EXPENSE_UPDATE_RESPONSE,
    expense,
  };
};

const updateExpensePending = () => {
  return {
    type: addExpenseActionTypes.UPDATE_EXPENSE.pending,
  };
};

const updateExpenseFulfilled = () => {
  return {
    type: addExpenseActionTypes.UPDATE_EXPENSE.fulfilled,
  };
};

const updateExpenseFailed = () => {
  return {
    type: addExpenseActionTypes.UPDATE_EXPENSE.failed,
  };
};

const setErrorMessage = message => {
  return {
    type: addExpenseActionTypes.SET_ERROR_MESSAGE,
    message,
  };
};

const setSiteCodeList = siteCodes => {
  return {
    type: addExpenseActionTypes.SET_SITE_CODE_LIST,
    siteCodes: siteCodes || [],
  };
};

const setSelectedTask = selectedTask => {
  return {
    type: addExpenseActionTypes.SET_SELECTED_TASK,
    selectedTask: selectedTask || {},
  };
};

const submitExpense = (values, history) => {
  const itemsJson = values.items.map((item, index) => {
    return {
      ...item,
      date: moment(item.date).format('MM/DD/YYYY'),
      attachmentRef: index,
    };
  });
  return (dispatch, getState) => {
    const updateExpenseUrl = apiService.endpoints.app.generateUpdateExpenseUrl();

    dispatch(updateExpensePending());
    const payload = {
      advanceClaimDetailsId: values.advanceClaimDetailsId,
      companyId:
        getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
      userId:
        getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
      apiType: constants.API_TYPES.UPDATE_ADV_OR_CLAIM_TYPE_API,
      submitType: values.submitType,
      workflowId: values.workflowId,
      advanceRequestId: values.advanceRequestNo,
      viewId: values.viewId,
      entityId: values.entityId,
      advanceOrClaim: values.type === 'advance' ? 0 : 1,
      advanceAmount: values.advanceAmount,
      siteCodeIds: values.siteCodeId.map(element => element.value),
      needByDate: moment(values.needByDate).format('MM/DD/YYYY'),
      itemJson: itemsJson,
      totalAmount: values.totalAmount,
      projectCodeId: values.projectCodeId,
      comments: values.comments,
      dynamicColumns: JSON.stringify(values.dynamicFields),
    };

    const formData = new FormData();
    if (values.attachment)
      formData.append('files', values.attachment, values.attachment.name);
    else if (values.items) {
      values.items.forEach(item => {
        if (item.file) formData.append('files', item.file, item.file.name);
      });
    }

    formData.append('payload', JSON.stringify(payload));
    formData.append(
      'token',
      getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
    );
    formData.append(
      'loadBalancer',
      getValue(constants.LOCAL_STORAGE.LOADBALANCER) || constants.EMPTY_STRING,
    );

    return axios
      .post(updateExpenseUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        dispatch(setExpenseUpdateResponse(response.data));
        history.goBack();
        dispatch(updateExpenseFulfilled());
        const expenseUpdateResponse = selectExpenseUpdateResponse(getState());
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: expenseUpdateResponse.actionMsg
              ? expenseUpdateResponse.actionMsg
              : 'Expense Updated successfully',
            isOpen: true,
          }),
        );
      })
      .catch(err => {
        dispatch(updateExpenseFailed());
        if (err.response && err.response.status === 401) {
          history.push('/login');
          dispatch(setErrorMessage(constants.SESSION_EXPIRED));
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(setErrorMessage(message));
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const setExpenseApprovalResponse = expense => {
  return {
    type: addExpenseActionTypes.SET_EXPENSE_APPROVAL_RESPONSE,
    expense,
  };
};

const approveRejectExpense = (values, expenseValues, items) => {
  return (dispatch, getState) => {
    dispatch(expensePending());
    const fetchApproveRejectURL = apiService.endpoints.app.generateFetchApproveRejectURL();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.APPROVE_REJECT_EXPENSE,
        submitType: values.submitType,
        workflowId: expenseValues.payload.workflowId,
        workflowAuditId: expenseValues.payload.workflowAuditId,
        taskId: expenseValues.payload.taskId,
        seqFlow: expenseValues.payload.seqFlow,
        auditTrackId: expenseValues.payload.auditTrackId,
        processInstanceId: expenseValues.payload.processInstanceId,
        claimId: expenseValues.payload.claimId,
        advanceRequestId: expenseValues.payload.advanceRequestId,
        advanceClaimDetailsId: expenseValues.payload.advanceClaimDetailsId,
        dynamicColumns: expenseValues.payload.dynamicColumns,
        viewId: expenseValues.payload.viewId,
        entityId: expenseValues.payload.entityId,
        advanceOrClaim:
          expenseValues.payload.advanceOrClaim === 'Advance' ? 0 : 1,
        needByDate: expenseValues.payload.needByDate,
        totalAmount: values.totalAmount || expenseValues.totalAmount,
        categoryId: expenseValues.payload.categoryId,
        fileBean: expenseValues.payload.fileBean,
        projectCodeId: expenseValues.payload.projectCodeId,
        siteCodeIds:
          (values.siteCodeId &&
            values.siteCodeId.map(element => element.value)) ||
          expenseValues.siteCodeId,
        documentBean: expenseValues.payload.documentBean,
        status: expenseValues.payload.status,
        itemJson: JSON.stringify(
          items.map((item, i) => {
            return {
              ...item,
              file: '',
              categoryName: item.category,
              comments: item.description,
              attachmentRef: i,
              uploadedFileName: '',
            };
          }),
        ),
        comments: values.comments,
      },
    };

    return axios
      .post(fetchApproveRejectURL, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch(setExpenseApprovalResponse(response.data));
        history.goBack();
        dispatch(expenseFulfilled());
        const expenseApprovalResponse = selectExpenseApprovalResponse(
          getState(),
        );
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: expenseApprovalResponse.actionMsg
              ? expenseApprovalResponse.actionMsg
              : 'Expense Updated successfully',
            isOpen: true,
          }),
        );
      })
      .catch(err => {
        dispatch(expenseRejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export {
  fetchEntityDetails,
  fetchAdvanceNumber,
  fetchAddExpenseDetails,
  approveRejectExpense,
  setItemData,
  resetItemData,
  submitExpense,
  setNewExpenseFormValues,
  resetNewExpenseFormValues,
  deleteInItemData,
  setSelectedCategory,
  setNewItemFormValues,
  setSiteCodeList,
  setSelectedTask,
  getTaskExpense,
};
