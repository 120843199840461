import axios from 'axios';
import poActionTypes from './actionTypes';
import apiService from '../../../shared/service/apiService';
import constants from '../../../shared/constants';
import { getValue } from '../../../shared/service/localStorage';
import history from '../../../shared/service/history';
import { selectPOApprovalResponse } from './selector';
import { setToast } from '../../home/homeActions';

const poPending = () => {
  return {
    type: poActionTypes.PO.pending,
  };
};

const poFulfilled = () => {
  return {
    type: poActionTypes.PO.fulfilled,
  };
};

const poRejected = () => {
  return {
    type: poActionTypes.PO.rejected,
  };
};

const updatePOPending = () => {
  return {
    type: poActionTypes.UPDATE_PO.pending,
  };
};

const updatePOFulfilled = () => {
  return {
    type: poActionTypes.UPDATE_PO.fulfilled,
  };
};

const updatePORejected = () => {
  return {
    type: poActionTypes.UPDATE_PO.rejected,
  };
};

const setErrorMessage = message => {
  return {
    type: poActionTypes.SET_ERROR_MESSAGE,
    message,
  };
};

const setPO = po => {
  return {
    type: poActionTypes.SET_PO,
    po,
  };
};

const setPOApprovalResponse = po => {
  return {
    type: poActionTypes.SET_PO_APPROVAL_RESPONSE,
    po,
  };
};

const getPO = task => {
  return dispatch => {
    const poUrl = apiService.endpoints.app.generatePOUrl();
    dispatch(poPending());

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        id: task.poRequestId,
        companyId: task.companyId,
        requisitionMasterId: task.poRequestMasterId,
        parentId: task.poParentId,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        loggedInSupplierId: constants.EMPTY_STRING,
        apiType: constants.API_TYPES.APPROVE_PO_REQ_TYPE_API,
      },
    };

    return axios
      .post(poUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch(setPO(response.data));
        dispatch(poFulfilled());
      })
      .catch(err => {
        dispatch(poRejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
          dispatch(setErrorMessage(constants.SESSION_EXPIRED));
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(setErrorMessage(message));
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const updatePO = (values, po) => {
  return (dispatch, getState) => {
    const updatePOUrl = apiService.endpoints.app.generateUpdatePOUrl();
    dispatch(updatePOPending());

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        requisitionMasterId: po.requisitionMasterId,
        requisitionId: po.requisitionId,
        submitType: values.submitType,
        companyId: values.companyId || 0,
        userId:
          parseInt(getValue(constants.LOCAL_STORAGE.USER_ID)) ||
          constants.EMPTY_STRING,
        apiType: constants.API_TYPES.UPDATE_PO_REQ_TYPE_API,
        comments: values.comments,
        parentId: po.parentId,
      },
    };

    return axios
      .post(updatePOUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch(setPOApprovalResponse(response.data));
        history.goBack();
        dispatch(updatePOFulfilled());
        const poApprovalResponse = selectPOApprovalResponse(getState());
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: poApprovalResponse.actionMsg
              ? poApprovalResponse.actionMsg
              : 'PO Updated successfully',
            isOpen: true,
          }),
        );
      })
      .catch(err => {
        dispatch(updatePORejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
          dispatch(setErrorMessage(constants.SESSION_EXPIRED));
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(setErrorMessage(message));
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export { getPO, updatePO, setPOApprovalResponse };
