// import {get} from 'lodash';

const getEntityList = () => {
  const entityList = JSON.parse(localStorage.getItem('entityList'));
  return entityList.map(entity => {
    return {
      label: entity.entityName,
      value: entity.entityId,
      country: entity.country,
      state: entity.state,
    };
  });
};
const getCurrencies = () => JSON.parse(localStorage.getItem('currencies'));

export { getEntityList, getCurrencies };
