import seamlessImmutable from 'seamless-immutable';
import * as moment from 'moment';
import setPromiseState from '../../../shared/service/promiseState';

const addExpenseInitialState = seamlessImmutable({
  promise: {
    addExpenseDetails: setPromiseState(),
    updateExpense: setPromiseState(),
    taskExpense: setPromiseState(),
  },
  addExpenseDetails: {},
  isFetchingEntityDetails: false,
  viewList: [],
  workflowList: [],
  siteCodeList: [],
  projectCodeList: [],
  advanceList: [],
  valueObj: {},
  isFetchingItemData: false,
  itemData: [],
  dynamicColumns: [],
  newExpenseFormValues: {
    type: '',
    entityId: '',
    viewId: '',
    workflowId: '',
    projectCodeId: '',
    siteCodeId: [],
    currencyId: '',
    needByDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
    attachment: '',
    comments: '',
    advanceRequestNo: '',
    advanceAmount: 0,
    submitType: '',
    advanceClaimDetailsId: 0,
  },
  newItemFormValues: {
    categoryId: 0,
    description: '',
    merchant: '',
    date: moment(new Date()).format(moment.HTML5_FMT.DATE),
    amount: '',
    file: null,
    billable: false,
    lineItemId: 0,
    comments: '',
    attachmentRef: 0,
  },
  selectedCategory: {},
  expenseApprovalResponse: {},
  expenseFormValues: {},
});

export default addExpenseInitialState;
