import { combineReducers } from 'redux';
import loginReducer from '../../components/login/loginReducer';
import poReducer from '../../components/tasks/purchaseOrder/reducer';
import tasksReducer from '../../components/tasks/myTasks/tasksRducer';
import pickUpReducer from '../../components/tasks/pickup/pickUpReducer';
import invoiceReducer from '../../components/tasks/invoice/invoiceReducer';
import assetReducer from '../../components/tasks/asset/assetReducer';
import homeReducer from '../../components/home/homeReducer';
import addExpenseReducer from '../../components/expense/addExpense/addExpenseReducer';
import expenseReducer from '../../components/expense/expenseReducer';
import timesheetsReducer from '../../components/timesheets/timesheetsReducer';
import addTimesheetsReducer from '../../components/timesheets/addTimesheets/addTimesheetsReducer';
import salesOrderReducer from '../../components/salesorder/reducer';
import addSalesOrderReducer from '../../components/salesorder/addSalesOrder/addSalesOrderReducer';

const app = combineReducers({
  home: homeReducer,
  login: loginReducer,
  tasks: tasksReducer,
  po: poReducer,
  pickUp: pickUpReducer,
  invoice: invoiceReducer,
  asset: assetReducer,
  addExpense: addExpenseReducer,
  expense: expenseReducer,
  timesheets: timesheetsReducer,
  addTimesheets: addTimesheetsReducer,
  salesOrder: salesOrderReducer,
  addSalesOrder: addSalesOrderReducer,
});

export default app;
