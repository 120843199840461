import React from 'react';
import PropTypes from 'prop-types';
import AdvanceItem from './AdvanceItem';
import ClaimItem from './ClaimItem';
import constants from '../constants';

const ExpenseItemList = props => {
  const { t, items, values, approvalMode } = props;
  return (
    <div className="ExpenseItemList">
      <p className="ExpenseItemList--title">Items</p>
      {items.map((item, i) => {
        return values.type === constants.type.claim ? (
          <ClaimItem
            item={item}
            t={t}
            values={values}
            key={i}
            index={i}
            approvalMode={approvalMode}
          />
        ) : (
          <AdvanceItem
            item={item}
            t={t}
            values={values}
            key={i}
            index={i}
            approvalMode={approvalMode}
          />
        );
      })}
    </div>
  );
};

ExpenseItemList.propTypes = {
  items: PropTypes.array.isRequired,
};

ExpenseItemList.defaultProps = {
  items: [],
};

export default ExpenseItemList;
