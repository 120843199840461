const addSalesOrderActionTypes = {
  ADD_SALESORDER_DETAILS: {
    pending: 'addSalesOrderDetails/pending',
    fulfilled: 'addSalesOrderDetails/fulfilled',
    rejected: 'addSalesOrderDetails/rejected',
  },
  UPDATE_SALESORDER: {
    pending: 'updateSalesOrder/pending',
    fulfilled: 'updateSalesOrder/fulfilled',
    failed: 'updateSalesOrder/failed',
  },
  TASK_SALESORDER: {
    pending: 'taskSalesOrder/pending',
    fulfilled: 'taskSalesOrder/fulfilled',
    rejected: 'taskSalesOrder/rejected',
  },
  SET_ADD_SALESORDER_DETAILS: 'SET_ADD_SALESORDER_DETAILS',
  IS_FETCHING_ENTITY_DETAILS: 'IS_FETCHING_ENTITY_DETAILS',
  SET_ENTITY_DETAILS: 'SET_ENTITY_DETAILS',
  IS_FETCHING_CUSTOMER_DETAILS: 'IS_FETCHING_CUSTOMER_DETAILS',
  SET_CUSTOMER_DETAILS: 'SET_CUSTOMER_DETAILS',
  IS_FETCHING_VIEW_DETAILS: 'IS_FETCHING_VIEW_DETAILS',
  SET_VIEW_DETAILS: 'SET_VIEW_DETAILS',
  IS_FETCHING_SEARCH_ITEM_DETAILS: 'IS_FETCHING_SEARCH_ITEM_DETAILS',
  SET_SEARCH_ITEM_DETAILS: 'SET_SEARCH_ITEM_DETAILS',
  SET_ITEM_DATA: 'SET_ITEM_DATA',
  RESET_ITEM_DATA: 'RESET_ITEM_DATA',
  SET_DYNAMIC_COLUMNS: 'SET_DYNAMIC_COLUMNS',
  RESET_DYNAMIC_COLUMNS: 'RESET_DYNAMIC_COLUMNS',
  IS_FETCHING_ADVANCE_DETAILS: 'IS_FETCHING_ADVANCE_DETAILS',
  SET_ADVANCE_DETAILS: 'SET_ADVANCE_DETAILS',

  SET_NEW_SALESORDER_FORM_VALUES: 'SET_NEW_SALESORDER_FORM_VALUES',
  RESET_NEW_SALESORDER_FORM_VALUES: 'RESET_NEW_SALESORDER_FORM_VALUES',

  SET_NEW_ITEM_FORM_VALUES: 'SET_NEW_ITEM_FORM_VALUES',

  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',

  SET_SALESORDER_UPDATE_RESPONSE: 'SET_SALESORDER_UPDATE_RESPONSE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_SALESORDER: 'SET_SALESORDER',

  SET_SITE_CODE_LIST: 'SET_SITE_CODE_LIST',
  SET_SELECTED_TASK: 'SET_SELECTED_TASK',
  SET_SALESORDER_FORM_VALUES: 'SET_SALESORDER_FORM_VALUES',
  SET_SALESORDER_APPROVAL_RESPONSE: 'SET_SALESORDER_APPROVAL_RESPONSE',
};

export default addSalesOrderActionTypes;
