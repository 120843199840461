export default {
  login: {
    placeholders: {
      userName: 'Enter your Username.',
      password: 'Enter your Password.',
    },
    tooltip: {
      hide: 'Hide',
      show: 'Show',
    },
    password: 'Password',
    login: 'Login',
    footer: '© iPact 2018. All rights reserved.',
  },
  labels: {
    type: 'Type',
  },
  expenses: {
    header: 'Expense',
    addItem: {
      type: 'Type',
      entity: 'Entity',
      view: 'View',
      siteCodes: 'Site Codes',
      workflow: 'Workflow',
      currency: 'Currency',
      needByDate: 'Need by Date',
      attachment: 'Attachment',
      advanceRequestNo: 'Advance Request #',
      advanceAmount: 'Advance Amount',
      comments: 'Comments',
      advance: 'Advance',
      claim: 'Claim',
      projectCode: 'Project code',
      totalAmount: 'Total Amount',
      placeholders: {
        type: 'Select a Type',
        entity: 'Select an Entity',
        view: 'Select a View',
        siteCodes: ' Select Site Codes',
        workflow: 'Select a Workflow',
        currency: 'Select Currency',
        category: 'Select a Category',
        projectCodes: 'Select Project code',
        advanceRequestNo: 'Select Advace Request #',
      },
      validations: {
        type: 'Type is Required',
        entity: 'Entity is Required',
        view: 'View is Required',
        projectCodes: 'Project code is Required',
        workflow: 'Workflow is Required',
        currency: 'Currency is Required',
        needByDate: 'Need by Date is Required',
        minNeedByDate: 'Date should be in future.',
        items: 'Please add atleast one Item',
        totalAmount:
          'Total amount of all Items cannot be less than Advace Amount.',
      },
      newItem: {
        category: 'Category',
        selectCategory: 'Select Category',
        description: 'Description',
        merchant: 'Merchant',
        billable: 'Billable',
        amount: 'Amount',
        file: 'Upload File',
        date: 'Date',
        submit: 'Add Item',
        update: 'Update Item',
        validations: {
          category: 'Category is required',
          description: 'Description is required',
          merchant: 'Merchant is required',
          amount: 'Amount is required',
          positiveAmount: 'Amount must be greater than 0',
          file: 'File is required',
          date: 'Date is required',
          exceedsCurDate: 'Date cannot exceed current date',
        },
      },
      billable: 'Billable ',
      nonBillable: 'Non Billable ',
    },
  },
  timesheets: {
    header: 'Timesheets',
    addItem: {
      entity: 'Entity',
      projectCode: 'Project Code',
      workflow: 'Workflow',
      fromDate: 'From Date',
      toDate: 'To Date',
      placeholders: {
        entity: 'Select an Entity',
        projectCode: 'Select a Project Code',
        workflow: 'Select a Workflow',
      },
      validations: {
        entity: 'Entity is Required',
        projectCode: 'Project Code is Required',
        workflow: 'Workflow is Required',
        fromDate: 'From Date is Required',
        toDate: 'To Date is Required',
        hours: 'Please add hours for all the days',
      },
    },
    buttons: {
      addHours: 'Add Hours',
    },
  },
  salesOrder: {
    header: 'Sales Order',
    addItem: {
      type: 'Sales Order Type',
      entity: 'Entity',
      view: 'View',
      siteCodes: 'Site Codes',
      workflow: 'Workflow',
      currency: 'Currency',
      startDate: 'Start Date',
      endDate: 'End Date',
      attachment: 'Attachment',
      advanceAmount: 'Advance Amount',
      comments: 'Comments',
      customer: 'Customer',
      customerPhone: 'Customer Contact',
      poNo: 'PO #',
      poDate: 'PO Date',
      quotation: 'Quotation #',
      orderSeq: 'Order Sequence',
      totalAmount: 'Total Amount',
      chooseFile: 'Choose Files...',
      totalNetPrice: 'Total Net Price',
      totalTax: 'Total Tax',
      grandTotal: 'Grand Total',
      placeholders: {
        customerPhone: 'Enter Customer Contact #',
        poNo: 'Enter PO #',
        advanceAmount: 'Enter Advance amount',
        itemNo: 'Enter Item Number',
        desc: 'Enter Item description',
        uom: 'Enter UOM',
        hsnCode: 'Enter HSN/SAC code',
      },
      validations: {
        type: 'Type is Required',
        entity: 'Entity is Required',
        view: 'View is Required',
        workflow: 'Workflow is Required',
        orderSeq: 'Order Sequence is Required',
        poNo: 'PO # is Required',
        currency: 'Currency is Required',
        poDate: 'PO Date is Required',
        maxPoDate: 'PO Date cannot be in future.',
        customer: 'Customer Needs to be selected.',
        items: 'Please add atleast one Item',
        invalidItem: 'Selected Items contains invalid price',
      },
      newItem: {
        itemNo: 'Item #',
        item: 'Item',
        description: 'Description',
        quantity: 'Quantity',
        sellPriceWithTax: 'Sell Price With Tax',
        unitPrice: 'Unit Price',
        uom: 'UOM',
        igst: 'IGST %',
        cgst: 'CGST %',
        sgst: 'SGST %',
        vat: 'VAT %',
        hsn: 'HSN/SAC',
        update: 'Update',
        success: 'Add Item',
        netPrice: 'Net Price',
        taxAmount: 'Tax Amount',
        totalAmount: 'Total Amount',
        validations: {
          itemNo: 'Item # is required',
          desc: 'Item Description is required',
          qty: 'Quantity is required',
          minQty: 'Quantity must be more than 0',
          price: 'Price is required',
          minPrice: 'Price cannot be less than 1',
          uom: 'UOM is required',
          hsnCode: 'HSN/SAC is required',
          igst: 'IGST % is required',
          minIgst: 'IGST % cannot be less than 0%',
          maxIgst: 'IGST % cannot be more than 100%',
          cgst: 'CGST % is required',
          minCgst: 'CGST % cannot be less than 0%',
          maxCgst: 'CGST % cannot be more than 100%',
          sgst: 'SGST % is required',
          minSgst: 'SGST % cannot be less than 0%',
          maxSgst: 'SGST % cannot be more than 100%',
          vat: 'VAT % is required',
          minVat: 'VAT % cannot be less than 0%',
          maxVat: 'VAT % cannot be more than 100%',
        },
      },
      billable: 'Billable ',
      nonBillable: 'Non Billable ',
    },
  },
  commons: {
    browse: 'Browse',
    chooseFile: 'Choose File...',
    submit: 'Submit',
    reset: 'Reset',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    approve: 'Approve',
    reject: 'Reject',
    back: 'Go Back',
    print: 'Print',
  },
};
