import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './style.scss';
import { Button, Callout, Intent, Spinner, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FormInput from '../../common/form/FormInput';
import FormSelect from '../../common/form/FormSelect';
import FormFileInput from '../../common/form/FormFileInput';
import FormTextArea from '../../common/form/FormTextArea';
import constants from './constants';
import localConstants from '../../../shared/constants';
import ExpenseItemList from './itemsList/ExpenseItemList';
import { getValue } from '../../../shared/service/localStorage';
import FormMultiSelect from '../../common/form/FormMultiSelect';
import DynamicField from '../../common/dynamicFields/dynamicFields';

const form = newProps => {
  const {
    t,
    entityList,
    fetchEntityDetails,
    fetchAdvanceNumber,
    viewList,
    workflowList,
    projectCodeList,
    history,
    itemData,
    dynamicColumns,
    resetItemData,
    submitExpense,
    newExpenseFormValues,
    expenseFormValues,
    setNewExpenseFormValues,
    resetNewExpenseFormValues,
    advanceList,
    isFetchingEntityDetails,
    setSiteCodeList,
    siteCodeList,
    isEdit,
    approveRejectExpense,
  } = newProps;

  let showErrorMessage = false;

  const approvalMode = !!(isEdit && expenseFormValues);
  const approvalModeType = approvalMode ? expenseFormValues.type : '';
  const displayDynamicAttributes = attributes => {
    const values = [];
    Object.keys(attributes).forEach((key, i) => {
      values.push(
        <FormInput
          key={i}
          id={i.toString()}
          name={i}
          label={key}
          value={attributes[key]}
          large
          disabled
        />,
      );
    });
    return values;
  };

  const displayDynamicFields = (data, dynamicFields, props) => {
    const fields = [];
    data.forEach((field, i) => {
      fields.push(
        <DynamicField
          key={i}
          data={field}
          type={field.fieldType}
          dynamicFields={dynamicFields}
          prevProps={props}
        />,
      );
    });
    return fields;
  };

  const showProjectCodes =
    getValue(localConstants.LOCAL_STORAGE.ORG_ALL_VIEW_VISIBLE) === 'Y' &&
    getValue(localConstants.LOCAL_STORAGE.PROJ_CODE_VISIBLE) === 'Y';

  const getAmountValue = (id, setFieldValue) => {
    const temp = advanceList.find(item => item.value === id);
    if (temp) {
      setFieldValue('advanceAmount', temp.advanceAmount);
      setFieldValue('advanceClaimDetailsId', temp.advanceClaimDetailsId);
    } else setFieldValue('advanceAmount', 0);
  };

  const getTotalAmount = items =>
    items.reduce((acc, line) => {
      return acc + line.amount;
    }, 0);

  const types = [
    { value: 'advance', label: t('expenses.addItem.advance') },
    { value: 'claim', label: t('expenses.addItem.claim') },
  ];
  return (
    <div className="AddExpense container">
      <Callout
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title="Expense"
      >
        {approvalMode
          ? `Approve/Reject ${expenseFormValues.advanceOrClaim} 
              ${expenseFormValues.requestNo}`
          : 'Create new Advance / Claim.'}
      </Callout>
      <Formik
        initialValues={
          !approvalMode && {
            ...newExpenseFormValues,
            type: newExpenseFormValues.type
              ? newExpenseFormValues.type
              : types[0].value,
            items: itemData,
          }
        }
        onSubmit={async (values, { setSubmitting }) => {
          if (approvalMode) {
            values.totalAmount = await getTotalAmount(itemData);
            approveRejectExpense(values, expenseFormValues, itemData);
            resetItemData();
            resetNewExpenseFormValues();
            setSubmitting(false);
          } else {
            values.totalAmount = await getTotalAmount(values.items);
            if (values.totalAmount >= values.advanceAmount) {
              submitExpense(values, history);
              resetItemData();
              resetNewExpenseFormValues();
              setSubmitting(false);
              showErrorMessage = false;
            } else {
              setSubmitting(false);
              showErrorMessage = true;
            }
          }
        }}
        validationSchema={
          !approvalMode &&
          Yup.object().shape({
            type: Yup.string().required(t('expenses.addItem.validations.type')),
            entityId: Yup.string().required(
              t('expenses.addItem.validations.entity'),
            ),
            viewId: Yup.string().required(
              t('expenses.addItem.validations.view'),
            ),
            workflowId: Yup.string().required(
              t('expenses.addItem.validations.workflow'),
            ),
            needByDate: Yup.date().when('type', {
              is: 'advance',
              then: Yup.date()
                .required(t('expenses.addItem.validations.needByDate'))
                .min(
                  new Date(),
                  t('expenses.addItem.validations.minNeedByDate'),
                ),
            }),
            items: Yup.array().required(
              t('expenses.addItem.validations.items'),
            ),
            projectCodeId: Yup.string().required(
              t('expenses.addItem.validations.projectCodes'),
            ),
          })
        }
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form onSubmit={handleSubmit} className="AddExpense__Form">
              <Field
                className="AddExpense__Form--fields"
                id="type"
                label={t('expenses.addItem.type')}
                component={FormSelect}
                defaultValue={approvalMode && types[expenseFormValues.type]}
                onBlur={handleBlur}
                options={types}
                value={values.type}
                searchable={false}
                disabled={approvalMode}
                helperText={errors.type && touched.type ? errors.type : null}
                intent={
                  errors.type && touched.type ? Intent.DANGER : Intent.NONE
                }
                placeholder={t('expenses.addItem.placeholders.type')}
                onChange={e => {
                  resetItemData();
                  setFieldValue('type', e.value);
                }}
              />
              <Field
                className="AddExpense__Form--fields"
                id="entityId"
                label={t('expenses.addItem.entity')}
                component={FormSelect}
                defaultValue={approvalMode && expenseFormValues.entityList}
                disabled={approvalMode}
                onChange={e => {
                  if (e.value) {
                    fetchEntityDetails(e.value);
                    setFieldValue(
                      'advanceRequestNo',
                      newExpenseFormValues.advanceRequestNo,
                    );
                    setFieldValue(
                      'advanceAmount',
                      newExpenseFormValues.advanceAmount,
                    );
                    setFieldValue('entityId', e.value);
                  }
                }}
                onBlur={handleBlur}
                options={entityList}
                value={values.entityId}
                helperText={
                  errors.entityId && touched.entityId ? errors.entityId : null
                }
                intent={
                  errors.entityId && touched.entityId
                    ? Intent.DANGER
                    : Intent.NONE
                }
                placeholder={t('expenses.addItem.placeholders.entity')}
              />
              <Field
                className="AddExpense__Form--fields"
                id="viewId"
                label={t('expenses.addItem.view')}
                searchable
                component={FormSelect}
                defaultValue={approvalMode && expenseFormValues.viewList}
                onChange={e => {
                  fetchAdvanceNumber(e.value, values.entityId);
                  setSiteCodeList(e.project && e.project.siteCodes);
                  setFieldValue('siteCodeId', []);
                  setFieldValue('viewId', e.value);
                  setFieldValue(
                    'projectCodeId',
                    (e.project && e.project.value) || 0,
                  );
                }}
                loading={isFetchingEntityDetails}
                onBlur={handleBlur}
                options={viewList}
                value={values.viewId}
                disabled={!(viewList && viewList.length > 0) || approvalMode}
                helperText={
                  errors.viewId && touched.viewId ? errors.viewId : null
                }
                intent={
                  errors.viewId && touched.viewId ? Intent.DANGER : Intent.NONE
                }
                placeholder={t('expenses.addItem.placeholders.view')}
              />
              {showProjectCodes && (
                <Field
                  className="AddExpense__Form--fields"
                  id="projectCodeId"
                  label={t('expenses.addItem.projectCode')}
                  component={FormSelect}
                  searchable
                  defaultValue={
                    approvalMode && expenseFormValues.projectCodeList
                  }
                  closeMenuOnSelect
                  loading={isFetchingEntityDetails}
                  onChange={e => {
                    setFieldValue('siteCodeId', []);
                    setSiteCodeList(e.siteCodes);
                    setFieldValue('projectCodeId', e.value);
                  }}
                  onBlur={handleBlur}
                  options={projectCodeList}
                  value={values.projectCodeId}
                  disabled={
                    approvalMode ||
                    !(projectCodeList && projectCodeList.length > 0)
                  }
                  helperText={
                    errors.projectCodeId && touched.projectCodeId
                      ? errors.projectCodeId
                      : null
                  }
                  intent={
                    errors.projectCodeId && touched.projectCodeId
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {getValue(localConstants.LOCAL_STORAGE.PROJ_CODE_VISIBLE) ===
                'Y' && (
                  <Field
                    className="AddExpense__Form--fields"
                    id="siteCodeId"
                    label={t('expenses.addItem.siteCodes')}
                    component={FormMultiSelect}
                    loading={isFetchingEntityDetails}
                    onChange={e => {
                      setFieldValue('siteCodeId', e);
                    }}
                    values={values.siteCodeId}
                    onBlur={handleBlur}
                    options={
                      (approvalMode && expenseFormValues.siteCodeList) ||
                      siteCodeList
                    }
                    defaultValue={
                      approvalMode && expenseFormValues.defaultSiteCodes
                    }
                    disabled={
                      _.isEmpty(approvalMode && expenseFormValues.siteCodeList) &&
                      _.isEmpty(siteCodeList)
                    }
                    helperText={
                      errors.siteCodeId && touched.siteCodeId
                        ? errors.siteCodeId
                        : null
                    }
                    intent={
                      errors.siteCodeId && touched.siteCodeId
                        ? Intent.DANGER
                        : Intent.NONE
                    }
                  />
                )}
              <Field
                className="AddExpense__Form--fields"
                id="workflowId"
                label={t('expenses.addItem.workflow')}
                component={FormSelect}
                onChange={e => setFieldValue('workflowId', e.value)}
                onBlur={handleBlur}
                options={workflowList}
                value={values.workflowId}
                defaultValue={approvalMode && expenseFormValues.workflowList}
                disabled={!(workflowList.length > 0) || approvalMode}
                helperText={
                  errors.workflowId && touched.workflowId
                    ? errors.workflowId
                    : null
                }
                intent={
                  errors.workflowId && touched.workflowId
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              {(values.type === constants.type.advance ||
                approvalModeType === 0) && (
                  <Field
                    className="AddExpense__Form--fields"
                    id="needByDate"
                    label={t('expenses.addItem.needByDate')}
                    type="date"
                    component={FormInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    large
                    disabled={approvalMode}
                    value={
                      (approvalMode &&
                        moment(new Date(expenseFormValues.needByDate)).format(
                          moment.HTML5_FMT.DATE,
                        )) ||
                      values.needByDate
                    }
                    helperText={
                      errors.needByDate && touched.needByDate
                        ? errors.needByDate
                        : null
                    }
                    intent={
                      errors.needByDate && touched.needByDate
                        ? Intent.DANGER
                        : Intent.NONE
                    }
                  />
                )}
              {values.type === constants.type.advance && !approvalMode && (
                <Field
                  className="AddExpense__Form--fields"
                  id="attachment"
                  label={t('expenses.addItem.attachment')}
                  component={FormFileInput}
                  text={
                    values.attachment
                      ? values.attachment.name
                      : t('commons.chooseFile')
                  }
                  onChange={e => {
                    setFieldValue('attachment', e.target.files[0]);
                  }}
                  onBlur={handleBlur}
                  large
                  disabled={approvalMode}
                  helperText={
                    errors.attachment && touched.attachment
                      ? errors.attachment
                      : null
                  }
                  intent={
                    errors.attachment && touched.attachment
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {approvalMode && expenseFormValues.fileName && (
                <div className={'AddExpense__Form--fields'}>
                  <p>Attachment</p>
                  <li>
                    <a
                      href={expenseFormValues.advanceAttachmentPath}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {expenseFormValues.fileName}
                    </a>
                  </li>
                </div>
              )}

              {approvalMode && approvalModeType === 1 && (
                <Field
                  className="AddExpense__Form--fields"
                  id="advanceRequestNo"
                  label={t('expenses.addItem.advanceRequestNo')}
                  component={FormInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  large
                  value={expenseFormValues.advanceRequestNo}
                  disabled
                />
              )}
              {values.type === constants.type.claim && !approvalMode && (
                <Field
                  className="AddExpense__Form--fields"
                  id="advanceRequestNo"
                  label={t('expenses.addItem.advanceRequestNo')}
                  component={FormSelect}
                  onBlur={handleBlur}
                  options={advanceList}
                  disabled={approvalMode}
                  onChange={e => {
                    setFieldValue('advanceRequestNo', e.value);
                    getAmountValue(parseInt(e.value, 0), setFieldValue);
                  }}
                  value={values.advanceRequestNo}
                  helperText={
                    errors.advanceRequestNo && touched.advanceRequestNo
                      ? errors.advanceRequestNo
                      : null
                  }
                  intent={
                    errors.advanceRequestNo && touched.advanceRequestNo
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {(values.type === constants.type.claim ||
                approvalModeType === 1) && (
                  <Field
                    className="AddExpense__Form--fields"
                    id="advanceAmount"
                    label={t('expenses.addItem.advanceAmount')}
                    type="number"
                    component={FormInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly
                    disabled={approvalMode}
                    large
                    value={
                      (approvalMode && expenseFormValues.advanceAmount) ||
                      values.advanceAmount
                    }
                    helperText={
                      errors.advanceAmount && touched.advanceAmount
                        ? errors.advanceAmount
                        : null
                    }
                    intent={
                      errors.advanceAmount && touched.advanceAmount
                        ? Intent.DANGER
                        : Intent.NONE
                    }
                  />
                )}
              <Field
                className="AddExpense__Form--fields"
                id="comments"
                label={t('expenses.addItem.comments')}
                component={FormTextArea}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                value={
                  (approvalMode && expenseFormValues.comments) ||
                  values.comments
                }
                helperText={
                  errors.comments && touched.comments ? errors.comments : null
                }
                intent={
                  errors.comments && touched.comments
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              {!approvalMode &&
                displayDynamicFields(
                  dynamicColumns,
                  newExpenseFormValues.dynamicFields,
                  props,
                )}

              {approvalMode &&
                expenseFormValues.dynamicColumns &&
                displayDynamicAttributes(
                  JSON.parse(expenseFormValues.dynamicColumns),
                )}

              {(itemData.length > 0 || approvalMode) && (
                <ExpenseItemList
                  items={itemData}
                  approvalMode={approvalMode}
                  t={t}
                  values={{
                    history,
                    type:
                      (approvalMode &&
                        types[expenseFormValues.type] &&
                        types[expenseFormValues.type].value) ||
                      values.type,
                  }}
                />
              )}
              {!approvalMode && (
                <Button
                  className="AddExpense__Form--fields"
                  type="button"
                  text="Add Item"
                  large="true"
                  onClick={() => {
                    setNewExpenseFormValues(values);
                    history.push({
                      pathname: '/home/expense/add/newItem',
                      state: {
                        type: values.type,
                      },
                    });
                  }}
                />
              )}
              <Field
                className="Form--fields Form--fields__right"
                id="totalAmount"
                label={t('expenses.addItem.totalAmount')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                rightElement={
                  <Tag minimal>{expenseFormValues.currencySymbol}</Tag>
                }
                value={getTotalAmount(itemData)}
              />
              {showErrorMessage && (
                <div>
                  <p style={{ color: 'red', margin: '1%' }}>
                    {' '}
                    {t('expenses.addItem.validations.totalAmount')}
                  </p>
                </div>
              )}
              {errors.items && (
                <div>
                  <p style={{ color: 'red', margin: '1%' }}> {errors.items}</p>
                </div>
              )}
              {!approvalMode && (
                <Button
                  className="AddExpense__Form--fields"
                  type="submit"
                  intent="success"
                  text={t('commons.submit')}
                  large="true"
                  onClick={() => {
                    values.submitType = 'submitBtn';
                  }}
                  disabled={isSubmitting}
                  fill
                />
              )}
              {!approvalMode && (
                <Button
                  className="AddExpense__Form--fields"
                  type="submit"
                  text={t('commons.save')}
                  large="true"
                  onClick={() => {
                    values.submitType = 'save';
                  }}
                  disabled={isSubmitting}
                  fill
                />
              )}
              {approvalMode && (
                <Button
                  className="AddExpense__Form--fields"
                  type="submit"
                  intent="success"
                  text={t('commons.approve')}
                  large="true"
                  onClick={() => {
                    values.submitType = 'approve';
                  }}
                  disabled={isSubmitting}
                  fill
                />
              )}
              {approvalMode && (
                <Button
                  className="AddExpense__Form--fields"
                  type="submit"
                  text={t('commons.reject')}
                  large="true"
                  intent="danger"
                  onClick={() => {
                    values.submitType = 'reject';
                  }}
                  disabled={isSubmitting}
                  fill
                />
              )}
              <Button
                className="AddExpense__Form--fields"
                type="button"
                text={t('commons.cancel')}
                large="true"
                disabled={isSubmitting}
                fill
                onClick={() => {
                  resetItemData();
                  resetNewExpenseFormValues();
                  history.goBack();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
class AddExpenseComponent extends Component {
  componentDidMount() {
    if (this.props.isEdit === true) {
      const history = this.props.history;
      const selectedTask =
        history.location.state && history.location.state.selectedTask;
      if (!selectedTask) this.props.history.push('/home/tasks');
      else this.props.getTaskExpense(selectedTask);
    } else {
      this.props.fetchAddExpenseDetails();
      this.props.history.action !== 'POP' && this.props.resetItemData();
    }
  }

  render() {
    const {
      addExpenseDetailsPromise,
      updateExpensePromise,
      taskExpensePromise,
    } = this.props;
    if (updateExpensePromise.isPending) {
      return <Spinner intent={Intent.PRIMARY} className="progress" />;
    }
    if (taskExpensePromise.isPending) {
      return <Spinner intent={Intent.PRIMARY} className="progress" />;
    }
    return (
      <div className="AddExpense">
        {addExpenseDetailsPromise.isPending && (
          <Spinner intent={Intent.PRIMARY} className="progress" />
        )}
        {(addExpenseDetailsPromise.isFulfilled ||
          taskExpensePromise.isFulfilled) &&
          form(this.props)}
      </div>
    );
  }
}
AddExpenseComponent.propTypes = {
  t: PropTypes.func.isRequired,
  newExpenseFormValues: PropTypes.object.isRequired,
  addExpenseDetailsPromise: PropTypes.object.isRequired,
  updateExpensePromise: PropTypes.object.isRequired,
  addExpenseDetails: PropTypes.object.isRequired,
  fetchEntityDetails: PropTypes.func.isRequired,
  fetchAdvanceNumber: PropTypes.func.isRequired,
  isFetchingEntityDetails: PropTypes.bool.isRequired,
  entityList: PropTypes.array.isRequired,
  viewList: PropTypes.array.isRequired,
  workflowList: PropTypes.array.isRequired,
  fetchAddExpenseDetails: PropTypes.func.isRequired,
  resetItemData: PropTypes.func.isRequired,
  setItemData: PropTypes.func.isRequired,
  itemData: PropTypes.array.isRequired,
  submitExpense: PropTypes.func.isRequired,
  setNewExpenseFormValues: PropTypes.func.isRequired,
  resetNewExpenseFormValues: PropTypes.func.isRequired,
  setSiteCodeList: PropTypes.func.isRequired,
  siteCodeList: PropTypes.array,
};

AddExpenseComponent.defaultProps = {
  siteCodeList: [],
};

export default AddExpenseComponent;
