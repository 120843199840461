import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';
import { filterString } from '../../../../shared/utils/string';
import globalConstants from '../../../../shared/constants';
import constants from '../../common/constants';
import Table from '../../../common/table/Table';

const POInventoryLine = props => {
  const { lines, history } = props;

  const showLineDetails = item => {
    history.push({ pathname: '/home/tasks/po/line', state: { line: item } });
  };

  const columns = [
    {
      Header: constants.labels.itemNo,
      accessor: constants.accessor.itemNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemDescription,
      accessor: constants.accessor.itemDesc,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.hsnCode,
      accessor: constants.accessor.hsnCode,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.category,
      accessor: constants.accessor.categoryDesc,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.availableQty,
      accessor: constants.accessor.availableQty,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.quantity,
      accessor: constants.accessor.qty,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.uom,
      accessor: constants.accessor.uom,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.unitPrice,
      accessor: constants.accessor.price,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.netPrice,
      accessor: constants.accessor.netPrice,
      filterMethod: (filter, row) => filterString(filter, row),
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 150,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = line => {
    const data = [
      {
        property: constants.labels.itemNo,
        value: line.itemNo,
      },
      {
        property: constants.labels.itemDescription,
        value: line.itemDesc,
      },
    ];
    return data.filter(ele => ele.value !== null && ele.value !== '');
  };

  return (
    <div className="PO__Line">
      <h4>{globalConstants.TITLES.INVENTORY_LINES}</h4>
      <div className="PO__Line--desktop">
        <Table
          data={lines}
          columns={columns}
          onClick={() => {}}
          disableFooter
        />
      </div>
      {lines.map((po, index) => (
        <div className="PO__Line--mobile" key={index}>
          <Table
            data={getMobileData(po)}
            columns={mobileColumns}
            pageSize={getMobileData(po).length}
            onClick={() => showLineDetails(po)}
            disableHeader
          />
        </div>
      ))}
    </div>
  );
};

POInventoryLine.propType = {
  line: PropTypes.object.isRequired,
  lines: PropTypes.array.isRequired,
  handleLineItemChange: PropTypes.func,
};

export default POInventoryLine;
