import { connect } from 'react-redux';
import SalesOrderComponent from './component';
import { fetchSalesOrderGrid } from './actions';
import { selectSalesOrderGrid, salesOrderPromise } from './selector';

const mapStateToProps = state => {
  return {
    salesOrderGrid: selectSalesOrderGrid(state),
    salesOrderPromise: salesOrderPromise(state),
    errorMessage: state.salesOrder.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSalesOrderGrid: () => {
      dispatch(fetchSalesOrderGrid());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesOrderComponent);
