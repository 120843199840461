import salesOrderInitialState from './initialState';
import salesOrderActionTypes from './actionTypes';
import setPromiseState from '../../shared/service/promiseState';

const salesOrderReducer = (state = salesOrderInitialState, action) => {
  switch (action.type) {
    case salesOrderActionTypes.SET_SALES_ORDER_GRID:
      return state.set('salesOrderGrid', action.salesOrderGrid);
    case salesOrderActionTypes.SALES_ORDER.fulfilled:
      return state.setIn(
        ['promise', 'salesOrder'],
        setPromiseState(false, true, false),
      );
    case salesOrderActionTypes.SALES_ORDER.pending:
      return state.setIn(
        ['promise', 'salesOrder'],
        setPromiseState(true, false, false),
      );
    case salesOrderActionTypes.SALES_ORDER.rejected:
      return state.setIn(
        ['promise', 'salesOrder'],
        setPromiseState(false, false, true),
      );
    default:
      return state;
  }
};

export default salesOrderReducer;
