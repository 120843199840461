import { connect } from 'react-redux';
import NewTimesheet from './NewTimesheet';
import t from '../../../../shared/service/translations';
import { selectTasksList } from '../addTimesheetsSelector';
import { submitTimesheets } from '../addTimesheetsActions';

const mapStateToProps = state => {
  return {
    t,
    tasksList: selectTasksList(state),
  };
};

const mapDispatchToProps = {
  submitTimesheets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewTimesheet);
