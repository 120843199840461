import { connect } from 'react-redux';
import AddSalesOrderComponent from './addSalesOrderComponent';
import t from '../../../shared/service/translations';
import {
  fetchEntityDetails,
  fetchAddSalesOrderDetails,
  setSalesOrderItemData,
  resetSalesOrderItemData,
  submitSalesOrder,
  approveRejectSalesOrder,
  setNewSalesOrderFormValues,
  resetNewSalesOrderFormValues,
  setSiteCodeList,
  getSalesOrderDetails,
  fetchCustomerDetails,
  fetchViewDetails,
  fetchItemSeachDetails,
} from './addSalesOrderActions';
import { getEntityList } from '../../login/authSelector';
import {
  addSalesOrderDetailsPromise,
  updateSalesOrderPromise,
  getAddSalesOrderDetails,
  selectViewList,
  selectWorkflowList,
  isFetchingEntityDetails,
  selectSalesOrderItemData,
  selectNewSalesOrderFormValues,
  selectOrderSeqList,
  selectCustomerList,
  taskSalesOrderPromise,
  selectSalesOrderFormValues,
  selectDynamicColumns,
  selectQuotationList,
  getSiteCodesList,
} from './addSalesOrderSelector';

const mapStateToProps = state => {
  return {
    t,
    addSalesOrderDetailsPromise: addSalesOrderDetailsPromise(state),
    updateSalesOrderPromise: updateSalesOrderPromise(state),
    addSalesOrderDetails: getAddSalesOrderDetails(state),
    taskSalesOrderPromise: taskSalesOrderPromise(state),
    entityList: getEntityList(),
    viewList: selectViewList(state),
    workflowList: selectWorkflowList(state),
    orderSeqList: selectOrderSeqList(state),
    customerList: selectCustomerList(state),
    isFetchingEntityDetails: isFetchingEntityDetails(state),
    itemData: selectSalesOrderItemData(state),
    dynamicColumns: selectDynamicColumns(state),
    newSalesOrderFormValues: selectNewSalesOrderFormValues(state),
    salesOrderFormValues: selectSalesOrderFormValues(state),
    selectedTask: state.tasks.selectedTask,
    siteCodeList: getSiteCodesList(state),
    quotationList: selectQuotationList(state),
  };
};

const mapDispatchToProps = {
  fetchEntityDetails,
  fetchCustomerDetails,
  fetchViewDetails,
  fetchAddSalesOrderDetails,
  setSalesOrderItemData,
  resetSalesOrderItemData,
  submitSalesOrder,
  approveRejectSalesOrder,
  getSalesOrderDetails,
  setNewSalesOrderFormValues,
  resetNewSalesOrderFormValues,
  setSiteCodeList,
  fetchItemSeachDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddSalesOrderComponent);
