import seamlessImmutable from 'seamless-immutable';
import setPromiseState from '../../shared/service/promiseState';

const timesheetsInitialState = seamlessImmutable({
  promise: {
    timesheets: setPromiseState(),
  },
  timesheetsGrid: {},
});

export default timesheetsInitialState;
