import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import InvoiceHeader from './components/InvoiceHeader';
import constants from '../../../shared/constants';
import InvoiceLine from './components/InvoiceLine';
import InvoicePrice from './components/InvoicePrice';
import InvoiceFooter from './components/InvoiceFooter';

class InvoiceComponent extends Component {
  componentDidMount() {
    this.props.getInvoice(this.props.selectedTask);
  }

  render() {
    const {
      invoice,
      promise,
      updateFieldValue,
      updateInvoiceFieldValue,
      history,
      updateInvoice,
    } = this.props;

    const handleChange = event => {
      updateFieldValue({ key: event.target.name, value: event.target.value });
    };

    const handleLineItemChange = y => event => {
      // updateLineFieldValue({ index: y, key: event.target.name, value: event.target.value });
    };

    const handleInvoiceUpdate = event => {
      updateInvoiceFieldValue({
        key: event.target.name,
        value: event.target.value,
      });
    };

    return (
      <div className="Invoice container">
        {promise.isFulfilled && (
          <div>
            <h4>Invoice Amendment</h4>
            <InvoiceHeader invoice={invoice} handleChange={handleChange} />
            <InvoiceLine
              handleLineItemChange={handleLineItemChange}
              lines={invoice.invoiceLineItems}
            />
            <InvoicePrice invoice={invoice} />
            <InvoiceFooter
              invoice={invoice}
              handleChange={handleInvoiceUpdate}
            />
            <div className="Form--fields Invoice__actions">
              <Button
                text={constants.tasks.columns.cancel}
                className="Invoice__buttons"
                onClick={() => history.goBack()}
                large
              />
              <Button
                className="Invoice__buttons"
                text={constants.tasks.columns.reject}
                intent={Intent.DANGER}
                onClick={() =>
                  updateInvoice(constants.tasks.actions.REJECT, history)
                }
                large
              />
              <Button
                className="Invoice__buttons"
                text={constants.tasks.columns.completeApproval}
                intent={Intent.PRIMARY}
                onClick={() =>
                  updateInvoice(constants.tasks.actions.APPROVE, history)
                }
                large
              />
            </div>
          </div>
        )}
        {promise.isPending && (
          <Spinner intent={Intent.PRIMARY} className="progress" />
        )}
      </div>
    );
  }
}

InvoiceComponent.propTypes = {
  getInvoice: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  updateLineFieldValue: PropTypes.func.isRequired,
  updateInvoiceFieldValue: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  selectedTask: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  promise: PropTypes.object.isRequired,
};

export default InvoiceComponent;
