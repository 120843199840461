import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Button, Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FormInput from '../../common/form/FormInput';
import FormSelect from '../../common/form/FormSelect';

const form = newProps => {
  const {
    t,
    entityList,
    fetchEntityDetails,
    fetchResourceGrid,
    projectCodeList,
    workflowList,
    history,
    newTimesheetsFormValues,
    resetNewTimesheetsFormValues,
  } = newProps;

  return (
    <div className="AddTimesheets container">
      <Callout
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title="Timesheets"
      >
        Create Timesheet.
      </Callout>

      <Formik
        initialValues={newTimesheetsFormValues}
        onSubmit={(values, { setSubmitting }) => {
          fetchResourceGrid(values);
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          entityId: Yup.string().required(
            t('timesheets.addItem.validations.entity'),
          ),
          projectCodeId: Yup.string().required(
            t('timesheets.addItem.validations.projectCode'),
          ),
          workflowId: Yup.string().required(
            t('timesheets.addItem.validations.workflow'),
          ),
          fromDate: Yup.date().required(
            t('timesheets.addItem.validations.fromDate'),
          ),
          toDate: Yup.date()
            .required(t('timesheets.addItem.validations.toDate'))
            .when('fromDate', st => {
              return Yup.date()
                .min(st, 'Minimum needs to be start date')
                .max(
                  moment(st)
                    .add(6, 'days')
                    .toDate(),
                  'You can enter timesheet for 7 days max',
                );
            }),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <Form onSubmit={handleSubmit} className="AddTimesheets__Form">
              <Field
                className="AddTimesheets__Form--fields"
                id="entityId"
                label={t('timesheets.addItem.entity')}
                component={FormSelect}
                onChange={e => {
                  setFieldValue('entityId', e.value);
                  if (e.value) {
                    fetchEntityDetails(e.value);
                  }
                }}
                onBlur={handleBlur}
                options={entityList}
                value={values.entityId}
                helperText={
                  errors.entityId && touched.entityId ? errors.entityId : null
                }
                intent={
                  errors.entityId && touched.entityId
                    ? Intent.DANGER
                    : Intent.NONE
                }
                placeholder={t('timesheets.addItem.placeholders.entity')}
              />
              <Field
                className="AddTimesheets__Form--fields"
                id="projectCodeId"
                label={t('timesheets.addItem.projectCode')}
                component={FormSelect}
                searchable
                onChange={e => {
                  setFieldValue('projectCodeId', e.value);
                }}
                onBlur={handleBlur}
                options={projectCodeList}
                value={values.projectCodeId}
                disabled={!(projectCodeList && projectCodeList.length)}
                helperText={
                  errors.projectCodeId && touched.projectCodeId
                    ? errors.projectCodeId
                    : null
                }
                intent={
                  errors.projectCodeId && touched.projectCodeId
                    ? Intent.DANGER
                    : Intent.NONE
                }
                placeholder={t('timesheets.addItem.placeholders.projectCode')}
              />
              <Field
                className="AddTimesheets__Form--fields"
                id="workflowId"
                label={t('timesheets.addItem.workflow')}
                component={FormSelect}
                onChange={e => {
                  setFieldValue('workflowId', e.value);
                }}
                onBlur={handleBlur}
                options={workflowList}
                value={values.workflowId}
                disabled={!(workflowList.length > 0)}
                helperText={
                  errors.workflowId && touched.workflowId
                    ? errors.workflowId
                    : null
                }
                intent={
                  errors.workflowId && touched.workflowId
                    ? Intent.DANGER
                    : Intent.NONE
                }
                placeholder={t('timesheets.addItem.placeholders.workflow')}
              />
              <Field
                className="AddTimesheets__Form--fields"
                id="fromDate"
                label={t('timesheets.addItem.fromDate')}
                type="date"
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                value={values.fromDate}
                helperText={
                  errors.fromDate && touched.fromDate ? errors.fromDate : null
                }
                intent={
                  errors.fromDate && touched.fromDate
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddTimesheets__Form--fields"
                id="toDate"
                label={t('timesheets.addItem.toDate')}
                type="date"
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                value={values.toDate}
                helperText={
                  errors.toDate && touched.toDate ? errors.toDate : null
                }
                intent={
                  errors.toDate && touched.toDate ? Intent.DANGER : Intent.NONE
                }
              />
              <Button
                className="AddTimesheets__Form--fields"
                type="submit"
                text={t('timesheets.buttons.addHours')}
                intent="primary"
                large={true}
                disabled={isSubmitting}
                fill={true}
              />
              <Button
                className="AddTimesheets__Form--fields"
                type="button"
                text={t('commons.cancel')}
                large={true}
                disabled={isSubmitting}
                fill
                onClick={() => {
                  resetNewTimesheetsFormValues();
                  history.goBack();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

class AddTimesheetsComponent extends Component {
  render() {
    return <div className="AddTimesheets">{form(this.props)}</div>;
  }
}

AddTimesheetsComponent.propTypes = {
  t: PropTypes.func.isRequired,
  newTimesheetsFormValues: PropTypes.object.isRequired,
  fetchEntityDetails: PropTypes.func.isRequired,
  isFetchingEntityDetails: PropTypes.bool.isRequired,
  entityList: PropTypes.array.isRequired,
  projectCodeList: PropTypes.array.isRequired,
  workflowList: PropTypes.array.isRequired,
  resetNewTimesheetsFormValues: PropTypes.func.isRequired,
};

export default AddTimesheetsComponent;
