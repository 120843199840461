import React, { Component } from 'react';
import './App.scss';
import { Spinner, Intent } from '@blueprintjs/core';
import { getValue } from '../../shared/service/localStorage';
import constants from '../../shared/constants';
import history from '../../shared/service/history';

class App extends Component {
  componentDidMount() {
    if (getValue(constants.LOCAL_STORAGE.TOKEN)) {
      history.push('/home');
    } else {
      history.push('/login');
    }
  }

  render() {
    return (
      <div>
        <Spinner intent={Intent.PRIMARY} className="App-progress" />
      </div>
    );
  }
}

export default App;
