import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Intent, Checkbox } from '@blueprintjs/core';
import './form.scss';

const FormCheckBox = props => {
  const {
    disabled,
    helperText,
    inline,
    intent,
    label,
    labelInfo,
    onChange,
    indeterminate,
    checked,
    large,
    id,
  } = props;
  return (
    <FormGroup
      className="ip-formGroup"
      disabled={disabled}
      helperText={helperText}
      inline={inline}
      intent={intent}
      labelFor={id}
      labelInfo={labelInfo}
    >
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        indeterminate={indeterminate}
        large={large}
        label={label}
      />
    </FormGroup>
  );
};

FormCheckBox.propTypes = {
  disabled: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  intent: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  large: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

FormCheckBox.defaultProps = {
  disabled: false,
  helperText: '',
  inline: false,
  intent: Intent.NONE,
  label: '',
  labelInfo: '',
  placeholder: '',
  small: false,
  large: false,
};

export default FormCheckBox;
