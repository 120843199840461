import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { Alignment, Navbar, NavbarGroup, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
class NavBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    return (
      <Navbar className={'bp3-navbar Navbar'} fixedToTop={true}>
        <NavbarGroup align={Alignment.LEFT}>
          <button
            className={'Navbar__Button'}
            onClick={() => this.props.toggleDrawer(!this.props.isDrawerOpen)}
          >
            <Icon
              className={'Navbar__Button--menu'}
              icon={IconNames.MENU}
              iconSize={'20'}
            />
          </button>
          <div className={'Navbar__Logo'} />
        </NavbarGroup>
        <div className={'Navbar__Grow'} />
        <NavbarGroup>
          <button
            className={'Navbar__Button'}
            onClick={this.handleProfileMenuOpen}
          >
            <Icon
              className={'Navbar__Button--menu'}
              icon={IconNames.USER}
              iconSize={'20'}
            />
          </button>
        </NavbarGroup>
      </Navbar>
    );
  }
}

NavBar.propTypes = {
  isDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default NavBar;
