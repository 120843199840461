// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../shared/images/card_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Asset {\n  background: #FFFFFF url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n  .Asset__Line {\n    margin: 1em 0; }\n    .Asset__Line--edit {\n      border-radius: 2px;\n      border: 1px solid #DFDFDE;\n      width: 100%;\n      padding: 6px; }\n    .Asset__Line--mobile {\n      margin: 20px 0;\n      display: none;\n      box-shadow: 0 0 4px 2px #F2F2F2; }\n    .Asset__Line--desktop {\n      display: block; }\n  .Asset__actions {\n    margin: 2em 0; }\n  .Asset__buttons {\n    margin: 10px 0px; }\n  .Asset__footer {\n    border-bottom: 1px solid #DFDFDE;\n    margin-top: 1em; }\n  .Asset--field {\n    width: 50% !important; }\n\n@media (min-width: 481px) and (max-width: 767px) {\n  .Asset__Line--mobile {\n    display: block; }\n  .Asset__Line--desktop {\n    display: none; }\n  .Asset--field {\n    width: 100% !important; } }\n\n@media (min-width: 320px) and (max-width: 480px) {\n  .Asset__Line--mobile {\n    display: block; }\n  .Asset__Line--desktop {\n    display: none; }\n  .Asset--field {\n    width: 100% !important; } }\n", ""]);
// Exports
module.exports = exports;
