import { get } from 'lodash';

const isFetchingEntityDetails = state =>
  get(state, 'addTimesheets.isFetchingEntityDetails');
const selectProjectCodeList = state =>
  get(state, 'addTimesheets.projectCodeList');
const selectWorkflowList = state => get(state, 'addTimesheets.workflowList');

const selectItemData = state => get(state, 'addTimesheets.itemData');

const selectNewTimesheetsFormValues = state =>
  get(state, 'addTimesheets.newTimesheetsFormValues');
const selectNewItemFormValues = state =>
  get(state, 'addTimesheets.newItemFormValues');

const selectSelectedCategory = state =>
  get(state, 'addTimesheets.selectedCategory');

const selectTimesheets = state => get(state, 'addTimesheets.timesheets');
const selectTimesheetsUpdateResponse = state =>
  get(state, 'addTimesheets.timesheetsUpdateResponse');
const timesheetsPromise = state =>
  get(state, 'addTimesheets.promise.timesheets');
const selectTasksList = state => get(state, 'addTimesheets.tasksList');

export {
  selectProjectCodeList,
  selectWorkflowList,
  isFetchingEntityDetails,
  selectItemData,
  selectNewTimesheetsFormValues,
  selectNewItemFormValues,
  selectSelectedCategory,
  selectTimesheets,
  selectTimesheetsUpdateResponse,
  timesheetsPromise,
  selectTasksList,
};
