import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';

const PrintComponent = props => {
  const { values } = props;
  return (
    <div className="PrintComponent">
      <div style={{ textAlign: 'center' }}>
        <br />
        <p>
          <span style={{ fontSize: '18px' }}>{values?.company?.orgName}</span>
          <br />
          {values?.company?.address}
          <br />
          {values?.company?.city}, {values?.company?.pinCode}
          <br />
          <span>GSTIN: {values?.company?.GSTIN}</span>
        </p>
        <hr style={{ borderTop: 'solid 1px' }} />
        <span>Estimate</span>
        <p>
          {values?.customer && values?.customer?.customerName}
          <br />
          <span>GSTIN: {values?.customer && values.customer.GSTIN}</span>
        </p>
        <p style={{ float: 'left', paddingTop: '1px' }}>
          {moment(new Date()).format('DD/MM/YY')}
        </p>
        <p style={{ float: 'right', paddingTop: '1px' }}>
          {moment(new Date()).format('HH:mm')}
        </p>
        <br />
        <hr style={{ borderTop: 'solid 1px' }} />
        <table className={'PrintComponent__Item'}>
          <thead>
            <tr>
              <td colspan="4">Item Name</td>
            </tr>
            <tr>
              <td
                colspan="1"
                style={{
                  textAlign: 'center',
                  borderBottom: 'solid 1px  black',
                }}
              >
                Qty
              </td>
              <td colspan="1" style={{ borderBottom: 'solid 1px  black' }}>
                Price
              </td>
              <td colspan="2" style={{ borderBottom: 'solid 1px  black' }}>
                Amt
              </td>
            </tr>
          </thead>
          <tbody>
            {values?.items?.map((item, i) => (
              <>
                <tr key={i}>
                  <td colspan="4" style={{ textAlign: 'left' }}>
                    {i + 1}.{item.name}
                  </td>
                </tr>
                <tr style={{ marginBottom: '10px' }}>
                  <td colspan="1" style={{ textAlign: 'center' }}>
                    {item.qty}
                  </td>
                  <td colspan="1">{item.price}</td>
                  <td colspan="2">{item.netPrice}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        <hr style={{ borderTop: 'solid 1px' }} />
        {values.taxNames.find(e => e === 'IGST') && (
          <table className={'PrintComponent__TaxItems'}>
            <thead>
              <tr>
                <td style={{ borderBottom: 'solid 1px  black' }}>IGST</td>
                <td style={{ borderBottom: 'solid 1px  black' }}>AMT</td>
              </tr>
            </thead>
            <tbody>
              {values?.igst?.map((item, i) => (
                <tr key={i}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {values?.taxNames?.find(e => e === 'VAT') && (
          <table className={'PrintComponent__TaxItems'}>
            <thead>
              <tr>
                <td style={{ borderBottom: 'solid 1px  black' }}>VAT</td>
                <td style={{ borderBottom: 'solid 1px  black' }}>AMT</td>
              </tr>
            </thead>
            <tbody>
              {values?.otherTax?.map((item, i) => (
                <tr key={i}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {values.taxNames.find(e => e === 'SGST,CGST') && (
          <table className={'PrintComponent__TaxItems'}>
            <thead>
              <tr>
                <td style={{ borderBottom: 'solid 1px  black' }}>SGST</td>
                <td style={{ borderBottom: 'solid 1px  black' }}>AMT</td>
              </tr>
            </thead>
            <tbody>
              {values?.gstMap?.map((item, i) => (
                <tr key={i}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr>
                <td style={{ borderBottom: 'solid 1px  black' }}>CGST</td>
                <td style={{ borderBottom: 'solid 1px  black' }}>AMT</td>
              </tr>
            </thead>
            <tbody>
              {values?.gstMap?.map((item, i) => (
                <tr key={i}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <hr style={{ borderTop: 'solid 1px' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            textAlign: 'left',
          }}
        >
          <table>
            <tr>
              <td>
                <span>Total Net Price :</span>
              </td>
              <td>
                <span>{values.totalNetPrice}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Total Tax :</span>
              </td>
              <td>
                <span>{values.totalNetPrice}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Advance :</span>
              </td>
              <td>
                <span>{values.advance}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span style={{ fontSize: '16px' }}>Grand Total :</span>
              </td>
              <td>
                <span style={{ fontSize: '16px' }}>{values.grandTotal}</span>
              </td>
            </tr>
          </table>
        </div>

        <hr style={{ borderTop: 'solid 1px' }} />
        <div style={{ alignItems: 'center' }}> *THANK YOU*</div>
        <p style={{ float: 'right', fontSize: '10px', paddingTop: '10px' }}>
          Powered by iPact &copy;
        </p>
      </div>
    </div>
  );
};

PrintComponent.propTypes = {
  values: PropTypes.object.isRequired,
};

PrintComponent.defaultProps = {
  values: {},
};

export default PrintComponent;
