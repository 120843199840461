import { connect } from 'react-redux';
import AddTimesheetsComponent from './AddTimesheetsComponent';
import t from '../../../shared/service/translations';
import {
  fetchEntityDetails,
  submitTimesheets,
  setNewTimesheetsFormValues,
  resetNewTimesheetsFormValues,
  fetchResourceGrid,
} from './addTimesheetsActions';
import { getEntityList } from '../../login/authSelector';
import {
  selectWorkflowList,
  isFetchingEntityDetails,
  selectItemData,
  selectNewTimesheetsFormValues,
  selectProjectCodeList,
} from './addTimesheetsSelector';

const mapStateToProps = state => {
  return {
    t,
    entityList: getEntityList(),
    projectCodeList: selectProjectCodeList(state),
    workflowList: selectWorkflowList(state),
    isFetchingEntityDetails: isFetchingEntityDetails(state),
    itemData: selectItemData(state),
    newTimesheetsFormValues: selectNewTimesheetsFormValues(state),
  };
};

const mapDispatchToProps = {
  fetchEntityDetails,
  submitTimesheets,
  setNewTimesheetsFormValues,
  resetNewTimesheetsFormValues,
  fetchResourceGrid,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTimesheetsComponent);
