import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './login.scss';
import { Formik, Form } from 'formik';
import { Tooltip, Button, Intent, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import logo from '../../shared/images/ipact-logo-light.svg';
import FormInput from '../common/form/FormInput';

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const { promise, login, errorMessage, t } = this.props;
    const handleLockClick = () =>
      this.setState({ showPassword: !this.state.showPassword });
    const lockButton = (
      <Tooltip
        content={`${
          this.state.showPassword
            ? t('login.tooltip.hide')
            : t('login.tooltip.show')
        }  ${t('login.password')}`}
      >
        <Button
          icon={
            <Icon
              icon={
                this.state.showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN
              }
              iconSize={Icon.SIZE_LARGE}
            />
          }
          style={{ borderRadius: '50%' }}
          intent={Intent.PRIMARY}
          minimal
          onClick={handleLockClick}
        />
      </Tooltip>
    );

    return (
      <div className="Login">
        <div className="Login__modal">
          <img src={logo} alt="logo" className="Login--logo" />
          <Formik
            initialValues={{ userName: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              login(values.userName, values.password);
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="Login__modal--box">
                  <FormInput
                    className="Login__modal--field"
                    id="userName"
                    type="text"
                    placeholder={t('login.placeholders.userName')}
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    large
                  />
                  <FormInput
                    id="password"
                    className="Login__modal--field"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={t('login.placeholders.password')}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rightElement={lockButton}
                    large
                  />
                  <Button
                    className="Login__modal--field"
                    type="submit"
                    rightIcon={IconNames.LOG_IN}
                    intent={Intent.PRIMARY}
                    text={t('login.login')}
                    large
                    disabled={
                      values.username === '' ||
                      values.password === '' ||
                      promise.isPending
                    }
                    loading={promise.isPending}
                  />
                  {errorMessage && (
                    <p className="Login--error">{errorMessage}</p>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div variant="caption" className="Login--footer">
          {t('login.footer')}
        </div>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  login: PropTypes.func.isRequired,
  promise: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default LoginComponent;
