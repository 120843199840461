import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../shared/constants';
import { filterString } from '../../../shared/utils/string';
import Table from '../../common/table/Table';
import './styles.scss';
import { Intent, Spinner } from '@blueprintjs/core';

class DesktopTable extends React.Component {
  render() {
    const { tasks, history, promise } = this.props;

    const data = tasks && tasks.asMutable({ deep: true });

    const handleClick = task => {
      this.props.setSelectedTask(task);
      switch (task.workflowTypeId) {
        case constants.TASKS_WORKFLOW_IDS.PO_REQUISITION_PROCESSING_TYPE:
          history.push({
            pathname: '/home/tasks/po',
            state: { selectedTask: task },
          });
          break;
        case constants.TASKS_WORKFLOW_IDS.PICKUP_PROCESSING_TYPE:
          history.push({
            pathname: '/home/tasks/pickUp',
            state: { selectedTask: task },
          });
          break;
        case constants.TASKS_WORKFLOW_IDS.INVOICE_PROCESSING_TYPE:
          history.push('/home/tasks/invoice');
          break;
        case constants.TASKS_WORKFLOW_IDS.ASSET_PROCESSING_TYPE:
          history.push('/home/tasks/asset');
          break;
        case constants.TASKS_WORKFLOW_IDS.EXPENSE_PROCESSING_TYPE:
          history.push({
            pathname: '/home/tasks/expense',
            state: { selectedTask: task },
          });
          break;
        default:
          break;
      }
    };

    const headers = constants.tasks.columns;

    const columns = [
      {
        Header: headers.workflowTypeName,
        accessor: 'workflowTypeName',
        minWidth: 150,
        filterMethod: (filter, row) => {
          switch (filter.value) {
            // case 'asset':
            //   return row[filter.id] === 'Asset';
            // case 'invoice':
            //   return row[filter.id] === 'Invoice';
            case 'pickUp':
              return row[filter.id] === 'PickUp';
            case 'poRequisition':
              return row[filter.id] === 'PO Requisition';
            case 'expense':
              return row[filter.id] === 'Expense-Advance or Claim';
            case 'all':
            default:
              return true;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">All</option>
            {/* <option value="asset">Asset</option>
            <option value="invoice">Invoice</option>*/}
            <option value="pickUp">PickUp</option>
            <option value="poRequisition">PO Requisition</option>
            <option value="expense">Expense-Advance or Claim</option>
          </select>
        ),
      },
      {
        Header: headers.supplierName,
        accessor: 'supplierName',
        minWidth: 150,
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.stageName,
        accessor: 'stageName',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.contractNo,
        accessor: 'contractNo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.contractOwner,
        accessor: 'contractOwner',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.requestedBy,
        accessor: 'requestedBy',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.requestNo,
        accessor: 'requestNo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.poNo,
        accessor: 'poNo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.invoiceNo,
        accessor: 'invoiceNo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.customerPONo,
        accessor: 'customerPONo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.customerInvoiceNo,
        accessor: 'customerInvoiceNo',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.createdDate,
        accessor: 'createdDate',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.dueDateString,
        accessor: 'dueDateString',
        filterMethod: (filter, row) => filterString(filter, row),
      },
      {
        Header: headers.status,
        accessor: 'status',
        Cell: row => (
          <span>
            <span
              style={{
                color:
                  row.value === 2
                    ? '#ff2e00'
                    : row.value === 1
                    ? '#ffbf00'
                    : '#57d500',
                transition: 'all .3s ease',
              }}
            >
              &#x25cf;
            </span>{' '}
            {row.value === 2
              ? 'Rejected'
              : row.value === 1
              ? 'Pending'
              : 'Active'}
          </span>
        ),
        sortable: false,
        filterable: false,
      },
    ];

    const mobileColumns = [
      {
        Header: 'Property',
        accessor: 'property',
        width: 130,
        Cell: ci => {
          return `${ci.value}:`;
        },
        style: {
          whiteSpace: 'initial',
          textAlign: 'right',
          fontWeight: 'bold',
          lineHeight: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: ci => {
          if (ci.original.property === headers.workflowTypeName) {
            return <span className="MyTasks__mobile--name">{ci.value}</span>;
          }
          return ci.value;
        },
        style: {
          whiteSpace: 'initial',
          lineHeight: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
    ];

    const getMobileData = task => {
      return [
        {
          property: headers.workflowTypeName,
          value: task.workflowTypeName,
        },
        {
          property: headers.requestNo,
          value: task.requestNo,
        },
        {
          property: headers.requestedBy,
          value: task.requestedBy,
        },
        {
          property: headers.createdDate,
          value: task.createdDate,
        },
        {
          property: headers.stageName,
          value: task.stageName,
        },
      ];
    };

    return (
      <div>
        {promise.isPending && (
          <Spinner intent={Intent.PRIMARY} className="progress" />
        )}
        {promise.isFulfilled && data && data.length ? (
          data.map((task, i) => (
            <div key={i} className="MyTasks__mobile">
              <Table
                data={getMobileData(task)}
                columns={mobileColumns}
                pageSize={getMobileData(task).length}
                onClick={() => handleClick(task)}
                disableHeader
              />
            </div>
          ))
        ) : (
          <div className="MyTasks__mobile">
            <p
              style={{
                background: '#f9f7f7',
                height: '300px',
                textAlign: 'center',
                fontSize: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              No Records...
            </p>
          </div>
        )}
        {promise.isFulfilled && (
          <div className="MyTasks__desktop">
            <Table
              data={data}
              columns={columns}
              filterable
              onClick={task => handleClick(task)}
            />
          </div>
        )}
      </div>
    );
  }
}

DesktopTable.propTypes = {
  promise: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  setSelectedTask: PropTypes.func.isRequired,
};

export default DesktopTable;
