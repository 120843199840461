import React from 'react';
import Card from '../../../common/card/Card';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';
import FormCheckBox from '../../../common/form/FormCheckBox';
import localConstants from '../../../../shared/constants';
import { getValue } from '../../../../shared/service/localStorage';

const POHeader = props => {
  const { po } = props;

  const siteCodes =
    JSON.parse(po.siteCodeList).length &&
    JSON.parse(po.siteCodeList).map(sc => sc.CODE);

  const showProjectCodes =
    getValue(localConstants.LOCAL_STORAGE.ORG_ALL_VIEW_VISIBLE) === 'Y' &&
    getValue(localConstants.LOCAL_STORAGE.PROJ_CODE_VISIBLE) === 'Y';

  const displayDynamicAttributes = attributes => {
    const values = [];
    Object.keys(attributes).forEach((key, i) => {
      values.push(
        <FormInput
          key={i}
          id={i.toString()}
          name={i}
          label={key}
          value={attributes[key]}
          large
          disabled
        />,
      );
    });
    return values;
  };

  return (
    <Card>
      <div className="POHeader Form">
        <div className="Form--fields">
          <FormInput
            id={constants.ids.entityName}
            name={constants.ids.entityName}
            label={constants.labels.Entity}
            value={po.entityName}
            large
            disabled
          />
          <FormInput
            id={constants.ids.viewName}
            name={constants.ids.viewName}
            label={constants.labels.View}
            value={po.viewName}
            large
            disabled
          />
          {po.startDate && (
            <FormInput
              id={constants.ids.startDate}
              name={constants.ids.startDate}
              label={constants.labels.startDate}
              value={po.startDate}
              large
              disabled
            />
          )}
          {po.endDate && (
            <FormInput
              id={constants.ids.endDate}
              name={constants.ids.endDate}
              label={constants.labels.endDate}
              value={po.endDate}
              large
              disabled
            />
          )}
          {po.projectCodeName && showProjectCodes && (
            <FormInput
              id={constants.ids.projectCodes}
              name={constants.ids.projectCodes}
              label={constants.labels.ProjectCode}
              value={po.projectCodeName}
              large
              disabled
            />
          )}
          {siteCodes.length && (
            <FormInput
              id={constants.ids.siteCodes}
              name={constants.ids.siteCodes}
              label={constants.labels.SiteCode}
              value={siteCodes.toString()}
              large
              disabled
            />
          )}
          {po.poFromLabel && (
            <FormInput
              id={constants.ids.poFromLabel}
              name={constants.ids.poFromLabel}
              label={constants.labels.Type}
              value={po.poFromLabel}
              large
              disabled
            />
          )}
          {po.requisitionNo && (
            <FormInput
              id={constants.ids.requisitionNo}
              name={constants.ids.requisitionNo}
              label={constants.labels.PORequestNo}
              value={po.requisitionNo}
              large
              disabled
            />
          )}
          {po.workflowName && (
            <FormInput
              id={constants.ids.workflowName}
              name={constants.ids.workflowName}
              label={constants.labels.Workflow}
              value={po.workflowName}
              large
              disabled
            />
          )}
          {po.supplierName && (
            <FormInput
              id={constants.ids.supplierName}
              name={constants.ids.supplierName}
              label={constants.labels.Supplier}
              value={po.supplierName}
              large
              disabled
            />
          )}
          {po.currencyCode && (
            <FormInput
              id={constants.ids.currencyCode}
              name={constants.ids.currencyCode}
              label={constants.labels.Currency}
              value={po.currencyCode}
              large
              disabled
            />
          )}
          {po.billingAddressName && (
            <FormInput
              id={constants.ids.billingAddressId}
              name={constants.ids.billingAddressId}
              label={constants.labels.billingAddress}
              value={po.billingAddressName}
              large
              disabled
            />
          )}
          {po.shippingAddressName && (
            <FormInput
              id={constants.ids.shippingAddressId}
              name={constants.ids.shippingAddressId}
              label={constants.labels.shipToAddress}
              value={po.shippingAddressName}
              large
              disabled
            />
          )}
          {po.wareHouseName && (
            <FormInput
              id={constants.ids.wareHouse}
              name={constants.ids.wareHouse}
              label={constants.labels.warehouse}
              value={po.wareHouseName}
              large
              disabled
            />
          )}
          {po.addressInput && (
            <FormCheckBox
              checked={po.addressInput}
              name={constants.ids.addressInput}
              id={constants.ids.addressInput}
              label={constants.labels.addressInput}
              onBlur={() => {}}
              onChange={() => {}}
              large
              disabled
            />
          )}
          {po.addressInput && po.deliveryAddress && (
            <FormInput
              id={constants.ids.deliveryAddress}
              name={constants.ids.deliveryAddress}
              label={constants.labels.deliveryAddress}
              value={po.deliveryAddress}
              large
              disabled
            />
          )}
          {po.addressInput && po.contactNo && (
            <FormInput
              id={constants.ids.contactNo}
              name={constants.ids.contactNo}
              label={constants.labels.contactNo}
              value={po.contactNo}
              large
              disabled
            />
          )}
          {po.addressInput && po.tinNo && (
            <FormInput
              id={constants.ids.tinNo}
              name={constants.ids.tinNo}
              label={constants.labels.tinNo}
              value={po.tinNo}
              large
              disabled
            />
          )}
          {po.addressInput && po.vatNo && (
            <FormInput
              id={constants.ids.vatNo}
              name={constants.ids.vatNo}
              label={constants.labels.vatNo}
              value={po.vatNo}
              large
              disabled
            />
          )}
          {po.poPaymentTypeName && (
            <FormInput
              id={constants.ids.poPaymentType}
              name={constants.ids.poPaymentType}
              label={constants.labels.poPaymentType}
              value={po.poPaymentTypeName}
              large
              disabled
            />
          )}
          {po.dynamicAttributes &&
            displayDynamicAttributes(JSON.parse(po.dynamicAttributes))}
        </div>
      </div>
    </Card>
  );
};

export default POHeader;
