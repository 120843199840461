import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Button, Callout, Intent, Spinner, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../common/form/FormInput';
import FormSelect from '../../common/form/FormSelect';
import FormFileInput from '../../common/form/FormFileInput';
import FormTextArea from '../../common/form/FormTextArea';
import localConstants from '../../../shared/constants';
import { getValue } from '../../../shared/service/localStorage';
import FormMultiSelect from '../../common/form/FormMultiSelect';
import DynamicField from '../../common/dynamicFields/dynamicFields';
import SalesOrderItemList from './itemList/salesOrderItem';
import constants from './constants';
import moment from 'moment';
import PrintComponent from './printComponent/printComponent';

const form = newProps => {
  const {
    t,
    addSalesOrderDetails,
    entityList,
    fetchEntityDetails,
    viewList,
    workflowList,
    quotationList,
    history,
    itemData,
    dynamicColumns,
    resetSalesOrderItemData,
    submitSalesOrder,
    newSalesOrderFormValues,
    salesOrderFormValues,
    setNewSalesOrderFormValues,
    resetNewSalesOrderFormValues,
    isFetchingEntityDetails,
    print,
    customerList,
    // orderSeqList,
    approveRejectSalesOrder,
    fetchCustomerDetails,
    fetchViewDetails,
    fetchItemSeachDetails,
    siteCodeList,
    setSiteCodeList,
  } = newProps;

  const salesOrderTypeList = addSalesOrderDetails.POTypeList || [];
  const currencyList = addSalesOrderDetails.currencyList || [];
  const types = constants.type;

  const getTotal = itemData => {
    return {
      totalNetPrice:
        itemData && itemData.reduce((a, b) => a + b.netPrice, 0).toFixed(2),
      totalTax:
        itemData && itemData.reduce((a, b) => a + b.taxAmount, 0).toFixed(2),
      grandTotal:
        itemData && itemData.reduce((a, b) => a + b.totalAmount, 0).toFixed(2),
    };
  };

  const getFileNames = files => {
    const filesArray = Object.values(files)?.map(e => e.name);
    return filesArray.length
      ? filesArray.toString()
      : t('salesOrder.addItem.chooseFile');
  };

  const getTaxColumns = (customerCountryState, values) => {
    const country = 'India';
    const unionTerritores = [
      'andaman and nicobar islands',
      'chandigarh',
      'delhi',
      'dadra and nagar haveli',
      'daman and diu',
      'jammu and kashmir',
      'lakshadweep',
      'pondicherry',
    ];

    const { entityCountry, entityState } = values;
    const countryState = customerCountryState.split('-');
    if (entityCountry !== country || countryState[0] !== country) {
      return ['VAT'];
    }
    if (
      entityState === countryState[1] &&
      unionTerritores.indexOf(entityState.toLowerCase()) > 0
    ) {
      return ['CGST', 'UTGST'];
    }
    if (entityState !== countryState[1]) {
      return ['IGST'];
    }
    if (entityState === countryState[1]) {
      return ['SGST', 'CGST'];
    }
  };

  const printMode = !!(print && salesOrderFormValues);
  const displayDynamicAttributes = attributes => {
    const values = [];
    Object.keys(attributes).forEach((key, i) => {
      values.push(
        <FormInput
          key={i}
          id={i.toString()}
          name={i}
          label={key}
          value={attributes[key]}
          large
          disabled
        />,
      );
    });
    return values;
  };

  const displayDynamicFields = (data, dynamicFields, props) => {
    const fields = [];
    data.forEach((field, i) => {
      fields.push(
        <DynamicField
          key={i}
          data={field}
          type={field.fieldType}
          dynamicFields={dynamicFields}
          prevProps={props}
        />,
      );
    });
    return fields;
  };

  const parseErrors = errors => {
    if (typeof errors === 'string') return errors;
    else if (Array.isArray(errors)) return errors[0].price;
    else return t('salesOrder.addItem.validations.invalidItem');
  };

  return (
    <div className="AddSalesOrder container">
      <Callout
        className="AddSalesOrder--title"
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title="SalesOrder"
      >
        {printMode ? `Sales Order Details` : 'Create new Sales Order.'}
      </Callout>
      <Formik
        initialValues={
          !printMode && {
            ...newSalesOrderFormValues,
            items: itemData,
            ...getTotal(itemData),
          }
        }
        onSubmit={async (values, { setSubmitting }) => {
          if (printMode) {
            approveRejectSalesOrder(values, salesOrderFormValues, itemData);
            resetSalesOrderItemData();
            resetNewSalesOrderFormValues();
            setSubmitting(false);
          } else {
            submitSalesOrder(values, history);
            resetSalesOrderItemData();
            resetNewSalesOrderFormValues();
            setSubmitting(false);
          }
        }}
        validationSchema={
          !printMode &&
          Yup.object().shape({
            type: Yup.string().required(
              t('salesOrder.addItem.validations.type'),
            ),
            entityId: Yup.string().required(
              t('salesOrder.addItem.validations.entity'),
            ),
            customer: Yup.string().required(
              t('salesOrder.addItem.validations.customer'),
            ),
            poNo: Yup.string().required(
              t('salesOrder.addItem.validations.poNo'),
            ),
            poDate: Yup.date()
              .required(t('salesOrder.addItem.validations.poDate'))
              .max(new Date(), t('salesOrder.addItem.validations.maxPoDate')),
            currency: Yup.string().required(
              t('salesOrder.addItem.validations.entity'),
            ),
            viewId: Yup.string().required(
              t('salesOrder.addItem.validations.view'),
            ),
            workflowId: Yup.string().required(
              t('salesOrder.addItem.validations.workflow'),
            ),
            // validateOrderSeq: Yup.boolean(),
            // orderSeq: Yup.string().when('validateOrderSeq', {
            //   is: true,
            //   then: Yup.string().required(
            //     t('salesOrder.addItem.validations.orderSeq'),
            //   ),
            // }),
            items: Yup.array()
              .of(
                Yup.object().shape({
                  price: Yup.number()
                    .min(1, t('salesOrder.addItem.validations.invalidItem'))
                    .required(t('salesOrder.addItem.validations.invalidItem')),
                }),
              )
              .required(t('salesOrder.addItem.validations.items')),
          })
        }
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          let hideAddItem = !printMode && !!values.quotationId.length;
          const resetFields = level => {
            switch (level) {
              case 0:
                setFieldValue('customer', '');
                setFieldValue('customerPhone', '');
                setFieldValue('workflowId', '');
                setFieldValue('currency', '');
                setFieldValue('orderSeq', '');
                setFieldValue('viewId', '');
                setFieldValue('quotationId', []);
                setFieldValue('siteCodeId', []);
                resetSalesOrderItemData();
                break;
              case 1:
                setFieldValue('viewId', '');
                setFieldValue('quotationId', []);
                setFieldValue('siteCodeId', []);
                resetSalesOrderItemData();
                break;
              case 2:
                setFieldValue('quotationId', []);
                setFieldValue('siteCodeId', []);
                resetSalesOrderItemData();
                break;
              default:
                resetSalesOrderItemData();
                break;
            }
          };
          return (
            <Form
              onSubmit={handleSubmit}
              className="AddSalesOrder__Form"
              style={{ marginBottom: printMode ? '15%' : '' }}
            >
              <Field
                className="AddSalesOrder__Form--fields"
                id="entityId"
                label={t('salesOrder.addItem.entity')}
                component={FormSelect}
                defaultValue={printMode && salesOrderFormValues.entityList}
                disabled={printMode}
                onChange={e => {
                  if (e.value) {
                    setFieldValue('entityId', e.value);
                    resetFields(0);
                    fetchEntityDetails(e.value);
                    setFieldValue('entityState', e.state);
                    setFieldValue('entityCountry', e.country);
                    // setFieldValue('validateOrderSeq', !!orderSeqList.length);
                  }
                }}
                onBlur={e => {
                  handleBlur(e);
                  // setFieldValue('validateOrderSeq', !!orderSeqList.length);
                }}
                options={entityList}
                value={values.entityId}
                helperText={
                  errors.entityId && touched.entityId ? errors.entityId : null
                }
                intent={
                  errors.entityId && touched.entityId
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="type"
                label={t('salesOrder.addItem.type')}
                component={FormSelect}
                defaultValue={printMode && salesOrderFormValues.POTypeList}
                disabled={printMode}
                onChange={e => {
                  e.value === types.blanket && setFieldValue('quotationId', []);
                  setFieldValue('type', e.value);
                }}
                onBlur={handleBlur}
                options={salesOrderTypeList}
                value={values.type}
                helperText={errors.type && touched.type ? errors.type : null}
                intent={
                  errors.type && touched.type ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="customer"
                label={t('salesOrder.addItem.customer')}
                searchable
                component={FormSelect}
                defaultValue={printMode && salesOrderFormValues.customerList}
                onChange={e => {
                  resetFields(1);
                  fetchCustomerDetails(e.value, values.entityId);
                  setFieldValue('customer', e.value);
                  setFieldValue('customerPhone', e.phone);
                  setFieldValue(
                    'taxColumns',
                    getTaxColumns(e.countryState, values),
                  );
                }}
                loading={isFetchingEntityDetails}
                onBlur={handleBlur}
                options={customerList}
                value={values.customer}
                disabled={printMode || !(customerList && customerList.length)}
                helperText={
                  errors.customer && touched.customer ? errors.customer : null
                }
                intent={
                  errors.customer && touched.customer
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="customerPhone"
                label={t('salesOrder.addItem.customerPhone')}
                component={FormInput}
                value={
                  (printMode && salesOrderFormValues.customerPhone) ||
                  values.customerPhone
                }
                disabled
                large
                placeholder={t('salesOrder.addItem.placeholders.customerPhone')}
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="poNo"
                label={t('salesOrder.addItem.poNo')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={(printMode && salesOrderFormValues.poNo) || values.poNo}
                disabled={printMode}
                large
                placeholder={t('salesOrder.addItem.placeholders.poNo')}
                helperText={errors.poNo && touched.poNo ? errors.poNo : null}
                intent={
                  errors.poNo && touched.poNo ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="poDate"
                label={t('salesOrder.addItem.poDate')}
                type="date"
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={printMode}
                value={
                  (printMode &&
                    moment(new Date(salesOrderFormValues.poDate)).format(
                      moment.HTML5_FMT.DATE,
                    )) ||
                  values.poDate
                }
                max={moment(new Date()).format(moment.HTML5_FMT.DATE)}
                large
                helperText={
                  errors.poDate && touched.poDate ? errors.poDate : null
                }
                intent={
                  errors.poDate && touched.poDate ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="currency"
                label={t('salesOrder.addItem.currency')}
                searchable
                component={FormSelect}
                defaultValue={printMode && salesOrderFormValues.currencyList}
                onChange={e => {
                  setFieldValue('currency', e.value);
                  setFieldValue('currencySymbol', e.symbol);
                }}
                loading={isFetchingEntityDetails}
                onBlur={handleBlur}
                options={currencyList}
                value={values.currency}
                disabled={printMode || !(currencyList && currencyList.length)}
                helperText={
                  errors.currency && touched.currency ? errors.currency : null
                }
                intent={
                  errors.currency && touched.currency
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="viewId"
                label={t('salesOrder.addItem.view')}
                searchable
                component={FormSelect}
                defaultValue={printMode && salesOrderFormValues.viewList}
                onChange={e => {
                  resetFields(2);
                  setFieldValue('viewId', e.value);
                  fetchViewDetails(values.entityId, e.value, values.customer);
                  setSiteCodeList(e.siteCodes);
                }}
                loading={isFetchingEntityDetails}
                onBlur={handleBlur}
                options={viewList}
                value={values.viewId}
                disabled={printMode || !(viewList && viewList.length)}
                helperText={
                  errors.viewId && touched.viewId ? errors.viewId : null
                }
                intent={
                  errors.viewId && touched.viewId ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddExpense__Form--fields"
                id="quotationId"
                label={t('salesOrder.addItem.quotation')}
                component={FormMultiSelect}
                loading={isFetchingEntityDetails}
                onChange={e => {
                  setFieldValue('quotationId', e);
                  hideAddItem = !!e.length;
                  !e.length && resetFields(3);
                }}
                defaultValue={printMode && salesOrderFormValues.quotationList}
                values={values.quotationId}
                onBlur={handleBlur}
                options={values.type !== types.blanket ? quotationList : []}
                disabled={printMode || !(quotationList && quotationList.length)}
                helperText={
                  errors.quotationId && touched.quotationId
                    ? errors.quotationId
                    : null
                }
                intent={
                  errors.quotationId && touched.quotationId
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="workflowId"
                label={t('salesOrder.addItem.workflow')}
                component={FormSelect}
                onChange={e => setFieldValue('workflowId', e.value)}
                onBlur={handleBlur}
                options={workflowList}
                value={values.workflowId}
                defaultValue={printMode && salesOrderFormValues.workflowList}
                disabled={printMode || !(workflowList && workflowList.length)}
                helperText={
                  errors.workflowId && touched.workflowId
                    ? errors.workflowId
                    : null
                }
                intent={
                  errors.workflowId && touched.workflowId
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              {getValue(localConstants.LOCAL_STORAGE.PROJ_CODE_VISIBLE) ===
                'Y' && (
                <Field
                  className="AddExpense__Form--fields"
                  id="siteCodeId"
                  label={t('expenses.addItem.siteCodes')}
                  component={FormMultiSelect}
                  loading={isFetchingEntityDetails}
                  onChange={e => {
                    setFieldValue('siteCodeId', e);
                  }}
                  values={values.siteCodeId}
                  onBlur={handleBlur}
                  options={siteCodeList}
                  defaultValue={printMode && salesOrderFormValues.siteCodeList}
                  disabled={printMode || !(siteCodeList && siteCodeList.length)}
                  helperText={
                    errors.siteCodeId && touched.siteCodeId
                      ? errors.siteCodeId
                      : null
                  }
                  intent={
                    errors.siteCodeId && touched.siteCodeId
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {/* <Field
                className="AddSalesOrder__Form--fields"
                id="orderSeq"
                label={t('salesOrder.addItem.orderSeq')}
                component={FormSelect}
                onChange={e => setFieldValue('orderSeq', e.value)}
                onBlur={handleBlur}
                options={orderSeqList}
                value={values.orderSeq}
                defaultValue={printMode && salesOrderFormValues.orderSeqList}
                disabled={printMode || !(orderSeqList && orderSeqList.length)}
                helperText={
                  errors.orderSeq && touched.orderSeq ? errors.orderSeq : null
                }
                intent={
                  errors.orderSeq && touched.orderSeq
                    ? Intent.DANGER
                    : Intent.NONE
                }
              /> */}
              <Field
                className="AddSalesOrder__Form--fields"
                id="startDate"
                label={t('salesOrder.addItem.startDate')}
                type="date"
                disabled={printMode}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={
                  (printMode &&
                    moment(new Date(salesOrderFormValues.startDate)).format(
                      moment.HTML5_FMT.DATE,
                    )) ||
                  values.startDate
                }
                large
              />
              <Field
                className="AddSalesOrder__Form--fields"
                id="endDate"
                label={t('salesOrder.addItem.endDate')}
                type="date"
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={
                  (printMode &&
                    moment(new Date(salesOrderFormValues.endDate)).format(
                      moment.HTML5_FMT.DATE,
                    )) ||
                  values.endDate
                }
                large
              />
              {!printMode &&
                displayDynamicFields(
                  dynamicColumns,
                  newSalesOrderFormValues.dynamicFields,
                  props,
                )}
              {printMode &&
                salesOrderFormValues.dynamicColumns &&
                displayDynamicAttributes(
                  JSON.parse(salesOrderFormValues.dynamicColumns),
                )}
              <Field
                className="AddSalesOrder__Form--fields"
                id="advanceAmount"
                type="number"
                label={t('salesOrder.addItem.advanceAmount')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={
                  (printMode && salesOrderFormValues.advanceAmount) ||
                  values.advanceAmount
                }
                disabled={printMode}
                large
                placeholder={t('salesOrder.addItem.placeholders.advanceAmount')}
              />
              {!printMode && (
                <Field
                  className="AddSalesOrder__Form--fields"
                  id="attachment"
                  label={t('salesOrder.addItem.attachment')}
                  component={FormFileInput}
                  text={getFileNames(values.attachment)}
                  onChange={e => {
                    setFieldValue(
                      'attachment',
                      Object.values(e.currentTarget.files),
                    );
                  }}
                  multiple
                  onBlur={handleBlur}
                  large
                  disabled={printMode}
                  helperText={
                    errors.attachment && touched.attachment
                      ? errors.attachment
                      : null
                  }
                  intent={
                    errors.attachment && touched.attachment
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {printMode && salesOrderFormValues.attachments && (
                <div className={'AddSalesOrder__Form--fields'}>
                  <p className={'SalesOrderItemList--title'}>Attachments</p>
                  {salesOrderFormValues?.attachments?.map((file, i) => (
                    <li key={i}>
                      <a
                        href={file.path}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </li>
                  ))}
                </div>
              )}
              <Field
                className="AddSalesOrder__Form--fields"
                id="comments"
                label={t('salesOrder.addItem.comments')}
                component={FormTextArea}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                value={
                  (printMode && salesOrderFormValues.comments) ||
                  values.comments
                }
                helperText={
                  errors.comments && touched.comments ? errors.comments : null
                }
                intent={
                  errors.comments && touched.comments
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              {((itemData && itemData.length > 0) || printMode) && (
                <SalesOrderItemList
                  items={itemData}
                  printMode={printMode}
                  t={t}
                  values={{
                    history,
                    currencySymbol:
                      (printMode && salesOrderFormValues.currencySymbol) ||
                      values.currencySymbol,
                    taxColumns: values.taxColumns,
                  }}
                />
              )}
              {!hideAddItem && values.type !== types.inventoryPo && !printMode && (
                <Button
                  className="AddSalesOrder__Form--fields"
                  type="button"
                  text="Add Item"
                  large
                  intent="primary"
                  disabled={!(values.customer && values.currency)}
                  onClick={() => {
                    setNewSalesOrderFormValues(values);
                    history.push({
                      pathname: '/home/salesOrder/add/newSalesOrder',
                      state: {
                        taxColumns: values.taxColumns,
                        currencySymbol: values.currencySymbol,
                      },
                    });
                  }}
                />
              )}
              {!printMode && (
                <Button
                  className="AddSalesOrder__Form--fields"
                  type="button"
                  text="Item Search"
                  style={{ marginLeft: '2%' }}
                  large
                  intent="primary"
                  disabled={!(values.customer && values.currency)}
                  onClick={() => {
                    setNewSalesOrderFormValues(values);
                    fetchItemSeachDetails(values);
                    history.push({
                      pathname: '/home/salesOrder/search/salesOrder',
                      state: { fields: values },
                    });
                  }}
                />
              )}
              {errors.items && (
                <div>
                  <p style={{ color: 'red', margin: '1%' }}>
                    {parseErrors(errors.items)}
                  </p>
                </div>
              )}
              <FormInput
                id="totalNetPrice"
                label={t('salesOrder.addItem.totalNetPrice')}
                name="totalNetPrice"
                large
                disabled
                rightElement={
                  <Tag minimal>
                    {(printMode && salesOrderFormValues.currencySymbol) ||
                      values.currencySymbol}
                  </Tag>
                }
                value={
                  printMode
                    ? salesOrderFormValues.totalNetPrice
                    : values.totalNetPrice
                }
              />
              <FormInput
                id="totalTax"
                label={t('salesOrder.addItem.totalTax')}
                name="totalTax"
                large
                disabled
                rightElement={
                  <Tag minimal>
                    {printMode
                      ? salesOrderFormValues.currencySymbol
                      : values.currencySymbol}
                  </Tag>
                }
                value={
                  printMode ? salesOrderFormValues.totalTax : values.totalTax
                }
              />
              <FormInput
                id="grandTotal"
                label={t('salesOrder.addItem.grandTotal')}
                name="grandTotal"
                large
                disabled
                rightElement={
                  <Tag minimal>
                    {(printMode && salesOrderFormValues.currencySymbol) ||
                      values.currencySymbol}
                  </Tag>
                }
                value={
                  printMode
                    ? salesOrderFormValues.grandTotal
                    : values.grandTotal
                }
              />
              {!printMode && (
                <Button
                  className="AddSalesOrder__Form--fields"
                  type="submit"
                  intent="success"
                  text={t('commons.submit')}
                  large
                  onClick={() => {
                    // setFieldValue('validateOrderSeq', !!orderSeqList.length);
                    values.submitType = 'submit';
                  }}
                  disabled={isSubmitting}
                  fill
                />
              )}
              {!printMode && (
                <Button
                  className="AddSalesOrder__Form--fields"
                  type="button"
                  text={t('commons.cancel')}
                  large
                  disabled={isSubmitting}
                  fill
                  onClick={() => {
                    resetSalesOrderItemData();
                    resetNewSalesOrderFormValues();
                    history.goBack();
                  }}
                />
              )}

              {printMode && (
                <div className="AddItem__ButtonGroup">
                  <Button
                    className="AddSalesOrder__Form--fields"
                    style={{ marginRight: '2%', width: '46%' }}
                    intent="success"
                    text={t('commons.print')}
                    large
                    disabled={isSubmitting}
                    fill
                    onClick={() => window.print()}
                  />
                  <Button
                    className="AddSalesOrder__Form--fields"
                    style={{ marginRight: '2%', width: '46%' }}
                    type="button"
                    text={t('commons.cancel')}
                    large
                    disabled={isSubmitting}
                    fill
                    onClick={() => {
                      resetSalesOrderItemData();
                      resetNewSalesOrderFormValues();
                      history.goBack();
                    }}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      {printMode && (
        <div className={'AddSalesOrder__Print'}>
          <PrintComponent
            values={salesOrderFormValues.printData}
          ></PrintComponent>
        </div>
      )}
    </div>
  );
};
class AddSalesOrderComponent extends Component {
  componentDidMount() {
    if (this.props.print === true) {
      const history = this.props.history;
      const selectedTask =
        history.location.state && history.location.state.selectedTask;
      if (!selectedTask) this.props.history.push('/home/salesOrder');
      else this.props.getSalesOrderDetails(selectedTask);
    } else {
      this.props.fetchAddSalesOrderDetails();
      this.props.history.action !== 'POP' &&
        this.props.resetSalesOrderItemData();
    }
  }

  render() {
    const {
      addSalesOrderDetailsPromise,
      updateSalesOrderPromise,
      taskSalesOrderPromise,
    } = this.props;
    if (updateSalesOrderPromise.isPending) {
      return <Spinner intent={Intent.PRIMARY} className="progress" />;
    }
    if (taskSalesOrderPromise.isPending) {
      return <Spinner intent={Intent.PRIMARY} className="progress" />;
    }
    return (
      <div className="AddSalesOrder">
        {addSalesOrderDetailsPromise.isPending && (
          <Spinner intent={Intent.PRIMARY} className="progress" />
        )}
        {(addSalesOrderDetailsPromise.isFulfilled ||
          taskSalesOrderPromise.isFulfilled) &&
          form(this.props)}
      </div>
    );
  }
}
AddSalesOrderComponent.propTypes = {
  t: PropTypes.func.isRequired,
  newSalesOrderFormValues: PropTypes.object.isRequired,
  addSalesOrderDetailsPromise: PropTypes.object.isRequired,
  updateSalesOrderPromise: PropTypes.object.isRequired,
  addSalesOrderDetails: PropTypes.object.isRequired,
  fetchEntityDetails: PropTypes.func.isRequired,
  isFetchingEntityDetails: PropTypes.bool.isRequired,
  entityList: PropTypes.array.isRequired,
  workflowList: PropTypes.array.isRequired,
  fetchAddSalesOrderDetails: PropTypes.func.isRequired,
  resetSalesOrderItemData: PropTypes.func.isRequired,
  setSalesOrderItemData: PropTypes.func.isRequired,
  itemData: PropTypes.array.isRequired,
  submitSalesOrder: PropTypes.func.isRequired,
  setNewSalesOrderFormValues: PropTypes.func.isRequired,
  resetNewSalesOrderFormValues: PropTypes.func.isRequired,
  setSiteCodeList: PropTypes.func.isRequired,
  siteCodeList: PropTypes.array,
};

AddSalesOrderComponent.defaultProps = {
  siteCodeList: [],
};

export default AddSalesOrderComponent;
