import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import NavBar from '../common/navBar';
import Drawer from '../common/SwipeableDrawer';
import MyTasksContainer from '../tasks/myTasks/MyTasksContainer';
import ExpenseContainer from '../expense/ExpenseContainer';
import TimesheetContainer from '../timesheets/TimesheetsContainer';
import history from '../../shared/service/history';
import PickUpContainer from '../tasks/pickup/PickUpContainer';
import InvoiceContainer from '../tasks/invoice/InvoiceContainer';
import AssetContainer from '../tasks/asset/AssetContainer';
import AppSnackBar from '../common/appSnackBar';
import AddExpenseContainer from '../expense/addExpense/AddExpenseContainer';
import NewItemContainer from '../expense/addExpense/newItem/NewItemContainer';
import AddTimesheetsContainer from '../timesheets/addTimesheets/AddTimesheetsContainer';
import NewTimesheetContainer from '../timesheets/addTimesheets/newTimesheet/NewTimesheetContainer';
import PurchaseOrderContainer from '../tasks/purchaseOrder/container';
import LineDetails from '../tasks/purchaseOrder/components/LineDetails';
import PickUpLineDetails from '../tasks/pickup/components/PickUpLineDetails';
import SalesOrderContainer from '../salesorder/container';
import AddSalesOrderContainer from '../salesorder/addSalesOrder/addSalesOrderContainer';
import NewSalesOrderContainer from '../salesorder/addSalesOrder/newItem/NewSalesOrderContainer';
import SearchSalesOrderContainer from '../salesorder/addSalesOrder/searchItem/SearchSalesOrderContainer';
import './home.scss';

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpen: false,
    };
  }

  render() {
    const toggleDrawer = isDrawerOpen => {
      this.setState({
        isDrawerOpen,
      });
    };

    const { toast, handleToastClose, match, fetchHRMSLoginURL } = this.props;

    return (
      <div className="Home">
        <NavBar
          isDrawerOpen={this.state.isDrawerOpen}
          toggleDrawer={toggleDrawer}
          style={{ paddingBottom: '30px' }}
        />
        <Drawer
          isDrawerOpen={this.state.isDrawerOpen}
          toggleDrawer={toggleDrawer}
          fetchHRMSLoginURL={fetchHRMSLoginURL}
        />
        <AppSnackBar
          variant={toast.variant}
          message={toast.message}
          open={toast.isOpen}
          handleClose={() => handleToastClose()}
        />
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={`${match.url}/tasks`}
              render={() => <MyTasksContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/expense`}
              render={() => <ExpenseContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/salesOrder`}
              render={() => <SalesOrderContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/timesheets`}
              render={() => <TimesheetContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/po`}
              render={() => <PurchaseOrderContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/po/line`}
              render={() => <LineDetails history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/pickUp`}
              render={() => <PickUpContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/pickUp/line`}
              render={() => <PickUpLineDetails history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/invoice`}
              render={() => <InvoiceContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/asset`}
              render={() => <AssetContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/tasks/expense`}
              render={() => <AddExpenseContainer history={history} isEdit />}
            />
            <Route
              exact
              path={`${match.url}/expense/add`}
              render={() => <AddExpenseContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/expense/add/newItem`}
              render={() => <NewItemContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/timesheets/add`}
              render={() => <AddTimesheetsContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/timesheets/add/newTimesheets`}
              render={() => <NewTimesheetContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/salesOrder/add`}
              render={() => <AddSalesOrderContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/salesOrder/details`}
              render={() => <AddSalesOrderContainer history={history} print />}
            />
            <Route
              exact
              path={`${match.url}/salesOrder/add/newSalesOrder`}
              render={() => <NewSalesOrderContainer history={history} />}
            />
            <Route
              exact
              path={`${match.url}/salesOrder/search/salesOrder`}
              render={() => <SearchSalesOrderContainer history={history} />}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default HomeComponent;
