// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../shared/fonts/Juicebox.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media print {\n  @font-face {\n    font-family: \"recipt\";\n    font-style: normal;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n  body {\n    font-family: \"Open Sans\"; }\n  @page {\n    margin: 0; }\n  .PrintComponent {\n    font-size: 14px;\n    font-family: \"Open Sans\";\n    font-weight: normal;\n    margin: 0;\n    padding: 0px;\n    letter-spacing: 1px; }\n    .PrintComponent__Item {\n      width: 100%; }\n      .PrintComponent__Item table,\n      .PrintComponent__Item td {\n        word-break: break-all;\n        text-align: left;\n        border-collapse: collapse; }\n      .PrintComponent__Item thead {\n        display: table-row-group;\n        font-weight: normal; }\n    .PrintComponent__TaxItems {\n      width: 80%;\n      text-align: left;\n      margin-left: 10%; }\n  p {\n    margin: 0%; }\n  hr {\n    margin: 2px !important; } }\n", ""]);
// Exports
module.exports = exports;
