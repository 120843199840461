import React from 'react';
import PropTypes from 'prop-types';
import { Toaster, Toast, Position, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const variantIcon = {
  success: IconNames.TICK_CIRCLE,
  warning: IconNames.INFO_SIGN,
  error: IconNames.ERROR,
  info: IconNames.INFO_SIGN,
};

const intent = {
  success: Intent.SUCCESS,
  warning: Intent.WARNING,
  error: Intent.DANGER,
  info: Intent.PRIMARY,
};

function CustomSnackBarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  return (
    <Toast
      message={message}
      intent={intent[variant]}
      icon={variantIcon[variant]}
      onDismiss={onClose}
      {...other}
    />
  );
}

CustomSnackBarContent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const ToastContent = CustomSnackBarContent;

const AppSnackBar = props => {
  const { open, variant, message, handleClose } = props;
  return (
    <div>
      {open && (
        <Toaster position={Position.BOTTOM} onClose={handleClose} usePortal>
          <ToastContent
            onClose={() => handleClose()}
            variant={variant}
            message={message}
          />
        </Toaster>
      )}
    </div>
  );
};

AppSnackBar.propTypes = {
  variant: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AppSnackBar;
