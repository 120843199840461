import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Spinner, Intent, Button, Callout } from '@blueprintjs/core';
import POLine from './components/POLine';
import POPrice from './components/POPrice';
import POFooter from './components/POFooter';
import POHeader from './components/POHeader';
import { Form, Formik } from 'formik';
import { IconNames } from '@blueprintjs/icons';
import POInventoryLine from './components/POInventoryLine';
import PORequisition from './components/PORequisition';

class POComponent extends Component {
  componentDidMount() {
    const history = this.props.history;
    const selectedTask =
      history.location.state && history.location.state.selectedTask;
    if (!selectedTask) this.props.history.push('/home/tasks');
    else this.props.getPO(selectedTask);
  }

  render() {
    const { po, promise, history, updatePO } = this.props;
    const selectedTask =
      history.location.state && history.location.state.selectedTask;
    const onSubmit = (values, { setSubmitting }) => {
      values.companyId = selectedTask.companyId;
      updatePO(values, po);
      setSubmitting(false);
    };

    return (
      promise.isFulfilled && (
        <Formik onSubmit={onSubmit}>
          {props => {
            const { values, isSubmitting, handleChange, handleSubmit } = props;
            return (
              <div className="PO container">
                <Form onSubmit={handleSubmit}>
                  <div>
                    <Callout
                      intent={Intent.PRIMARY}
                      icon={IconNames.INFO_SIGN}
                      title={po.title}
                    >
                      Approve or Reject {selectedTask.requestNo}
                    </Callout>
                    <POHeader po={po} />
                    {po.lineItems && (
                      <POLine lines={po.lineItems} history={history} />
                    )}

                    {po.poRequisitionsList && (
                      <PORequisition
                        list={po.poRequisitionsList}
                        history={history}
                      />
                    )}
                    <POPrice po={po} />
                    {po.poInventoryLineItems && (
                      <POInventoryLine
                        lines={po.poInventoryLineItems}
                        history={history}
                      />
                    )}
                    <POFooter
                      po={po}
                      handleChange={handleChange}
                      values={values}
                    />
                    <div className="Form--fields PO__actions">
                      <Button
                        className="PO__buttons"
                        text="Approve"
                        type="submit"
                        large
                        disabled={isSubmitting}
                        intent={Intent.PRIMARY}
                        onClick={() => {
                          values.submitType = 'approve';
                        }}
                      />
                      <Button
                        className="PO__buttons"
                        text="Reject"
                        type="submit"
                        intent={Intent.DANGER}
                        large
                        disabled={isSubmitting}
                        onClick={() => {
                          values.submitType = 'reject';
                        }}
                      />
                      <Button
                        text="Cancel"
                        className="PO__buttons"
                        disabled={isSubmitting}
                        onClick={() => history.goBack()}
                        large
                      />
                    </div>
                  </div>
                </Form>
                {promise.isPending && (
                  <Spinner intent={Intent.PRIMARY} className="progress" />
                )}
              </div>
            );
          }}
        </Formik>
      )
    );
  }
}

POComponent.propTypes = {
  getPO: PropTypes.func.isRequired,
  updatePO: PropTypes.func.isRequired,
  selectedTask: PropTypes.object.isRequired,
  po: PropTypes.object.isRequired,
  promise: PropTypes.object.isRequired,
};

export default POComponent;
