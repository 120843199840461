import addSalesOrderInitialState from './addSalesOrderInitialState';
import addSalesOrderActionTypes from './addSalesOrderActionTypes';
import setPromiseState from '../../../shared/service/promiseState';

const addSalesOrderReducer = (state = addSalesOrderInitialState, action) => {
  switch (action.type) {
    case addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.fulfilled:
      return state.setIn(
        ['promise', 'addSalesOrderDetails'],
        setPromiseState(false, true, false),
      );
    case addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.pending:
      return state.setIn(
        ['promise', 'addSalesOrderDetails'],
        setPromiseState(true, false, false),
      );
    case addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.rejected:
      return state.setIn(
        ['promise', 'addSalesOrderDetails'],
        setPromiseState(false, false, true),
      );
    case addSalesOrderActionTypes.SET_ADD_SALESORDER_DETAILS:
      return state.set('addSalesOrderDetails', action.addSalesOrderDetails);

    case addSalesOrderActionTypes.TASK_SALESORDER.fulfilled:
      return state.setIn(
        ['promise', 'taskSalesOrder'],
        setPromiseState(false, true, false),
      );
    case addSalesOrderActionTypes.TASK_SALESORDER.pending:
      return state.setIn(
        ['promise', 'taskSalesOrder'],
        setPromiseState(true, false, false),
      );
    case addSalesOrderActionTypes.TASK_SALESORDER.rejected:
      return state.setIn(
        ['promise', 'taskSalesOrder'],
        setPromiseState(false, false, true),
      );
    case addSalesOrderActionTypes.UPDATE_SALESORDER.fulfilled:
      return state.setIn(
        ['promise', 'updateSalesOrder'],
        setPromiseState(false, true, false),
      );
    case addSalesOrderActionTypes.UPDATE_SALESORDER.pending:
      return state.setIn(
        ['promise', 'updateSalesOrder'],
        setPromiseState(true, false, false),
      );
    case addSalesOrderActionTypes.UPDATE_SALESORDER.rejected:
      return state.setIn(
        ['promise', 'updateSalesOrder'],
        setPromiseState(false, false, true),
      );
    case addSalesOrderActionTypes.IS_FETCHING_ENTITY_DETAILS:
      return state.set(
        'isFetchingEntityDetails',
        action.isFetchingEntityDetails,
      );
    case addSalesOrderActionTypes.IS_FETCHING_CUSTOMER_DETAILS:
      return state.set(
        'isFetchingCustomerDetails',
        action.isFetchingCustomerDetails,
      );
    case addSalesOrderActionTypes.IS_FETCHING_VIEW_DETAILS:
      return state.set('isFetchingViewDetails', action.isFetchingViewDetails);
    case addSalesOrderActionTypes.SET_ENTITY_DETAILS:
      return state
        .set('workflowList', action.entityDetails.workflowList)
        .set('customerList', action.entityDetails.customerList)
        .set('orderSeqList', action.entityDetails.orderSeqList);
    case addSalesOrderActionTypes.SET_CUSTOMER_DETAILS:
      return state.set('customerDetails', action.customerDetails);
    case addSalesOrderActionTypes.SET_VIEW_DETAILS:
      return state.set('viewDetails', action.viewDetails);
    case addSalesOrderActionTypes.SET_SEARCH_ITEM_DETAILS:
      return state.set('searchItemDetails', action.searchItemDetails);

    case addSalesOrderActionTypes.IS_FETCHING_ADVANCE_DETAILS:
      return state.set(
        'isFetchingAdvanceDetails',
        action.isFetchingAdvanceDetails,
      );
    case addSalesOrderActionTypes.SET_ADVANCE_DETAILS:
      return state.set('advanceList', action.advanceDetails.advanceList);

    case addSalesOrderActionTypes.SET_ITEM_DATA:
      return state.set('itemData', action.itemData);
    case addSalesOrderActionTypes.RESET_ITEM_DATA:
      return state.set('itemData', addSalesOrderInitialState.itemData);

    case addSalesOrderActionTypes.SET_DYNAMIC_COLUMNS:
      return state.set('dynamicColumns', action.dynamicColumns);
    case addSalesOrderActionTypes.RESET_DYNAMIC_COLUMNS:
      return state.set(
        'dynamicColumns',
        addSalesOrderInitialState.dynamicColumns,
      );

    case addSalesOrderActionTypes.SET_NEW_SALESORDER_FORM_VALUES:
      return state.set(
        'newSalesOrderFormValues',
        action.newSalesOrderFormValues,
      );
    case addSalesOrderActionTypes.RESET_NEW_SALESORDER_FORM_VALUES:
      return state
        .set(
          'newSalesOrderFormValues',
          addSalesOrderInitialState.newSalesOrderFormValues,
        )
        .set('viewList', addSalesOrderInitialState.viewList)
        .set('advanceList', addSalesOrderInitialState.advanceList)
        .set('projectCodeList', addSalesOrderInitialState.projectCodeList)
        .set('workflowList', addSalesOrderInitialState.workflowList);

    case addSalesOrderActionTypes.SET_NEW_ITEM_FORM_VALUES:
      return state.set('newItemFormValues', action.newItemFormValues);
    case addSalesOrderActionTypes.SET_SALESORDER_FORM_VALUES:
      return state.set('salesOrderFormValues', action.salesOrderFormValues);
    case addSalesOrderActionTypes.SET_SELECTED_CATEGORY:
      return state.set('selectedCategory', action.selectedCategory);
    case addSalesOrderActionTypes.SET_SALESORDER_UPDATE_RESPONSE:
      return state.set('salesOrderUpdateResponse', action.salesOrder);
    case addSalesOrderActionTypes.SET_SITE_CODE_LIST:
      return state.set('siteCodeList', action.siteCodes);
    case addSalesOrderActionTypes.SET_SALESORDER_APPROVAL_RESPONSE:
      return state.set('salesOrderApprovalResponse', action.salesOrder);
    default:
      return state;
  }
};

export default addSalesOrderReducer;
