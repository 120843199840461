import React from 'react';
import { Dialog, Classes } from '@blueprintjs/core';

const DialogComponent = props => {
  const { dialogBody, className, icon, onClose, isOpen, title, style } = props;
  return (
    <Dialog
      icon={icon}
      onClose={onClose}
      title={title}
      className={className}
      isOpen={isOpen}
      usePortal
      lazy
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      style={style}
    >
      <div className={Classes.DIALOG_BODY}>{dialogBody}</div>
    </Dialog>
  );
};

export default DialogComponent;
