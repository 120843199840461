import dotenv from 'dotenv';

dotenv.config();

const baseUrl = process.env.REACT_APP_BASE_URL;

const apiService = {
  baseUrl,
  timeout: 1000,
  endpoints: {
    app: {
      login: '/login',
      generateLoginUrl() {
        return `${baseUrl}${this.login}`;
      },
      tasks: '/tasks',
      generateTasksUrl() {
        return `${baseUrl}${this.tasks}`;
      },
      po: '/po',
      generatePOUrl() {
        return `${baseUrl}${this.po}`;
      },
      updatePO: '/updatePO',
      generateUpdatePOUrl() {
        return `${baseUrl}${this.updatePO}`;
      },
      pickUp: '/pickUp',
      generatePickUpUrl() {
        return `${baseUrl}${this.pickUp}`;
      },
      updatePickUp: '/updatePickUp',
      generateUpdatePickUpUrl() {
        return `${baseUrl}${this.updatePickUp}`;
      },
      invoice: '/invoice',
      generateInvoiceUrl() {
        return `${baseUrl}${this.invoice}`;
      },
      updateInvoice: '/updateInvoice',
      generateUpdateInvoiceUrl() {
        return `${baseUrl}${this.updateInvoice}`;
      },
      asset: '/asset',
      generateAssetUrl() {
        return `${baseUrl}${this.asset}`;
      },
      updateAsset: '/updateAsset',
      generateUpdateAssetUrl() {
        return `${baseUrl}${this.updateAsset}`;
      },
      fetchAddExpenseDetails: '/addExpenseDetails',
      generateFetchAddExpenseDetailsUrl() {
        return `${baseUrl}${this.fetchAddExpenseDetails}`;
      },
      fetchEntityDetails: '/entityDetails',
      generateFetchEntityDetailsUrl() {
        return `${baseUrl}${this.fetchEntityDetails}`;
      },
      fetcAdvanceDetails: '/advanceDetails',
      generateFetchAdvanceDetailsUrl() {
        return `${baseUrl}${this.fetcAdvanceDetails}`;
      },
      fetchItemData: '/itemData',
      generateItemDataUrl() {
        return `${baseUrl}${this.fetchItemData}`;
      },
      fetchExpenseGrid: '/expenseGrid',
      generateExpenseGridUrl() {
        return `${baseUrl}${this.fetchExpenseGrid}`;
      },
      updateExpense: '/updateExpense',
      generateUpdateExpenseUrl() {
        return `${baseUrl}${this.updateExpense}`;
      },
      fetchAddTimesheetsDetails: '/addTimesheetDetails',
      generateFetchAddTimesheetsDetailsUrl() {
        return `${baseUrl}${this.fetchAddTimesheetsDetails}`;
      },
      fetchTimesheetDetails: '/timesheetDetails',
      generateFetchTimesheetDetailsUrl() {
        return `${baseUrl}${this.fetchTimesheetDetails}`;
      },
      fetchTimesheetGrid: '/timesheetGrid',
      generateTimesheetGridUrl() {
        return `${baseUrl}${this.fetchTimesheetGrid}`;
      },
      fetchTimesheetEntityDetails: '/timesheetEntityDetails',
      generateFetchTimesheetEntityDetailsUrl() {
        return `${baseUrl}${this.fetchTimesheetEntityDetails}`;
      },
      fetchExpenseForApproval: '/expense',
      generateFetchExpenseForApprovalUrl() {
        return `${baseUrl}${this.fetchExpenseForApproval}`;
      },
      fetchApproveReject: '/approveRejectExpense',
      generateFetchApproveRejectURL() {
        return `${baseUrl}${this.fetchApproveReject}`;
      },
      fetchTimeSheetResourcesGridUrl: '/timesheetResourceGrid',
      generatefetchTimeSheetResourcesGridUrl() {
        return `${baseUrl}${this.fetchTimeSheetResourcesGridUrl}`;
      },
      fetchUpdateTimesheetsUrl: '/submitTimesheet',
      generateUpdateTimesheetsUrl() {
        return `${baseUrl}${this.fetchUpdateTimesheetsUrl}`;
      },
      fetchSalesOrderGrid: '/salesOrderGrid',
      generateSalesOrderGridUrl() {
        return `${baseUrl}${this.fetchSalesOrderGrid}`;
      },
      fetchSalesOrderEntityDetails: '/salesOrderEntityDetails',
      generateSalesOrderEntityDetailsUrl() {
        return `${baseUrl}${this.fetchSalesOrderEntityDetails}`;
      },
      fetchAddSalesOrderDetails: '/newSalesOrderDetails',
      generateAddSalesOrderDetailsUrl() {
        return `${baseUrl}${this.fetchAddSalesOrderDetails}`;
      },
      fetchCustomerDetails: '/salesOrderCustomerDetails',
      generateSalesOrderCustomerDetailsUrl() {
        return `${baseUrl}${this.fetchCustomerDetails}`;
      },
      fetchViewDetails: '/salesOrderViewDetails',
      generateSalesOrderViewDetailsUrl() {
        return `${baseUrl}${this.fetchViewDetails}`;
      },
      fetchSearchItemDetails: '/salesOrderSearchItemDetails',
      generateSalesOrderSearchItemDetailsUrl() {
        return `${baseUrl}${this.fetchSearchItemDetails}`;
      },
      fetchAddSalesOrder: '/addSalesOrder',
      generateUpdateSalesOrderUrl() {
        return `${baseUrl}${this.fetchAddSalesOrder}`;
      },
      fetchSalesOrderDetails: '/salesOrderDetails',
      generateSalesOrderDetailsUrl() {
        return `${baseUrl}${this.fetchSalesOrderDetails}`;
      },
      fetchHRMS: '/hrms',
      generateHRMSUrl() {
        return `${baseUrl}${this.fetchHRMS}`;
      },
    },
  },
};

export default apiService;
