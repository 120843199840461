import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ExpenseDesktop from './desktop';
import t from '../../shared/service/translations';
import { Button, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

class ExpenseComponent extends Component {
  state = {
    anchorEl: null
  };

  componentWillMount() {
    this.props.fetchExpenseGrid();
  }

  render() {
    const { anchorEl } = this.state;
    const { history, expenseGrid, expensePromise } = this.props;

    return (
      <div className='Expense container'>
        <h4>{t('expenses.header')}</h4>
        <ExpenseDesktop
          rows={expenseGrid.rows || []}
          promise={expensePromise}
        />

        <Button
          className='Expense__Add'
          icon={<Icon icon={IconNames.PLUS} iconSize={Icon.SIZE_LARGE} />}
          intent={Intent.DANGER}
          minimal={true}
          onClick={() => history.push('/home/expense/add')}
          aria-owns={anchorEl ? 'expense-menu' : undefined}
          aria-haspopup='true'
        />
      </div>
    );
  }
}

ExpenseComponent.propTypes = {
  fetchExpenseGrid: PropTypes.func.isRequired,
  expenseGrid: PropTypes.object.isRequired,
  expensePromise: PropTypes.object.isRequired
};

export default ExpenseComponent;
