const addExpenseActionTypes = {
  ADD_EXPENSE_DETAILS: {
    pending: 'addExpenseDetails/pending',
    fulfilled: 'addExpenseDetails/fulfilled',
    rejected: 'addExpenseDetails/rejected',
  },
  UPDATE_EXPENSE: {
    pending: 'updateExpense/pending',
    fulfilled: 'updateExpense/fulfilled',
    failed: 'updateExpense/failed',
  },
  TASK_EXPENSE: {
    pending: 'taskExpense/pending',
    fulfilled: 'taskExpense/fulfilled',
    rejected: 'taskExpense/rejected',
  },
  SET_ADD_EXPENSE_DETAILS: 'SET_ADD_EXPENSE_DETAILS',
  IS_FETCHING_ENTITY_DETAILS: 'IS_FETCHING_ENTITY_DETAILS',
  SET_ENTITY_DETAILS: 'SET_ENTITY_DETAILS',
  SET_ITEM_DATA: 'SET_ITEM_DATA',
  RESET_ITEM_DATA: 'RESET_ITEM_DATA',
  SET_DYNAMIC_COLUMNS: 'SET_DYNAMIC_COLUMNS',
  RESET_DYNAMIC_COLUMNS: 'RESET_DYNAMIC_COLUMNS',
  IS_FETCHING_ADVANCE_DETAILS: 'IS_FETCHING_ADVANCE_DETAILS',
  SET_ADVANCE_DETAILS: 'SET_ADVANCE_DETAILS',

  SET_NEW_EXPENSE_FORM_VALUES: 'SET_NEW_EXPENSE_FORM_VALUES',
  RESET_NEW_EXPENSE_FORM_VALUES: 'RESET_NEW_EXPENSE_FORM_VALUES',

  SET_NEW_ITEM_FORM_VALUES: 'SET_NEW_ITEM_FORM_VALUES',

  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',

  SET_EXPENSE_UPDATE_RESPONSE: 'SET_EXPENSE_UPDATE_RESPONSE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_EXPENSE: 'SET_EXPENSE',

  SET_SITE_CODE_LIST: 'SET_SITE_CODE_LIST',
  SET_SELECTED_TASK: 'SET_SELECTED_TASK',
  SET_EXPENSE_FORM_VALUES: 'SET_EXPENSE_FORM_VALUES',
  SET_EXPENSE_APPROVAL_RESPONSE: 'SET_EXPENSE_APPROVAL_RESPONSE',
};

export default addExpenseActionTypes;
