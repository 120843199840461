import { connect } from 'react-redux';
import NewItem from './NewItem';
import {
  setItemData,
  deleteInItemData,
  setSelectedCategory,
} from '../addExpenseActions';
import t from '../../../../shared/service/translations';
import {
  selectItemData,
  selectNewExpenseFormValues,
  selectNewItemFormValues,
  getCategoriesTree,
  getCategories,
  selectSelectedCategory,
  selectExpenseFormValues,
} from '../addExpenseSelector';

const mapStateToProps = state => {
  return {
    t,
    selectNewExpenseFormValues: selectNewExpenseFormValues(state),
    itemData: selectItemData(state),
    newItemValues: selectNewItemFormValues(state),
    categoriesTree: getCategoriesTree(state),
    categories: getCategories(state),
    selectedCategory: selectSelectedCategory(state),
    expenseFormValues: selectExpenseFormValues(state),
  };
};

const mapDispatchToProps = {
  setItemData,
  deleteInItemData,
  setSelectedCategory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewItem);
