import React from 'react';
import PropTypes from 'prop-types';
import '../pickUp.scss';
import FormTextArea from '../../../common/form/FormTextArea';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';

const PickUpFooter = props => {
  const { handleChange, pickUp, values } = props;
  return (
    <div className="PickUp__footer">
      {pickUp.comments && (
        <FormInput
          id={constants.ids.comments}
          name={constants.ids.comments}
          label={constants.labels.comments}
          value={pickUp.comments}
          large
          disabled
        />
      )}
      <FormTextArea
        id="comments"
        label="Approval Comments"
        value={values.comments}
        name="comments"
        className="PickUp__footer--field"
        onChange={handleChange}
        large
      />
    </div>
  );
};

PickUpFooter.propType = {
  handleChange: PropTypes.func.isRequired,
  pickUp: PropTypes.object.isRequired,
};

export default PickUpFooter;
