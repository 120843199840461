import addExpenseInitialState from './addExpenseInitialState';
import addExpenseActionTypes from './addExpenseActionTypes';
import setPromiseState from '../../../shared/service/promiseState';

const addExpenseReducer = (state = addExpenseInitialState, action) => {
  switch (action.type) {
    case addExpenseActionTypes.ADD_EXPENSE_DETAILS.fulfilled:
      return state.setIn(
        ['promise', 'addExpenseDetails'],
        setPromiseState(false, true, false),
      );
    case addExpenseActionTypes.ADD_EXPENSE_DETAILS.pending:
      return state.setIn(
        ['promise', 'addExpenseDetails'],
        setPromiseState(true, false, false),
      );
    case addExpenseActionTypes.ADD_EXPENSE_DETAILS.rejected:
      return state.setIn(
        ['promise', 'addExpenseDetails'],
        setPromiseState(false, false, true),
      );
    case addExpenseActionTypes.SET_ADD_EXPENSE_DETAILS:
      return state.set('addExpenseDetails', action.addExpenseDetails);

    case addExpenseActionTypes.TASK_EXPENSE.fulfilled:
      return state.setIn(
        ['promise', 'taskExpense'],
        setPromiseState(false, true, false),
      );
    case addExpenseActionTypes.TASK_EXPENSE.pending:
      return state.setIn(
        ['promise', 'taskExpense'],
        setPromiseState(true, false, false),
      );
    case addExpenseActionTypes.TASK_EXPENSE.rejected:
      return state.setIn(
        ['promise', 'taskExpense'],
        setPromiseState(false, false, true),
      );
    case addExpenseActionTypes.UPDATE_EXPENSE.fulfilled:
      return state.setIn(
        ['promise', 'updateExpense'],
        setPromiseState(false, true, false),
      );
    case addExpenseActionTypes.UPDATE_EXPENSE.pending:
      return state.setIn(
        ['promise', 'updateExpense'],
        setPromiseState(true, false, false),
      );
    case addExpenseActionTypes.UPDATE_EXPENSE.rejected:
      return state.setIn(
        ['promise', 'updateExpense'],
        setPromiseState(false, false, true),
      );
    case addExpenseActionTypes.IS_FETCHING_ENTITY_DETAILS:
      return state.set(
        'isFetchingEntityDetails',
        action.isFetchingEntityDetails,
      );
    case addExpenseActionTypes.SET_ENTITY_DETAILS:
      return state
        .set('viewList', action.entityDetails.viewList)
        .set('workflowList', action.entityDetails.workflowList)
        .set('projectCodeList', action.entityDetails.projectCodesList);

    case addExpenseActionTypes.IS_FETCHING_ADVANCE_DETAILS:
      return state.set(
        'isFetchingAdvanceDetails',
        action.isFetchingAdvanceDetails,
      );
    case addExpenseActionTypes.SET_ADVANCE_DETAILS:
      return state.set('advanceList', action.advanceDetails.advanceList);

    case addExpenseActionTypes.SET_ITEM_DATA:
      return state.set('itemData', action.itemData);
    case addExpenseActionTypes.RESET_ITEM_DATA:
      return state.set('itemData', addExpenseInitialState.itemData);

    case addExpenseActionTypes.SET_DYNAMIC_COLUMNS:
      return state.set('dynamicColumns', action.dynamicColumns);
    case addExpenseActionTypes.RESET_DYNAMIC_COLUMNS:
      return state.set('dynamicColumns', addExpenseInitialState.dynamicColumns);

    case addExpenseActionTypes.SET_NEW_EXPENSE_FORM_VALUES:
      return state.set('newExpenseFormValues', action.newExpenseFormValues);
    case addExpenseActionTypes.RESET_NEW_EXPENSE_FORM_VALUES:
      return state
        .set(
          'newExpenseFormValues',
          addExpenseInitialState.newExpenseFormValues,
        )
        .set('viewList', addExpenseInitialState.viewList)
        .set('advanceList', addExpenseInitialState.advanceList)
        .set('projectCodeList', addExpenseInitialState.projectCodeList)
        .set('workflowList', addExpenseInitialState.workflowList);

    case addExpenseActionTypes.SET_NEW_ITEM_FORM_VALUES:
      return state.set('newItemFormValues', action.newItemFormValues);
    case addExpenseActionTypes.SET_EXPENSE_FORM_VALUES:
      return state.set('expenseFormValues', action.expenseFormValues);
    case addExpenseActionTypes.SET_SELECTED_CATEGORY:
      return state.set('selectedCategory', action.selectedCategory);
    case addExpenseActionTypes.SET_EXPENSE_UPDATE_RESPONSE:
      return state.set('expenseUpdateResponse', action.expense);
    case addExpenseActionTypes.SET_SITE_CODE_LIST:
      return state.set('siteCodeList', action.siteCodes);
    case addExpenseActionTypes.SET_EXPENSE_APPROVAL_RESPONSE:
      return state.set('expenseApprovalResponse', action.expense);
    default:
      return state;
  }
};

export default addExpenseReducer;
