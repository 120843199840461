import React from 'react';
import PropTypes from 'prop-types';
import '../invoice.scss';
import { Tag } from '@blueprintjs/core';
import FormInput from '../../../common/form/FormInput';
import constants from '../../common/constants';

const Price = props => {
  const { invoice } = props;
  return (
    <div>
      <div className="Form--fields Form--fields__right">
        <FormInput
          id={constants.ids.subTotalAmt}
          label={constants.labels.subTotalAmt}
          name={constants.ids.subTotalAmt}
          value={invoice.subTotalAmt}
          readOnly
          large
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
        />
      </div>
      <div className="Form--fields Form--fields__right">
        <FormInput
          id={constants.ids.additionalAmt}
          label={constants.labels.additionalAmt}
          name={constants.ids.additionalAmt}
          readOnly
          large
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={invoice.additionalAmt}
        />
      </div>
      <div className="Form--fields Form--fields__right">
        <FormInput
          id={constants.ids.adjustedAmt}
          label={constants.labels.adjustedAmt}
          name={constants.ids.adjustedAmt}
          readOnly
          large
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={invoice.adjustedAmt}
        />
      </div>
      <div className="Form--fields Form--fields__right">
        <FormInput
          id={constants.ids.discount}
          label={constants.labels.discount}
          name={constants.ids.discount}
          readOnly
          large
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={invoice.discount}
        />
      </div>
      <div className="Form--fields Form--fields__right">
        <FormInput
          id={constants.ids.grandTotal}
          label={constants.labels.grandTotal}
          name={constants.ids.grandTotal}
          readOnly
          large
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={invoice.grandTotal}
        />
      </div>
    </div>
  );
};

Price.propType = {
  lines: PropTypes.array.isRequired,
};

export default Price;
