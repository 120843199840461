import axios from 'axios';
import * as moment from 'moment';
import addTimesheetsActionTypes from './addTimesheetsActionTypes';
import apiService from '../../../shared/service/apiService';
import history from '../../../shared/service/history';
import constants from '../../../shared/constants';
import { getValue } from '../../../shared/service/localStorage';
import { setToast } from '../../home/homeActions';

const fetchEntityDetails = entityId => {
  return dispatch => {
    const fetchEntityDetailsUrl = apiService.endpoints.app.generateFetchTimesheetEntityDetailsUrl();
    dispatch({
      type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
      isFetchingEntityDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        entityId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_TIME_SHEET_ENTITY_CHANGE_TYPE_API,
      },
    };

    return axios
      .post(fetchEntityDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addTimesheetsActionTypes.SET_ENTITY_DETAILS,
          entityDetails: response.data,
        });
        dispatch({
          type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const formatTasks = tasks => {
  const taskArray = [];
  Object.keys(tasks).forEach(key => {
    taskArray.push({
      taskId: parseInt(key.replace("'", '')),
      taskHours: tasks[key],
    });
  });
  return JSON.stringify(taskArray);
};

const generateTasks = (tasks, fromDate, projectResourceId) => {
  const tasksJson = [];
  tasks.forEach((item, i) => {
    tasksJson.push({
      date: moment(fromDate)
        .add(i, 'days')
        .format('MM/DD/YYYY'),
      timesheetByDateId: '0',
      projectResourceId,
      resourceHours: '0.0',
      taskHours: formatTasks(item.task),
      comments: item.comments,
    });
  });
  return JSON.stringify(tasksJson);
};

const fetchResourceGrid = values => {
  return dispatch => {
    const fetchTimeSheetResourcesGridUrl = apiService.endpoints.app.generatefetchTimeSheetResourcesGridUrl();
    dispatch({
      type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
      isFetchingEntityDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        entityId: values.entityId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        loggedInUserId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        projectCodeId: values.projectCodeId,
        apiType: constants.API_TYPES.FETCH_TIME_SHEET_USER_TASKS_API,
        startDate: moment(new Date(values.fromDate)).format('MM/DD/YYYY'),
        endDate: moment(new Date(values.toDate)).format('MM/DD/YYYY'),
      },
    };
    return axios
      .post(fetchTimeSheetResourcesGridUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addTimesheetsActionTypes.SET_TASKS,
          tasksList: { ...response.data, values },
        });
        dispatch({
          type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
        history.push({
          pathname: '/home/timesheets/add/newTimesheets',
          state: { values },
        });
      })
      .catch(err => {
        dispatch({
          type: addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const setNewTimesheetsFormValues = values => {
  return dispatch => {
    dispatch({
      type: addTimesheetsActionTypes.SET_NEW_TIMESHEETS_FORM_VALUES,
      newTimesheetsFormValues: values,
    });
  };
};

const resetNewTimesheetsFormValues = () => {
  return dispatch => {
    dispatch({
      type: addTimesheetsActionTypes.RESET_NEW_TIMESHEETS_FORM_VALUES,
    });
  };
};

const setTimesheetsUpdateResponse = timesheets => {
  return {
    type: addTimesheetsActionTypes.SET_TIMESHEETS_UPDATE_RESPONSE,
    timesheets,
  };
};

const updateTimesheetsPending = () => {
  return {
    type: addTimesheetsActionTypes.UPDATE_TIMESHEETS.pending,
  };
};

const updateTimesheetsFulfilled = () => {
  return {
    type: addTimesheetsActionTypes.UPDATE_TIMESHEETS.fulfilled,
  };
};

const updateTimesheetsFailed = () => {
  return {
    type: addTimesheetsActionTypes.UPDATE_TIMESHEETS.failed,
  };
};

const setErrorMessage = message => {
  return {
    type: addTimesheetsActionTypes.SET_ERROR_MESSAGE,
    message,
  };
};

const submitTimesheets = (values, taskValues, his) => {
  return dispatch => {
    const updateTimesheetsUrl = apiService.endpoints.app.generateUpdateTimesheetsUrl();
    dispatch(updateTimesheetsPending());
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        loggedInUserId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SAVE_OR_UPDATE_TYPE_API,
        submitType: values.submitType,
        workflowId: taskValues.values.workflowId,
        entityId: taskValues.values.entityId,
        projectCodeId: taskValues.values.projectCodeId,
        startDate: moment(taskValues.values.fromDate).format('MM/DD/YYYY'),
        endDate: moment(taskValues.values.toDate).format('MM/DD/YYYY'),
        itemJson: generateTasks(
          values.tasks,
          taskValues.values.fromDate,
          taskValues.projectResourceId,
        ),
      },
    };

    return axios
      .post(updateTimesheetsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch(setTimesheetsUpdateResponse(response.data));
        dispatch(updateTimesheetsFulfilled());
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: 'Timesheets Updated successfully',
            isOpen: true,
          }),
        );
        his.go(-2);
      })
      .catch(err => {
        dispatch(updateTimesheetsFailed());
        if (err.response && err.response.status === 401) {
          his.push('/login');
          dispatch(setErrorMessage(constants.SESSION_EXPIRED));
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(setErrorMessage(message));
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export {
  fetchEntityDetails,
  submitTimesheets,
  setNewTimesheetsFormValues,
  resetNewTimesheetsFormValues,
  fetchResourceGrid,
};
