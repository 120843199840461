import { connect } from 'react-redux';
import { selectToast } from './homeSelector';
import { handleToastClose } from './homeActions';
import HomeComponent from './HomeComponent';
import { fetchHRMSLoginURL } from '../hrms/actions';

const mapStateToProps = state => {
  return {
    toast: selectToast(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleToastClose() {
      dispatch(handleToastClose());
    },
    fetchHRMSLoginURL: () => {
      dispatch(fetchHRMSLoginURL());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
