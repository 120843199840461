export default {
  accessor: {
    lineItemId: 'lineItemId',
    itemDesc: 'itemDesc',
    categoryDesc: 'categoryDesc',
    subCategoryDesc: 'subCategoryDesc',
    hsnCode: 'hsnCode',
    uom: 'uom',
    price: 'price',
    sgst: 'sgst',
    cgst: 'cgst',
    tax: 'tax',
    netPrice: 'netPrice',
    totalAmount: 'totalAmount',
    quantity: 'quantity',
    itemDescription: 'itemDescription',
    comments: 'comments',
    sNo: 'sNo',
    itemNo: 'itemNo',
    supplierPartNo: 'supplierPartNo',
    qtyOrdered: 'qtyOrdered',
    qtyReceived: 'qtyReceived',
    discount: 'discount',
    taxAmount: 'taxAmount',
    poNo: 'poNo',
    supplierName: 'supplierName',
    lineItem: 'lineItem',
    qty: 'qty',
    header: 'header',
    availableQty: 'availableQty',
    requestedQty: 'requestedQty',
    onHandQty: 'onHandQty',
    desc: 'desc',
    taxPercent: 'taxPercent',
    mrpQty: 'mrpQty',
    pickedupQty: 'PickedupQty',
    bundleInfo: 'bundleInfo',
  },
  ids: {
    entityName: 'entityName',
    viewName: 'viewName',
    projectCodes: 'projectCode',
    siteCodes: 'siteCodes',
    poFromLabel: 'poFromLabel',
    requisitionNo: 'requisitionNo',
    workflowName: 'workflowName',
    workflowId: 'workflowId',
    supplierName: 'supplierName',
    currencyCode: 'currencyCode',
    billingAddressId: 'billingAddressId',
    shippingAddressId: 'shippingAddressId',
    poTotalNetPrice: 'poTotalNetPrice',
    poTotalTax: 'poTotalTax',
    totalAmount: 'totalAmount',
    advancePayment: 'advancePayment',
    terms: 'terms',
    paymentTerms: 'paymentTerms',
    comments: 'comments',
    contactNo: 'contactNo',
    tinNo: 'tinNo',
    vatNo: 'vatNo',
    deliveryAddress: 'deliveryAddress',
    addressInput: 'addressInput',
    pickUpItemRequestNo: 'pickUpItemRequestNo',
    wareHouse: 'wareHouse',
    productDetailId: 'productDetailId',
    poType: 'poType',
    invoiceNo: 'invoiceNo',
    invoiceDate: 'invoiceDate',
    poNo: 'poNo',
    subTotalAmt: 'subTotalAmt',
    additionalAmt: 'additionalAmt',
    adjustedAmt: 'adjustedAmt',
    discount: 'discount',
    grandTotal: 'grandTotal',
    assetRequestNo: 'assetRequestNo',
    needDate: 'needDate',
    returnDate: 'returnDate',
    lineItem: 'lineItem',
    category: 'category',
    description: 'description',
    amount: 'amount',
    billable: 'billable',
    merchant: 'merchant',
    poDiscount: 'poDiscount',
    poTradeDiscount: 'poTradeDiscount',
    poPaymentType: 'poPaymentType',
    vehicleNo: 'vehicleNo',
    ewayBillNo: 'ewayBillNo',
    additionalMail: 'additionalMail',
    poSubTotal: 'poSubTotal',
    startDate: 'startDate',
    endDate: 'endDate',
  },
  labels: {
    mrpQty: 'MRP Qty',
    rupees: '₹',
    Entity: 'Entity',
    View: 'View',
    Type: 'Type',
    ProjectCode: 'Project Code',
    SiteCode: 'Site Codes',
    PORequestNo: 'PO Request #',
    Workflow: 'Workflow',
    Supplier: 'Supplier',
    Currency: 'Currency',
    billingAddress: 'Billing Address',
    shipToAddress: 'Ship To Address',
    totalNetPrice: 'Total NetPrice',
    totalTax: 'Total Tax',
    grandTotal: 'Grand Total',
    advancePayment: 'Advance Payment %',
    terms: 'Terms',
    paymentTerms: 'Payment Terms',
    paymentTermsDays: 'Payment Terms(Days)',
    comments: 'Comments',
    lineItemId: 'Line Item #',
    category: 'Category',
    subCategory: 'Sub Category',
    uom: 'UOM',
    price: 'Price',
    sgst: 'SGST %',
    cgst: 'CGST %',
    igst: 'IGST %',
    tax: 'Tax',
    netPrice: 'Net Price',
    totalAmount: 'Total',
    quantity: 'Qty',
    itemDescription: 'Item',
    contactNo: 'Contact No',
    tinNo: 'GSTIN',
    vatNo: 'VAT #',
    deliveryAddress: 'Delivery Address',
    addressInput: 'Enter Shipping Address',
    pickUpItemRequestNo: 'Pickup Request #',
    warehouse: 'Warehouse',
    productDetailId: 'MRP #',
    poType: 'Po Type',
    invoiceNo: 'Invoice #',
    invoiceDate: 'Invoice Date',
    poNo: 'PO #',
    creditNotes: 'Credit Notes',
    subTotalAmt: 'Sub Total Amount',
    sNo: 'S.NO',
    itemNo: 'Item #',
    supplierPartNo: 'Supplier Part #',
    qtyOrdered: 'Qty Ordered',
    qtyReceived: 'Qty Received',
    discount: 'Discount',
    taxAmount: 'Tax',
    additionalAmt: 'Additional Amount',
    adjustedAmt: 'Adjusted Amount',
    assetRequestNo: 'Asset Request #',
    needDate: 'Need Date',
    returnDate: 'ReturnDate',
    lineItem: 'Line Item',
    description: 'Description',
    amount: 'Amount',
    billable: 'Billable',
    merchant: 'Merchant',
    Discount: 'Discount',
    TradeDiscount: 'Trade Discount',
    poPaymentType: 'Payment Type',
    hsnCode: 'HSN/SAC',
    reqQty: 'Requested Qty',
    onHandQty: 'On Hand Qty',
    unitPrice: 'Unit Price',
    approvalComments: 'Approval Comments',
    availableQty: 'Available Qty',
    vehicleNo: 'Vehicle #',
    ewayBillNo: 'e-Way Bill #',
    additionalMail: 'CC Mail',
    poSubTotal: 'Sub Total',
    startDate: 'Start Date',
    endDate: 'End Date',
    taxPercent: 'Tax %',
    pickedupQty: 'Pickedup Qty',
    bundleInfo: 'Bundle Info',
  },
  helperText: {
    billingAddress: 'Choose a billing address',
    shipToAddress: 'Choose a shipping address',
    tinNo: '(format: 00AAAAA0000A1Z0)',
  },
};
