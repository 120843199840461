import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Spinner } from '@blueprintjs/core';
import * as moment from 'moment';
import Table from '../common/table/Table';
import { filterString, getDateFromObject } from '../../shared/utils/string';

const ExpenseDesktop = props => {
  const { rows, promise } = props;

  const columns = [
    {
      Header: 'Entity',
      accessor: 'ENTITY_NAME',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      id: 'VIEW_NAME',
      Header: 'View',
      accessor: d => d.VIEW_NAME || 'ALL',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Request #',
      accessor: 'REQUEST_NO',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Item',
      accessor: 'ITEMS_DESCRIPTION',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Advance/Claim',
      accessor: 'ADVANCE_OR_CLAIM',
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'advance') {
          return row[filter.id] === 'Advance';
        }
        return row[filter.id] === 'Claim';
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          <option value="advance">Advance</option>
          <option value="claim">Claim</option>
        </select>
      ),
    },
    {
      id: 'TOTAL_AMT',
      Header: 'Total Amount',
      accessor: d => `${d.CURRENCY_SYMBOL} ${d.TOTAL_AMT}`,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Requested By',
      accessor: 'AC_DELIM_CREATED_BY_FUNC',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      id: 'CREATED_DATE',
      Header: 'Requested On',
      accessor: d =>
        moment(getDateFromObject(d.CREATED_DATE)).format('DD/MM/YYYY'),
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Approved By',
      accessor: 'AC_DELIM_UPDATED_BY_FUNC',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      id: 'UPDATED_DATE',
      Header: 'Approved On',
      accessor: d =>
        moment(getDateFromObject(d.UPDATED_DATE)).format('DD/MM/YYYY'),
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Workflow Status',
      accessor: 'TEMP_STATUS_DESC',
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case 'approved':
            return row[filter.id] === 'Approved';
          case 'saved':
            return row[filter.id] === 'Saved';
          case 'cancelled':
            return row[filter.id] === 'Cancelled';
          case 'rejected':
            return row[filter.id] === 'Process Rejected';
          case 'all':
          default:
            return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          <option value="approved">Approved</option>
          <option value="saved">Saved</option>
          <option value="rejected">Process Rejected</option>
          <option value="cancelled">Cancelled</option>
        </select>
      ),
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 130,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        if (ci.original.property === 'Request #') {
          return <span className="MyExpenses__mobile--name">{ci.value}</span>;
        }
        return ci.value;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = task => {
    return [
      {
        property: 'Request #',
        value: task.REQUEST_NO,
      },
      {
        property: 'Advance/Claim',
        value: task.ADVANCE_OR_CLAIM,
      },
      {
        property: 'Total Amount',
        value: `${task.CURRENCY_SYMBOL} ${task.TOTAL_AMT}`,
      },
      {
        property: 'Requested By',
        value: task.AC_DELIM_CREATED_BY_FUNC,
      },
      {
        property: 'Workflow Status',
        value: task.TEMP_STATUS_DESC,
      },
    ];
  };
  return (
    <div>
      {promise.isPending && (
        <Spinner intent={Intent.PRIMARY} className="progress" />
      )}
      {promise.isFulfilled &&
        (rows.length ? (
          rows.map((task, i) => (
            <div key={i} className="MyExpenses__mobile">
              <Table
                data={getMobileData(task)}
                columns={mobileColumns}
                pageSize={getMobileData(task).length}
                onClick={() => {}}
                disableHeader
              />
            </div>
          ))
        ) : (
          <div className="MyExpenses__mobile">
            <p
              style={{
                background: '#f9f7f7',
                height: '300px',
                textAlign: 'center',
                fontSize: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              No Records...
            </p>
          </div>
        ))}

      {promise.isFulfilled && (
        <div className="MyExpenses__desktop">
          <Table data={rows} columns={columns} filterable onClick={() => {}} />
        </div>
      )}
    </div>
  );
};

ExpenseDesktop.propTypes = {
  rows: PropTypes.array.isRequired,
  promise: PropTypes.object.isRequired,
};

export default ExpenseDesktop;
