const salesOrderActionTypes = {
  SET_SALES_ORDER_GRID: 'SET_SALES_ORDER_GRID',
  SALES_ORDER: {
    pending: 'salesOrder/pending',
    fulfilled: 'salesOrder/fulfilled',
    rejected: 'salesOrder/rejected',
  },
};

export default salesOrderActionTypes;
