import axios from 'axios';
import apiService from '../../shared/service/apiService';

import { getValue } from '../../shared/service/localStorage';
import constants from '../../shared/constants';
import history from '../../shared/service/history';
import { setToast } from '../home/homeActions';

const fetchHRMSLoginURL = () => {
  return dispatch => {
    const fetchHRMSUrl = apiService.endpoints.app.generateHRMSUrl();

    const payload = {
      token: getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.HRMS,
      },
    };

    return axios
      .post(fetchHRMSUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (response.data.hrmsApiUrl) {
          window.open(
            response.data.hrmsApiUrl,
            '_blank',
            'toolbar=0,location=0,menubar=0',
          );
        } else {
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message: constants.HRMS_UNAVAILABLE,
              isOpen: true,
            }),
          );
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export { fetchHRMSLoginURL };
