import axios from 'axios';
import addSalesOrderActionTypes from './addSalesOrderActionTypes';
import apiService from '../../../shared/service/apiService';
import history from '../../../shared/service/history';
import constants from '../../../shared/constants';
import moment from 'moment';
import {
  selectSalesOrderItemData,
  selectSalesOrderUpdateResponse,
  selectSalesOrderApprovalResponse,
  selectSearchItemList,
} from './addSalesOrderSelector';
import { getValue } from '../../../shared/service/localStorage';
import { setToast } from '../../home/homeActions';

const salesOrderPending = () => {
  return {
    type: addSalesOrderActionTypes.TASK_SALESORDER.pending,
  };
};

const salesOrderFulfilled = () => {
  return {
    type: addSalesOrderActionTypes.TASK_SALESORDER.fulfilled,
  };
};

const salesOrderRejected = () => {
  return {
    type: addSalesOrderActionTypes.TASK_SALESORDER.rejected,
  };
};

const getSalesOrderDetails = task => {
  return dispatch => {
    dispatch(salesOrderPending());
    const fetchSalesOrderDetailsUrl = apiService.endpoints.app.generateSalesOrderDetailsUrl();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_SALESORDER_DETAILS_PRINT,
        id: task.PURCHASE_ID_PK,
      },
    };

    return axios
      .post(fetchSalesOrderDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_ENTITY_DETAILS,
          entityDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.SET_SALESORDER_FORM_VALUES,
          salesOrderFormValues: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.SET_ITEM_DATA,
          itemData: response.data && response.data.itemsList,
        });
        dispatch(salesOrderFulfilled());
      })
      .catch(err => {
        dispatch(salesOrderRejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchAddSalesOrderDetails = () => {
  return dispatch => {
    dispatch({ type: addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.pending });

    const fetchAddSalesOrderDetailsUrl = apiService.endpoints.app.generateAddSalesOrderDetailsUrl();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.FETCH_SALESORDER_DETAILS,
        entityList: [],
      },
    };

    return axios
      .post(fetchAddSalesOrderDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_ADD_SALESORDER_DETAILS,
          addSalesOrderDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.SET_DYNAMIC_COLUMNS,
          dynamicColumns: response.data && response.data.dynamicColumns,
        });
        dispatch({
          type: addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.fulfilled,
        });
      })
      .catch(err => {
        dispatch({
          type: addSalesOrderActionTypes.ADD_SALESORDER_DETAILS.rejected,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchEntityDetails = entityId => {
  return dispatch => {
    const fetchEntityDetailsUrl = apiService.endpoints.app.generateSalesOrderEntityDetailsUrl();
    dispatch({
      type: addSalesOrderActionTypes.IS_FETCHING_ENTITY_DETAILS,
      isFetchingEntityDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        entityId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SALES_ORDER_ENTITY_DETAILS_API,
      },
    };

    return axios
      .post(fetchEntityDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_ENTITY_DETAILS,
          entityDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_ENTITY_DETAILS,
          isFetchingEntityDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchCustomerDetails = (customerId, entityId) => {
  return dispatch => {
    const fetchCustomerDetailsUrl = apiService.endpoints.app.generateSalesOrderCustomerDetailsUrl();
    dispatch({
      type: addSalesOrderActionTypes.IS_FETCHING_CUSTOMER_DETAILS,
      isFetchingCustomerDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        customerId,
        entityId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SALES_ORDER_CUSTOMER_DETAILS_API,
      },
    };

    return axios
      .post(fetchCustomerDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_CUSTOMER_DETAILS,
          customerDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_CUSTOMER_DETAILS,
          isFetchingCustomerDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_CUSTOMER_DETAILS,
          isFetchingCustomerDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchViewDetails = (entityId, projectCodeId, customerId) => {
  return dispatch => {
    const fetchViewDetailsUrl = apiService.endpoints.app.generateSalesOrderViewDetailsUrl();
    dispatch({
      type: addSalesOrderActionTypes.IS_FETCHING_VIEW_DETAILS,
      isFetchingViewDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        projectCodeId: projectCodeId,
        customerId,
        entityId,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SALES_ORDER_VIEW_DETAILS_API,
      },
    };

    return axios
      .post(fetchViewDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_VIEW_DETAILS,
          viewDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_VIEW_DETAILS,
          isFetchingViewDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_VIEW_DETAILS,
          isFetchingViewDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const fetchItemSeachDetails = values => {
  return async dispatch => {
    const fetchSearchItemDetailsUrl = apiService.endpoints.app.generateSalesOrderSearchItemDetailsUrl();
    dispatch({
      type: addSalesOrderActionTypes.IS_FETCHING_SEARCH_ITEM_DETAILS,
      isFetchingSearchItemDetails: true,
    });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        projectCodeId: values.viewId,
        entityId: values.entityId,
        search: values.searchKey || '',
        current: 0,
        sortBy: '',
        sortDirection: '',
        rowCount: 100,
        currency: values.currency,
        quotationIdsString: JSON.stringify(
          values.quotationId.map(e => e.value),
        ),
        taxColumns: values.taxColumns,
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SALES_ORDER_SEARCH_ITEM_DETAILS_API,
      },
    };
    return axios
      .post(fetchSearchItemDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({
          type: addSalesOrderActionTypes.SET_SEARCH_ITEM_DETAILS,
          searchItemDetails: response.data,
        });
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_SEARCH_ITEM_DETAILS,
          isFetchingSearchItemDetails: false,
        });
      })
      .catch(err => {
        dispatch({
          type: addSalesOrderActionTypes.IS_FETCHING_SEARCH_ITEM_DETAILS,
          isFetchingSearchItemDetails: false,
        });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const setSalesOrderSearchItemData = (data, searchListIndex) => {
  return (dispatch, getState) => {
    const itemData = selectSalesOrderItemData(getState());
    const itemIndex = itemData.findIndex(e => e.primaryKey === data.primaryKey);
    let newData = [...itemData];
    if (itemIndex === -1 && data.selected) newData.push(data);
    else if (itemIndex > -1 && !data.selected) newData.splice(itemIndex, 1);
    dispatch({
      type: addSalesOrderActionTypes.SET_ITEM_DATA,
      itemData: newData,
    });
    const searchItemList = selectSearchItemList(getState());
    const modifiedList = [...searchItemList];
    modifiedList[searchListIndex] = data;
    dispatch({
      type: addSalesOrderActionTypes.SET_SEARCH_ITEM_DETAILS,
      searchItemDetails: modifiedList,
    });
  };
};

const deleteInSalesOrderSearchItemData = item => {
  return (dispatch, getState) => {
    const itemData = selectSalesOrderItemData(getState());
    const newData = [...itemData];
    const index = newData.find(e => e.primaryKey === item.primaryKey);
    newData.splice(index, 1);
    dispatch({
      type: addSalesOrderActionTypes.SET_ITEM_DATA,
      itemData: newData,
    });
  };
};

const setSalesOrderItemData = (data, indexExists, index, history) => {
  return (dispatch, getState) => {
    const itemData = selectSalesOrderItemData(getState());
    let newData = [...itemData];
    if (indexExists) {
      newData[index] = data;
    } else {
      newData = [...itemData, data];
    }
    dispatch({
      type: addSalesOrderActionTypes.SET_ITEM_DATA,
      itemData: newData,
    });
    history.goBack();
  };
};

const deleteInSalesOrderItemData = (index, history) => {
  return (dispatch, getState) => {
    const itemData = selectSalesOrderItemData(getState());
    const newData = [...itemData];
    newData.splice(index, 1);
    dispatch({
      type: addSalesOrderActionTypes.SET_ITEM_DATA,
      itemData: newData,
    });
    history.goBack();
  };
};

const resetSalesOrderItemData = () => {
  return dispatch => {
    dispatch({ type: addSalesOrderActionTypes.RESET_ITEM_DATA });
  };
};

const setNewItemFormValues = newItemFormValues => {
  return dispatch => {
    dispatch({
      type: addSalesOrderActionTypes.SET_NEW_ITEM_FORM_VALUES,
      newItemFormValues,
    });
  };
};

const setNewSalesOrderFormValues = values => {
  return dispatch => {
    dispatch({
      type: addSalesOrderActionTypes.SET_NEW_SALESORDER_FORM_VALUES,
      newSalesOrderFormValues: values,
    });
  };
};

const resetNewSalesOrderFormValues = () => {
  return dispatch => {
    dispatch({
      type: addSalesOrderActionTypes.RESET_NEW_SALESORDER_FORM_VALUES,
    });
  };
};

const setSalesOrderUpdateResponse = salesOrder => {
  return {
    type: addSalesOrderActionTypes.SET_SALESORDER_UPDATE_RESPONSE,
    salesOrder,
  };
};

const updateSalesOrderPending = () => {
  return {
    type: addSalesOrderActionTypes.UPDATE_SALESORDER.pending,
  };
};

const updateSalesOrderFulfilled = () => {
  return {
    type: addSalesOrderActionTypes.UPDATE_SALESORDER.fulfilled,
  };
};

const updateSalesOrderFailed = () => {
  return {
    type: addSalesOrderActionTypes.UPDATE_SALESORDER.failed,
  };
};

const setErrorMessage = message => {
  return {
    type: addSalesOrderActionTypes.SET_ERROR_MESSAGE,
    message,
  };
};

const setSiteCodeList = siteCodes => {
  return {
    type: addSalesOrderActionTypes.SET_SITE_CODE_LIST,
    siteCodes: siteCodes || [],
  };
};

const setSelectedTask = selectedTask => {
  return {
    type: addSalesOrderActionTypes.SET_SELECTED_TASK,
    selectedTask: selectedTask || {},
  };
};

const submitSalesOrder = (values, history) => {
  const itemJson = values.items.map((item, index) => {
    return {
      ...item,
      lineItemId: index,
      taxName: (item.taxName && item.taxName.toString()) || '',
      quoteItemId: (item.quoteItemId && item.quoteItemId.toString()) || 0,
      selected: undefined,
      primaryKey: undefined,
      hsnCode: item.hsncode || '',
      additionalNotes: item.additionalNotes || '',
      uom: item.uom || '',
    };
  });
  const siteCodeIds = values.siteCodeId.map(element => element.value);
  const quotationIds = values.quotationId.map(element => element.value);

  return (dispatch, getState) => {
    const updateSalesOrderUrl = apiService.endpoints.app.generateUpdateSalesOrderUrl();

    dispatch(updateSalesOrderPending());
    const payload = {
      companyId:
        getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
      userId:
        getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
      apiType: constants.API_TYPES.UPDATE_SALESORDER_API,
      submitType: values.submitType,
      workflowId: values.workflowId,
      projectCodeId: values.viewId,
      entityId: values.entityId,
      poType: values.type,
      poNo: values.poNo,
      poDate: moment(values.poDate).format('MM/DD/YYYY'),
      customerId: values.customer,
      currency: values.currency,
      quotationIds,
      multiSeqId: values.orderSeq,
      customerContact: values.customerPhone,
      createdOn:
        (values.startDate && moment(values.startDate).format('MM/DD/YYYY')) ||
        '',
      endDate:
        (values.endDate && moment(values.endDate).format('MM/DD/YYYY')) || '',
      advancePayment: values.advanceAmount,
      siteCodeIds,
      itemJson,
      comments: values.comments,
      advance: !!values.advanceAmount ? 'true' : 'false',
      totalNetPrice: values.totalNetPrice,
      totalTax: values.totalTax,
      grandTotal: values.grandTotal,
      dynamicColumns: JSON.stringify(values.dynamicFields),
    };

    const formData = new FormData();
    values.attachment &&
      values.attachment.forEach(file => {
        formData.append('files', file, file.name);
      });
    formData.append('payload', JSON.stringify(payload));
    formData.append(
      'token',
      getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
    );
    formData.append(
      'loadBalancer',
      getValue(constants.LOCAL_STORAGE.LOADBALANCER) || constants.EMPTY_STRING,
    );

    return axios
      .post(updateSalesOrderUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        dispatch(setSalesOrderUpdateResponse(response.data));
        history.goBack();
        dispatch(updateSalesOrderFulfilled());
        const salesOrderUpdateResponse = selectSalesOrderUpdateResponse(
          getState(),
        );
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: salesOrderUpdateResponse.actionMsg
              ? salesOrderUpdateResponse.actionMsg
              : 'SalesOrder Updated successfully',
            isOpen: true,
          }),
        );
      })
      .catch(err => {
        dispatch(updateSalesOrderFailed());
        if (err.response && err.response.status === 401) {
          history.push('/login');
          dispatch(setErrorMessage(constants.SESSION_EXPIRED));
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(setErrorMessage(message));
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

const setSalesOrderApprovalResponse = salesOrder => {
  return {
    type: addSalesOrderActionTypes.SET_SALESORDER_APPROVAL_RESPONSE,
    salesOrder,
  };
};

const approveRejectSalesOrder = (values, salesOrderValues, items) => {
  return (dispatch, getState) => {
    dispatch(salesOrderPending());
    const fetchApproveRejectURL = apiService.endpoints.app.generateFetchApproveRejectURL();
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.APPROVE_REJECT_SALESORDER,
        submitType: values.submitType,
        workflowId: salesOrderValues.payload.workflowId,
        workflowAuditId: salesOrderValues.payload.workflowAuditId,
        taskId: salesOrderValues.payload.taskId,
        seqFlow: salesOrderValues.payload.seqFlow,
        auditTrackId: salesOrderValues.payload.auditTrackId,
        processInstanceId: salesOrderValues.payload.processInstanceId,
        claimId: salesOrderValues.payload.claimId,
        advanceRequestId: salesOrderValues.payload.advanceRequestId,
        advanceClaimDetailsId: salesOrderValues.payload.advanceClaimDetailsId,
        dynamicColumns: salesOrderValues.payload.dynamicColumns,
        viewId: salesOrderValues.payload.viewId,
        entityId: salesOrderValues.payload.entityId,
        advanceOrClaim:
          salesOrderValues.payload.advanceOrClaim === 'Advance' ? 0 : 1,
        needByDate: salesOrderValues.payload.needByDate,
        totalAmount: values.totalAmount || salesOrderValues.totalAmount,
        categoryId: salesOrderValues.payload.categoryId,
        fileBean: salesOrderValues.payload.fileBean,
        projectCodeId: salesOrderValues.payload.projectCodeId,
        siteCodeIds:
          (values.siteCodeId &&
            values.siteCodeId.map(element => element.value)) ||
          salesOrderValues.siteCodeId,
        documentBean: salesOrderValues.payload.documentBean,
        status: salesOrderValues.payload.status,
        itemJson: JSON.stringify(
          items.map((item, i) => {
            return {
              ...item,
              file: '',
              categoryName: item.category,
              comments: item.description,
              attachmentRef: i,
              uploadedFileName: '',
            };
          }),
        ),
        comments: values.comments,
      },
    };

    return axios
      .post(fetchApproveRejectURL, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch(setSalesOrderApprovalResponse(response.data));
        history.goBack();
        dispatch(salesOrderFulfilled());
        const salesOrderApprovalResponse = selectSalesOrderApprovalResponse(
          getState(),
        );
        dispatch(
          setToast({
            variant: constants.TOAST.VARIANTS.SUCCESS,
            message: salesOrderApprovalResponse.actionMsg
              ? salesOrderApprovalResponse.actionMsg
              : 'SalesOrder Updated successfully',
            isOpen: true,
          }),
        );
      })
      .catch(err => {
        dispatch(salesOrderRejected());
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export {
  fetchEntityDetails,
  fetchAddSalesOrderDetails,
  approveRejectSalesOrder,
  setSalesOrderItemData,
  resetSalesOrderItemData,
  submitSalesOrder,
  setNewSalesOrderFormValues,
  resetNewSalesOrderFormValues,
  deleteInSalesOrderItemData,
  setNewItemFormValues,
  setSiteCodeList,
  setSelectedTask,
  getSalesOrderDetails,
  fetchCustomerDetails,
  fetchViewDetails,
  fetchItemSeachDetails,
  setSalesOrderSearchItemData,
  deleteInSalesOrderSearchItemData,
};
