import React from 'react';
import Select from 'react-select';
import Immutable from 'seamless-immutable';
import PropTypes from 'prop-types';
import { FormGroup, Intent } from '@blueprintjs/core';
import './form.scss';

const colourStyles = {
  singleValue: base => ({
    ...base,
    color: '#182026',
    overflow: 'auto',
    textOverflow: 'unset',
  }),
  input: base => ({
    ...base,
    color: '#182026',
  }),
};

const FormSelect = props => {
  const {
    disabled,
    helperText,
    inline,
    intent,
    label,
    labelInfo,
    onChange,
    onBlur,
    id,
    options,
    value,
    className,
    searchable,
    loading,
    defaultValue,
  } = props;

  const data = Immutable.isImmutable(options)
    ? options.asMutable({ deep: true })
    : options;
  return (
    <FormGroup
      disabled={disabled}
      helperText={helperText}
      inline={inline}
      intent={intent}
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
    >
      <Select
        isSearchable={searchable}
        id={id}
        isDisabled={disabled}
        isLoading={loading}
        className={`${
          disabled ? 'bp3-disabled' : ''
        } bp3-fill bp3-large ${className}`}
        styles={colourStyles}
        options={data}
        onChange={onChange}
        onBlur={onBlur}
        value={options.find(option => option.value === value) || defaultValue}
        defaultValue={defaultValue}
      />
    </FormGroup>
  );
};

FormSelect.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inline: PropTypes.bool,
  intent: PropTypes.string,
  label: PropTypes.string,
  labelInfo: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  searchable: PropTypes.bool,
  loading: PropTypes.bool,
};

FormSelect.defaultProps = {
  disabled: false,
  helperText: '',
  inline: false,
  intent: Intent.NONE,
  label: '',
  labelInfo: '',
  className: '',
  value: '',
  defaultValue: '',
  options: [],
  placeholder: 'Choose an item...',
  searchable: false,
  loading: false,
  onBlur: () => {},
};

export default FormSelect;
