import seamlessImmutable from 'seamless-immutable';
import moment from 'moment';
import setPromiseState from '../../../shared/service/promiseState';

const addSalesOrderInitialState = seamlessImmutable({
  promise: {
    addSalesOrderDetails: setPromiseState(),
    updateSalesOrder: setPromiseState(),
    taskSalesOrder: setPromiseState(),
  },
  addSalesOrderDetails: {},
  isFetchingEntityDetails: false,
  viewList: [],
  workflowList: [],
  siteCodeList: [],
  orderSeqList: [],
  customerList: [],
  quotationList: [],
  valueObj: {},
  isFetchingItemData: false,
  itemData: [],
  dynamicColumns: [],
  newSalesOrderFormValues: {
    type: '',
    entityId: '',
    customer: '',
    customerPhone: '',
    viewId: '',
    workflowId: '',
    quotationId: [],
    currency: '',
    projectCodeId: '',
    siteCodeId: [],
    orderSeq: '',
    poDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
    startDate: '',
    endDate: '',
    attachment: [],
    comments: '',
    poNo: '',
    advanceAmount: 0,
    submitType: '',
    taxColumns: [],
  },
  newItemFormValues: {
    itemNo: '',
    desc: '',
    qty: 1,
    sellPriceWithTax: 0,
    price: 0,
    uom: '',
    hsnCode: '',
    taxName: '',
    igst: 0,
    cgst: 0,
    sgst: 0,
    otherTax: 0,
    netPrice: 0,
    taxAmount: 0,
    totalAmount: 0,
    quoteItemId: 0,
    additionalNotes: '',
  },
  salesOrderApprovalResponse: {},
  salesOrderFormValues: {},
});

export default addSalesOrderInitialState;
