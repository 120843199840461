import { connect } from 'react-redux';
import AddExpenseComponent from './AddExpenseComponent';
import t from '../../../shared/service/translations';
import {
  fetchEntityDetails,
  fetchAdvanceNumber,
  fetchAddExpenseDetails,
  setItemData,
  resetItemData,
  submitExpense,
  approveRejectExpense,
  setNewExpenseFormValues,
  resetNewExpenseFormValues,
  setSiteCodeList,
  getTaskExpense,
} from './addExpenseActions';
import { getEntityList } from '../../login/authSelector';
import {
  addExpenseDetailsPromise,
  updateExpensePromise,
  getAddExpenseDetails,
  selectViewList,
  selectWorkflowList,
  isFetchingEntityDetails,
  selectItemData,
  selectNewExpenseFormValues,
  selectProjectCodeList,
  selectAdvanceList,
  getSiteCodesList,
  taskExpensePromise,
  selectExpenseFormValues,
  selectDynamicColumns,
} from './addExpenseSelector';

const mapStateToProps = state => {
  return {
    t,
    addExpenseDetailsPromise: addExpenseDetailsPromise(state),
    updateExpensePromise: updateExpensePromise(state),
    addExpenseDetails: getAddExpenseDetails(state),
    taskExpensePromise: taskExpensePromise(state),
    entityList: getEntityList(),
    viewList: selectViewList(state),
    workflowList: selectWorkflowList(state),
    projectCodeList: selectProjectCodeList(state),
    advanceList: selectAdvanceList(state),
    isFetchingEntityDetails: isFetchingEntityDetails(state),
    itemData: selectItemData(state),
    dynamicColumns: selectDynamicColumns(state),
    newExpenseFormValues: selectNewExpenseFormValues(state),
    expenseFormValues: selectExpenseFormValues(state),
    siteCodeList: getSiteCodesList(state),
    selectedTask: state.tasks.selectedTask,
  };
};

const mapDispatchToProps = {
  fetchEntityDetails,
  fetchAdvanceNumber,
  fetchAddExpenseDetails,
  setItemData,
  resetItemData,
  submitExpense,
  approveRejectExpense,
  getTaskExpense,
  setNewExpenseFormValues,
  resetNewExpenseFormValues,
  setSiteCodeList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddExpenseComponent);
