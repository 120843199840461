import React from 'react';
import Card from '../../../common/card/Card';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';

const AssetHeader = props => {
  const { asset } = props;
  return (
    <Card>
      <div className="AssetHeader Form">
        <div className="Form--fields">
          <FormInput
            id={constants.ids.entityName}
            name={constants.ids.entityName}
            label={constants.labels.Entity}
            value={asset.entityName}
            large
          />
          <FormInput
            id={constants.ids.viewName}
            name={constants.ids.viewName}
            label={constants.labels.View}
            value={asset.viewName}
            large
          />
          <FormInput
            id={constants.ids.assetRequestNo}
            name={constants.ids.assetRequestNo}
            label={constants.labels.assetRequestNo}
            value={asset.pickUpItemRequestNo}
            large
          />
          <FormInput
            id={constants.ids.workflowName}
            name={constants.ids.workflowName}
            label={constants.labels.Workflow}
            value={asset.workflowName}
            large
          />
          <FormInput
            id={constants.ids.wareHouse}
            name={constants.ids.wareHouse}
            label={constants.labels.warehouse}
            value={asset.wareHouseName}
            large
          />
          <FormInput
            id={constants.ids.needDate}
            name={constants.ids.needDate}
            label={constants.labels.needDate}
            value={asset.needDate}
            large
          />
          <FormInput
            id={constants.ids.returnDate}
            name={constants.ids.returnDate}
            label={constants.labels.returnDate}
            value={asset.returnDate}
            large
          />
        </div>
      </div>
    </Card>
  );
};

export default AssetHeader;
