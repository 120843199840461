import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import './form.scss';

const FormInput = props => {
  const {
    disabled,
    helperText,
    inline,
    intent,
    label,
    labelInfo,
    onChange,
    onBlur,
    placeholder,
    small,
    large,
    id,
    value,
    type,
    readOnly,
    leftIcon,
    rightElement,
    className,
    onClick,
    max,
    min,
    defaultValue,
  } = props;
  return (
    <FormGroup
      disabled={disabled}
      helperText={helperText}
      inline={inline}
      intent={intent}
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
      readOnly={readOnly}
    >
      <InputGroup
        style={
          type === 'date' || type === 'datetime-local'
            ? { borderRadius: '5px' }
            : { overflow: 'scroll', borderRadius: '5px' }
        }
        className={`${readOnly ? 'readonly' : ''} ${className}`}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        intent={intent}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        large={large}
        small={small}
        type={type}
        readOnly={readOnly}
        leftIcon={leftIcon}
        rightElement={rightElement}
        onClick={onClick}
        max={max}
        min={min}
        defaultValue={defaultValue}
      />
    </FormGroup>
  );
};

FormInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  intent: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  large: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string.isRequired,
  leftIcon: PropTypes.string,
  rightElement: PropTypes.element,
};

FormInput.defaultProps = {
  disabled: false,
  helperText: '',
  inline: false,
  intent: Intent.NONE,
  label: '',
  labelInfo: '',
  placeholder: '',
  small: false,
  large: false,
  readOnly: false,
  type: 'text',
};

export default FormInput;
