const timesheetsActionTypes = {
  SET_TIMESHEETS_GRID: 'SET_TIMESHEETS_GRID',
  TIMESHEETS: {
    pending: 'timesheets/pending',
    fulfilled: 'timesheets/fulfilled',
    rejected: 'timesheets/rejected',
  },
};

export default timesheetsActionTypes;
