const invoiceActionTypes = {
  INVOICE: {
    pending: 'INVOICE/pending',
    fulfilled: 'INVOICE/fulfilled',
    rejected: 'INVOICE/rejected',
  },
  UPDATE_INVOICE: {
    pending: 'UPDATE_INVOICE/pending',
    fulfilled: 'UPDATE_INVOICE/fulfilled',
    rejected: 'UPDATE_INVOICE/rejected',
  },
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_INVOICE: 'SET_INVOICE',
  SET_INVOICE_APPROVAL_RESPONSE: 'SET_INVOICE_APPROVAL_RESPONSE',
  UPDATE_INVOICE_HEADER_FIELD_VALUE: 'UPDATE_INVOICE_HEADER_FIELD_VALUE',
  UPDATE_INVOICE_LINE_FIELD_VALUE: 'UPDATE_INVOICE_LINE_FIELD_VALUE',
  UPDATE_INVOICE_FIELD_VALUE: 'UPDATE_INVOICE_FIELD_VALUE',
};

export default invoiceActionTypes;
