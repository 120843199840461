import timesheetsInitialState from './timesheetsInitialState';
import timesheetsActionTypes from './timesheetsActionTypes';
import setPromiseState from '../../shared/service/promiseState';

const timesheetsReducer = (state = timesheetsInitialState, action) => {
  switch (action.type) {
    case timesheetsActionTypes.SET_TIMESHEETS_GRID:
      return state.set('timesheetsGrid', action.timesheetsGrid);
    case timesheetsActionTypes.TIMESHEETS.fulfilled:
      return state.setIn(
        ['promise', 'timesheets'],
        setPromiseState(false, true, false),
      );
    case timesheetsActionTypes.TIMESHEETS.pending:
      return state.setIn(
        ['promise', 'timesheets'],
        setPromiseState(true, false, false),
      );
    case timesheetsActionTypes.TIMESHEETS.rejected:
      return state.setIn(
        ['promise', 'timesheets'],
        setPromiseState(false, false, true),
      );
    default:
      return state;
  }
};

export default timesheetsReducer;
