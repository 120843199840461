import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';
import FormTextArea from '../../../common/form/FormTextArea';
import { H5 } from '@blueprintjs/core';

const POFooter = props => {
  const { handleChange, po, values } = props;

  const displayAttachments = documents => {
    const values = [];
    documents.forEach((doc, index) => {
      values.push(
        <li key={index}>
          <a
            href={`${po.host}${doc.attachmentPath}`}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            {doc.uploadedFileName}
          </a>
        </li>,
      );
    });
    return values;
  };

  return (
    <div className="Form">
      <div className="PO__footer">
        {po.vehicleNo && (
          <FormInput
            id={constants.ids.vehicleNo}
            label={constants.labels.vehicleNo}
            value={po.vehicleNo}
            name={constants.ids.vehicleNo}
            onChange={handleChange}
            large
            disabled
          />
        )}
        {po.ewayBillNo && (
          <FormInput
            id={constants.ids.ewayBillNo}
            label={constants.labels.ewayBillNo}
            value={po.ewayBillNo}
            name={constants.ids.ewayBillNo}
            onChange={handleChange}
            large
            disabled
          />
        )}
        {po.advance && (
          <FormInput
            id={constants.ids.advancePayment}
            label={constants.labels.advancePayment}
            value={po.advancePayment}
            name={constants.ids.advancePayment}
            onChange={handleChange}
            large
            disabled
          />
        )}
        {po.email && po.additionalMail && (
          <FormInput
            id={constants.ids.additionalMail}
            label={constants.labels.additionalMail}
            value={po.additionalMail}
            name={constants.ids.additionalMail}
            onChange={handleChange}
            large
            disabled
          />
        )}
        {po.terms && (
          <FormTextArea
            id={constants.ids.terms}
            label={constants.labels.terms}
            value={po.terms}
            name={constants.ids.terms}
            large
            disabled
            onChange={handleChange}
          />
        )}
        {po.paymentTerms && (
          <FormTextArea
            id={constants.ids.paymentTerms}
            label={constants.labels.paymentTerms}
            value={po.paymentTerms}
            large
            disabled
            name={constants.ids.paymentTerms}
            onChange={handleChange}
          />
        )}
        {po.documentSet && (
          <div className="PO__Attachments">
            <H5>Attachments</H5>
            <ul>{displayAttachments(JSON.parse(po.documentSet))}</ul>
          </div>
        )}
        {po.comments && (
          <FormTextArea
            id={constants.ids.comments}
            label={constants.labels.comments}
            value={po.comments}
            large
            disabled
            name={constants.ids.comments}
            onChange={handleChange}
          />
        )}
        <FormTextArea
          id={constants.ids.comments}
          label={constants.labels.approvalComments}
          value={values.comments}
          large
          name={constants.ids.comments}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

POFooter.propType = {
  handleChange: PropTypes.func.isRequired,
  po: PropTypes.object.isRequired,
};

export default POFooter;
