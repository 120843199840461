import React from 'react';
import Card from '../../../common/card/Card';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';
import FormSelect from '../../../common/form/FormSelect';

const InvoiceHeader = props => {
  const { invoice, handleChange } = props;
  return (
    <Card>
      <div className="InvoiceHeader Form">
        <div className="Form--fields">
          <FormInput
            id={constants.ids.entityName}
            name={constants.ids.entityName}
            label={constants.labels.Entity}
            value={invoice.entityName}
            large
          />
          <FormInput
            id={constants.ids.poType}
            name={constants.ids.poType}
            label={constants.labels.poType}
            value={invoice.poType}
            large
          />
          <FormInput
            id={constants.ids.invoiceNo}
            name={constants.ids.invoiceNo}
            label={constants.labels.invoiceNo}
            value={invoice.invoiceNo}
            large
          />
          <FormInput
            id={constants.ids.invoiceDate}
            name={constants.ids.invoiceDate}
            label={constants.labels.invoiceDate}
            value={invoice.invoiceDate}
            large
          />
          <FormInput
            id={constants.ids.workflowName}
            name={constants.ids.workflowName}
            label={constants.labels.Workflow}
            value={invoice.workflowName}
            large
          />
          <FormInput
            id={constants.ids.supplierName}
            name={constants.ids.supplierName}
            label={constants.labels.Supplier}
            value={invoice.supplierName}
            large
          />
          <FormInput
            id={constants.ids.paymentTerms}
            name={constants.ids.paymentTerms}
            label={constants.labels.paymentTermsDays}
            value={invoice.paymentDays}
            large
          />
          <FormSelect
            id={constants.ids.creditNotes}
            name={constants.ids.creditNotes}
            label={constants.labels.creditNotes}
            options={invoice.creditNotes}
            onChange={handleChange}
            // helperText={constants.helperText.creditNotes}
            large
          />
        </div>
      </div>
    </Card>
  );
};

export default InvoiceHeader;
