export default {
  EMPTY_STRING: '',
  DEFAULT_NUMBER: 0,
  DEFAULT_BOOL: false,
  DEFAULT_SORT: 'asc',
  DEFAULT_TASK_ROWS: 100,
  SERVER_UNAVAILABLE:
    'Sorry, Server under maintenance. Try after few minutes or contact support.',
  HRMS_UNAVAILABLE: 'Cannot access HRMS page, please contact admin.',
  SESSION_EXPIRED: 'Session expired. Please login again',
  LOCAL_STORAGE: {
    TOKEN: 'token',
    LOADBALANCER: 'loadBalancer',
    USER_ID: 'UserId',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    ALL_VIEW_ACCESS: 'allViewAccess',
    ORG_NAME: 'orgName',
    ORG_ID: 'orgId',
    PROJ_CODE_VISIBLE: 'projCodeVisible',
    ORG_ALL_VIEW_VISIBLE: 'orgAllViewVisible',
  },
  TASKS_WORKFLOW_IDS: {
    PO_REQUISITION_PROCESSING_TYPE: 8,
    PICKUP_PROCESSING_TYPE: 9,
    ASSET_PROCESSING_TYPE: 12,
    INVOICE_PROCESSING_TYPE: 11,
    EXPENSE_PROCESSING_TYPE: 27,
  },
  tasks: {
    columns: {
      entityName: 'Entity',
      workflowTypeName: 'Task Type',
      supplierName: 'Supplier',
      stageName: 'Stage',
      contractNo: 'Contract',
      contractOwner: 'Contract Owner',
      requestedBy: 'Requested By',
      requestNo: 'Request #',
      poNo: 'PO #',
      invoiceNo: 'Invoice #',
      customerPONo: 'Sales Order PO #',
      customerInvoiceNo: 'Sales Invoice #',
      createdDate: 'Created Date',
      dueDateString: 'Due Date',
      status: 'Status',
      poType: 'Po Type',
      invoiceDate: 'Invoice Date',
      workFlowName: 'Workflow Name',
      cancel: 'Cancel',
      reject: 'Reject',
      completeApproval: 'Complete Approval',
    },
    actions: {
      APPROVE: 'approve',
      REJECT: 'reject',
    },
  },
  API_TYPES: {
    APPROVE_PO_REQ_TYPE_API: 4,
    APPROVE_REJECT_EXPENSE: 5,
    EDIT_PO_AMENDMENT_TYPE_API: 17,
    APPROVE_PICK_UP_TYPE_API: 9,
    APPROVE_INVOICE_TYPE_API: 13,
    APPROVE_ASSET_TYPE_API: 11,
    UPDATE_PO_AMENDMENT_TYPE_API: 18,
    UPDATE_PO_REQ_TYPE_API: 5,
    UPDATE_PICK_UP_TYPE_API: 17,
    UPDATE_ASSET_TYPE_API: 12,
    UPDATE_INVOICE_TYPE_API: 14,
    FETCH_ADV_OR_CLAIM_ENTITY_CHANGE_TYPE_API: 3,
    FETCH_ADVANCE_DETAILS_API: 9,
    FETCH_ADV_OR_CLAIM_DETAIL: 2,
    FETCH_ADV_OR_CLAIM_ITEMS_TYPE_API: 4,
    FINANCE_ADV_OR_CLAIM_GRID_TYPE_API: 1,
    UPDATE_ADV_OR_CLAIM_TYPE_API: 5,
    TIME_SHEET_GRID_TYPE_API: 1,
    TIME_SHEET_CREATE_TYPE_API: 2,
    FETCH_TIME_SHEET_ENTITY_CHANGE_TYPE_API: 3,
    FETCH_TIME_SHEET_RESOURCES_GRID_TYPE_API: 4,
    FETCH_TIME_SHEET_USER_TASKS_API: 6,
    SAVE_OR_UPDATE_TYPE_API: 5,
    FETCH_EXPENSE_FOR_APPROVAL: 8,
    SALES_ORDER_GRID: 1,
    SALES_ORDER_ENTITY_DETAILS_API: 3,
    FETCH_SALESORDER_DETAILS: 2,
    SALES_ORDER_CUSTOMER_DETAILS_API: 4,
    SALES_ORDER_VIEW_DETAILS_API: 5,
    SALES_ORDER_SEARCH_ITEM_DETAILS_API: 6,
    UPDATE_SALESORDER_API: 8,
    FETCH_SALESORDER_DETAILS_PRINT: 9,
    HRMS: 1,
  },

  TASK: {
    PO_AMENDMENT_TITLE: 'PO Amendment',
    PICK_UP_TITLE: 'Pickup - Edit Request Item',
    ASSET_TITLE: 'Edit Asset',
    INVOICE_TITLE: 'Edit Invoice',
  },

  TITLES: {
    MY_TASKS: 'My Tasks',
    LINE_ITEMS: 'Line Items',
    INVENTORY_LINES: 'Inventory Line Items',
    LINE_DETAILS: 'Line Details',
    SUPPLIER: 'Supplier Name',
  },

  TOAST: {
    VARIANTS: {
      SUCCESS: 'success',
      ERROR: 'error',
      WARNING: 'warning',
      INFO: 'info',
    },
  },

  ids: {
    poFrom: 'poFrom',
  },
};
