import seamlessImmutable from 'seamless-immutable';
import setPromiseState from '../../shared/service/promiseState';

const salesOrderInitialState = seamlessImmutable({
  promise: {
    salesOrder: setPromiseState(),
  },
  salesOrderGrid: {},
});

export default salesOrderInitialState;
