import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Spinner } from '@blueprintjs/core';
import Table from '../common/table/Table';
import { filterString } from '../../shared/utils/string';

const SalesDesktop = props => {
  const { rows, history, promise } = props;

  const handleClick = item => {
    history.push({
      pathname: '/home/salesOrder/details',
      state: { selectedTask: item },
    });
  };

  const columns = [
    {
      Header: 'Entity',
      accessor: 'ENTITY_NAME',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Sales Order Type',
      accessor: 'PO_TYPE',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Views',
      accessor: 'CODE',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'PO #',
      accessor: 'CUSTOMER_PO_NO',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'System PO #',
      accessor: 'PO_NO',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Customer',
      accessor: 'NAME',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Status',
      accessor: 'TEMP_STATUS_DESC',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'Total',
      id: 'TOTAL_AMT',
      accessor: val => `${val.SYMBOL}${val.TOTAL_AMT}`,
      filterMethod: (filter, row) => filterString(filter, row),
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 130,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        if (ci.original.property === 'Request #') {
          return (
            <span className="MySalesOrders__mobile--name">{ci.value}</span>
          );
        }
        return ci.value;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = task => {
    return [
      {
        property: 'Enity',
        value: task.ENTITY_NAME,
      },
      {
        property: 'Sales Order Type',
        value: task.PO_TYPE,
      },
      {
        property: 'PO #',
        value: task.CUSTOMER_PO_NO,
      },
      {
        property: 'Customer',
        value: task.NAME,
      },
      {
        property: 'Status',
        value: task.TEMP_STATUS_DESC,
      },
      {
        property: 'Total',
        value: `${task.SYMBOL}${task.TOTAL_AMT}`,
      },
    ];
  };
  return (
    <div>
      {promise.isPending && (
        <Spinner intent={Intent.PRIMARY} className="progress" />
      )}
      {promise.isFulfilled &&
        (rows.length ? (
          rows.map((task, i) => (
            <div key={i} className="MySalesOrders__mobile">
              <Table
                data={getMobileData(task)}
                columns={mobileColumns}
                pageSize={getMobileData(task).length}
                onClick={() => handleClick(task)}
                disableHeader
              />
            </div>
          ))
        ) : (
          <div className="MySalesOrders__mobile">
            <p
              style={{
                background: '#f9f7f7',
                height: '300px',
                textAlign: 'center',
                fontSize: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              No Records...
            </p>
          </div>
        ))}

      {promise.isFulfilled && (
        <div className="MySalesOrders__desktop">
          <Table
            data={rows}
            columns={columns}
            filterable
            onClick={item => handleClick(item)}
          />
        </div>
      )}
    </div>
  );
};

SalesDesktop.propTypes = {
  rows: PropTypes.array.isRequired,
  promise: PropTypes.object.isRequired,
};

export default SalesDesktop;
