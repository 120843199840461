import React from 'react';
import Card from '../../../common/card/Card';
import globalConstants from '../../../../shared/constants';
import POLine from './POLine';
import { H5 } from '@blueprintjs/core';

const PORequisition = props => {
  const { list, history } = props;

  return (
    <div className="Form--fields">
      {list.map((ele, index) => (
        <Card key={index}>
          <H5>
            {globalConstants.TITLES.SUPPLIER}:
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
              &nbsp; {ele.supplierName}
            </span>
          </H5>
          {ele.lineItems && <POLine lines={ele.lineItems} history={history} />}
        </Card>
      ))}
    </div>
  );
};

export default PORequisition;
