import React from 'react';
import Card from '../../../common/card/Card';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';
import localConstants from '../../../../shared/constants';
import { getValue } from '../../../../shared/service/localStorage';

const PickUpHeader = props => {
  const { pickUp } = props;

  const siteCodes =
    (JSON.parse(pickUp.siteCodeList) &&
      JSON.parse(pickUp.siteCodeList).length &&
      JSON.parse(pickUp.siteCodeList).map(sc => sc.CODE)) ||
    [];

  const showProjectCodes =
    getValue(localConstants.LOCAL_STORAGE.ORG_ALL_VIEW_VISIBLE) === 'Y' &&
    getValue(localConstants.LOCAL_STORAGE.PROJ_CODE_VISIBLE) === 'Y';

  const displayDynamicAttributes = attributes => {
    const values = [];
    Object.keys(attributes).forEach((key, i) => {
      values.push(
        <FormInput
          key={i}
          id={i.toString()}
          name={i}
          label={key}
          value={attributes[key]}
          large
          disabled
        />,
      );
    });
    return values;
  };

  return (
    <Card>
      <div className="PickUpHeader Form">
        <div className="Form--fields">
          <FormInput
            id={constants.ids.entityName}
            name={constants.ids.entityName}
            label={constants.labels.Entity}
            value={pickUp.entityName}
            large
            disabled
          />
          <FormInput
            id={constants.ids.viewName}
            name={constants.ids.viewName}
            label={constants.labels.View}
            value={pickUp.viewName}
            large
            disabled
          />
          {pickUp.projectCodeName && showProjectCodes && (
            <FormInput
              id={constants.ids.projectCodes}
              name={constants.ids.projectCodes}
              label={constants.labels.ProjectCode}
              value={pickUp.projectCodeName}
              large
              disabled
            />
          )}
          {!!siteCodes.length && (
            <FormInput
              id={constants.ids.siteCodes}
              name={constants.ids.siteCodes}
              label={constants.labels.SiteCode}
              value={siteCodes.toString()}
              large
              disabled
            />
          )}
          {/* <FormInput
            id={constants.ids.pickUpItemRequestNo}
            name={constants.ids.pickUpItemRequestNo}
            label={constants.labels.pickUpItemRequestNo}
            value={pickUp.pickUpItemRequestNo}
            large
            disabled
          /> */}
          {pickUp.workflowName && (
            <FormInput
              id={constants.ids.workflowName}
              name={constants.ids.workflowId}
              label={constants.labels.Workflow}
              value={pickUp.workflowName}
              disabled
              large
            />
          )}
          {pickUp.wareHouseName && (
            <FormInput
              id={constants.ids.wareHouse}
              name={constants.ids.warehouse}
              label={constants.labels.warehouse}
              value={pickUp.wareHouseName}
              disabled
              large
            />
          )}
          {pickUp.mrpRequestNo && (
            <FormInput
              id={constants.ids.productDetailId}
              name={constants.ids.productDetailId}
              label={constants.labels.productDetailId}
              value={pickUp.mrpRequestNo}
              disabled
              large
            />
          )}
          {pickUp.dynamicAttributes &&
            displayDynamicAttributes(JSON.parse(pickUp.dynamicAttributes))}
        </div>
      </div>
    </Card>
  );
};

export default PickUpHeader;
