import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import constants from '../../../shared/constants';
import menuConstants from './constants';
import { getValue } from '../../../shared/service/localStorage';
import history from '../../../shared/service/history';
import './styles.scss';

import {
  Alignment,
  Button,
  Classes,
  Drawer,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const SideNav = props => {
  const { isDrawerOpen, toggleDrawer, fetchHRMSLoginURL } = props;

  const sideList = (
    <div className="SideNav">
      <Menu className={Classes.ELEVATION_2} large>
        <MenuItem
          className="SideNav-header"
          icon={
            <Icon
              icon={IconNames.USER}
              className="SideNav-icon"
              iconSize={25}
              color="red"
            />
          }
          text={`${getValue(constants.LOCAL_STORAGE.FIRST_NAME)} ${getValue(
            constants.LOCAL_STORAGE.LAST_NAME,
          )}`}
        />
        <MenuDivider />
        <NavLink to="/home/tasks">
          <Button
            className="SideNav-item"
            icon={<Icon icon={IconNames.PROPERTIES} className="SideNav-icon" />}
            text={menuConstants.MY_TASKS}
            alignText={Alignment.LEFT}
            minimal
            fill
          />
        </NavLink>
        <NavLink to="/home/expense">
          <Button
            className="SideNav-item"
            icon={<Icon icon={IconNames.DOLLAR} className="SideNav-icon" />}
            text={menuConstants.EXPENSE}
            alignText={Alignment.LEFT}
            minimal
            fill
          />
        </NavLink>
        {false && (
          <NavLink to="/home/reports">
            <Button
              className="SideNav-item"
              icon={
                <Icon
                  icon={IconNames.GROUPED_BAR_CHART}
                  className="SideNav-icon"
                />
              }
              text={menuConstants.REPORTS}
              alignText={Alignment.LEFT}
              minimal
              fill
            />
          </NavLink>
        )}
        <NavLink to="/home/timesheets">
          <Button
            className="SideNav-item"
            icon={<Icon icon={IconNames.TIME} className="SideNav-icon" />}
            text={menuConstants.TIMESHEETS}
            alignText={Alignment.LEFT}
            minimal
            fill
          />
        </NavLink>
        <NavLink to="/home/salesOrder">
          <Button
            className={'SideNav-item'}
            icon={<Icon icon={IconNames.BOX} className={'SideNav-icon'} />}
            text={menuConstants.SALES_ORDER}
            alignText={Alignment.LEFT}
            minimal={true}
            fill={true}
          />
        </NavLink>
        <Button
          className={'SideNav-item'}
          icon={
            <Icon icon={IconNames.TIMELINE_EVENTS} className={'SideNav-icon'} />
          }
          text={menuConstants.HRMS}
          alignText={Alignment.LEFT}
          minimal={true}
          fill={true}
          onClick={() => fetchHRMSLoginURL()}
        />
        <MenuDivider />
        <MenuItem
          className="SideNav-item"
          onClick={() => {
            localStorage.clear();
            history.push('/login');
          }}
          icon={<Icon icon={IconNames.LOG_OUT} className="SideNav-icon" />}
          text={menuConstants.LOG_OUT}
        />
      </Menu>
    </div>
  );
  const iOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  return (
    <div>
      <Drawer
        isOpen={isDrawerOpen}
        size="250px"
        usePortal
        hasBackdrop={!iOS}
        canOutsideClickClose
        position={Position.LEFT}
        onClose={() => toggleDrawer(false)}
      >
        <div className={Classes.DRAWER_BODY}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
          >
            {sideList}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

SideNav.propTypes = {
  isDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

SideNav.defaultProps = {
  isDrawerOpen: false,
};

export default SideNav;
