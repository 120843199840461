const addTimesheetsActionTypes = {
  ADD_TIMESHEETS_DETAILS: {
    pending: 'addTimesheetsDetails/pending',
    fulfilled: 'addTimesheetsDetails/fulfilled',
    rejected: 'addTimesheetsDetails/rejected',
  },
  UPDATE_TIMESHEETS: {
    pending: 'updateTimesheets/pending',
    fulfilled: 'updateTimesheets/fulfilled',
    failed: 'updateTimesheets/failed',
  },
  SET_ADD_TIMESHEETS_DETAILS: 'SET_ADD_TIMESHEETS_DETAILS',
  IS_FETCHING_ENTITY_DETAILS: 'IS_FETCHING_ENTITY_DETAILS',
  SET_ENTITY_DETAILS: 'SET_ENTITY_DETAILS',
  SET_ITEM_DATA: 'SET_ITEM_DATA',
  RESET_ITEM_DATA: 'RESET_ITEM_DATA',

  SET_NEW_TIMESHEETS_FORM_VALUES: 'SET_NEW_TIMESHEETS_FORM_VALUES',
  RESET_NEW_TIMESHEETS_FORM_VALUES: 'RESET_NEW_TIMESHEETS_FORM_VALUES',

  SET_NEW_ITEM_FORM_VALUES: 'SET_NEW_ITEM_FORM_VALUES',

  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',

  SET_TIMESHEETS_UPDATE_RESPONSE: 'SET_TIMESHEETS_UPDATE_RESPONSE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_TASKS: 'SET_TASKS'
};

export default addTimesheetsActionTypes;
