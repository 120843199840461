import React from 'react';
import PropTypes from 'prop-types';
import '../asset.scss';
import FormTextArea from '../../../common/form/FormTextArea';

const AssetFooter = props => {
  const { handleChange, asset } = props;
  return (
    <div className="Asset__footer">
      <FormTextArea
        className="Asset--field"
        id="comments"
        label="Comments"
        value={asset.comments}
        name="comments"
        onChange={handleChange}
        large
      />
    </div>
  );
};

AssetFooter.propType = {
  handleChange: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired,
};

export default AssetFooter;
