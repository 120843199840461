import React from 'react';
import PropTypes from 'prop-types';
import '../invoice.scss';
import { filterString } from '../../../../shared/utils/string';
import globalConstants from '../../../../shared/constants';
import constants from '../../common/constants';
import Table from '../../../common/table/Table';

const InvoiceLine = props => {
  const { handleLineItemChange, lines } = props;
  const renderEditable = cellInfo => {
    return (
      <input
        type="text"
        className="Invoice__Line--edit"
        contentEditable
        suppressContentEditableWarning
        value={lines[cellInfo.index][cellInfo.column.id]}
        onChange={e =>
          handleLineItemChange(
            cellInfo.index,
            cellInfo.column.id,
            e.target.value,
          )
        }
      />
    );
  };

  const columns = [
    {
      Header: constants.labels.sNo,
      accessor: constants.accessor.sNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.poNo,
      accessor: constants.accessor.poNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemNo,
      accessor: constants.accessor.itemNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.Supplier,
      accessor: constants.accessor.supplierName,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.supplierPartNo,
      accessor: constants.accessor.supplierPartNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.qtyOrdered,
      accessor: constants.accessor.qtyOrdered,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.qtyReceived,
      accessor: constants.accessor.qtyReceived,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.uom,
      accessor: constants.accessor.uom,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.price,
      accessor: constants.accessor.price,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.netPrice,
      accessor: constants.accessor.netPrice,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.discount,
      accessor: constants.accessor.discount,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.sgst,
      accessor: constants.accessor.sgst,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
    {
      Header: constants.labels.cgst,
      accessor: constants.accessor.cgst,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
    {
      Header: constants.labels.igst,
      accessor: constants.accessor.igst,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
    {
      Header: constants.labels.taxAmount,
      accessor: constants.accessor.taxAmount,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
    {
      Header: constants.labels.totalAmount,
      accessor: constants.accessor.totalAmount,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 150,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        // if (ci.original.property === constants.labels.quantity || ci.original.property === constants.labels.comments) {
        //   return renderEditable(ci);
        // }
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = line => {
    return [
      {
        property: constants.labels.sNo,
        value: line.sNo,
      },
      {
        property: constants.labels.poNo,
        value: line.poNo,
      },
      {
        property: constants.labels.itemNo,
        value: line.itemNo,
      },
      {
        property: constants.labels.supplierName,
        value: line.supplierName,
      },
      {
        property: constants.labels.supplierPartNo,
        value: line.supplierPartNo,
      },
      {
        property: constants.labels.qtyOrdered,
        value: line.qtyOrdered,
      },
      {
        property: constants.labels.qtyReceived,
        value: line.qtyReceived,
      },
      {
        property: constants.labels.uom,
        value: line.uom,
      },
      {
        property: constants.labels.price,
        value: line.price,
      },
      {
        property: constants.labels.netPrice,
        value: line.netPrice,
      },
      {
        property: constants.labels.discount,
        value: line.discount,
      },
      {
        property: constants.labels.sgst,
        value: line.sgst,
      },
      {
        property: constants.labels.cgst,
        value: line.cgst,
      },
      {
        property: constants.labels.igst,
        value: line.igst,
      },
      {
        property: constants.labels.taxAmount,
        value: line.taxAmt,
      },
      {
        property: constants.labels.totalAmount,
        value: line.totalAmt,
      },
    ];
  };

  return (
    <div className="Invoice__Line">
      <h4>{globalConstants.TITLES.LINE_ITEMS}</h4>
      <div className="Invoice__Line--desktop">
        <Table
          data={lines}
          columns={columns}
          onClick={() => {}}
          disableFooter
        />
      </div>
      {lines.map(task => (
        <div className="Invoice__Line--mobile">
          <Table
            data={getMobileData(task)}
            columns={mobileColumns}
            pageSize={getMobileData(task).length}
            onClick={() => {}}
            disableHeader
          />
        </div>
      ))}
    </div>
  );
};

InvoiceLine.propType = {
  line: PropTypes.object.isRequired,
  lines: PropTypes.array.isRequired,
  handleLineItemChange: PropTypes.func,
};

export default InvoiceLine;
