import poActionTypes from './actionTypes';
import poInitialState from './initialState';
import setPromiseState from '../../../shared/service/promiseState';

const poReducer = (state = poInitialState, action) => {
  switch (action.type) {
    case poActionTypes.PO.fulfilled:
      return state.setIn(
        ['promise', 'po'],
        setPromiseState(false, true, false),
      );

    case poActionTypes.PO.pending:
      return state
        .setIn(['promise', 'po'], setPromiseState(true, false, false))
        .set('errorMessage', poInitialState.errorMessage);

    case poActionTypes.PO.rejected:
      return state.setIn(
        ['promise', 'po'],
        setPromiseState(false, false, true),
      );

    case poActionTypes.UPDATE_PO.fulfilled:
      return state.setIn(
        ['promise', 'updatePO'],
        setPromiseState(false, true, false),
      );

    case poActionTypes.UPDATE_PO.pending:
      return state
        .setIn(['promise', 'updatePO'], setPromiseState(true, false, false))
        .set('errorMessage', poInitialState.errorMessage);

    case poActionTypes.UPDATE_PO.rejected:
      return state.setIn(
        ['promise', 'updatePO'],
        setPromiseState(false, false, true),
      );

    case poActionTypes.SET_ERROR_MESSAGE:
      return state.set('errorMessage', action.message);

    case poActionTypes.SET_PO:
      return state.set('po', action.po);

    case poActionTypes.SET_PO_APPROVAL_RESPONSE:
      return state.set('poApprovalResponse', action.po);

    default:
      return state;
  }
};

export default poReducer;
