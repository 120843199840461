export default {
  type: {
    advance: 'advance',
    claim: 'claim',
  },
  siteCodeType: {
    view: 'VIEW',
    project: 'PROJECT',
  },
  labels: {
    projectCodes: 'Project Codes',
    siteCodes: 'Site Codes',
  },
};
