import axios from 'axios';
import apiService from '../../shared/service/apiService';
import salesOrderActionTypes from './actionTypes';
import { getValue } from '../../shared/service/localStorage';
import constants from '../../shared/constants';
import history from '../../shared/service/history';
import { setToast } from '../home/homeActions';

const fetchSalesOrderGrid = () => {
  return dispatch => {
    const fetchSalesOrderGridUrl = apiService.endpoints.app.generateSalesOrderGridUrl();
    dispatch({ type: salesOrderActionTypes.SALES_ORDER.pending });

    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.SALES_ORDER_GRID,
        search: '',
        current: 0,
        sortBy: '',
        sortDirection: '',
        rowCount: 100,
      },
    };

    return axios
      .post(fetchSalesOrderGridUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({ type: salesOrderActionTypes.SALES_ORDER.fulfilled });
        dispatch({
          type: salesOrderActionTypes.SET_SALES_ORDER_GRID,
          salesOrderGrid: response.data,
        });
      })
      .catch(err => {
        dispatch({ type: salesOrderActionTypes.SALES_ORDER.rejected });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export { fetchSalesOrderGrid };
