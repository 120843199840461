/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Callout,
  Intent,
  Card,
  Elevation,
  Icon,
  ControlGroup,
  H4,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import FormInput from '../../../common/form/FormInput';
import '../style.scss';

class SearchSalesOrderItem extends React.Component {
  componentDidMount() {
    let filteredList =
      (this.props.searchItemList && [...this.props.searchItemList]) || [];
    let selectedList = [];
    this.setState({
      filteredList: filteredList,
      selectedList: selectedList,
    });
  }

  render() {
    const {
      t,
      history,
      setSalesOrderSearchItemData,
      searchItemList,
      fetchItemSeachDetails,
      deleteInSalesOrderSearchItemData,
    } = this.props;
    const { fields } = history.location.state;
    let showErrorMessage = false;
    const renderItemDetails = (item, i) => {
      return (
        <Card
          key={i}
          className="ExpenseItemList--Item"
          style={{
            backgroundColor: item.selected ? 'antiquewhite' : '',
          }}
          interactive
          elevation={Elevation.ZERO}
          onClick={() => {
            item = { ...item, selected: !item.selected };
            setSalesOrderSearchItemData(item, i);
          }}
        >
          <div className="ExpenseItemList--Item__withDate">
            <span className="ExpenseItemList--Item__category bp3-text-overflow-ellipsis">
              {item.desc}
            </span>
          </div>
          <div>
            <span className="bp3-text-muted">Item No: {item.itemNo}</span>
          </div>
          <div>
            <span className="bp3-tag .bp3-large bp3-minimal bp3-intent-success">
              Price: {fields.currencySymbol} {item.price.toFixed(2)}
            </span>
            <span
              className="bp3-tag .bp3-large bp3-minimal bp3-intent-success"
              style={{ float: 'right' }}
            >
              Tax: {fields.currencySymbol} {item.taxAmount.toFixed(2)}
            </span>
          </div>
        </Card>
      );
    };
    const onSubmit = (values, actions) => {
      actions.setSubmitting(false);
      showErrorMessage = false;
      const selected =
        (searchItemList && searchItemList.filter(item => item.selected)) || [];
      if (selected.length) history.goBack();
      else showErrorMessage = true;
    };

    const onCancel = () => {
      if (searchItemList && searchItemList.length) {
        searchItemList.forEach(
          item => item.selected && deleteInSalesOrderSearchItemData(item),
        );
        setTimeout(() => {
          history.goBack();
        }, 200);
      } else {
        setTimeout(() => {
          history.goBack();
        }, 200);
      }
    };

    return (
      <div className="AddItem container">
        <Callout
          intent={Intent.PRIMARY}
          icon={IconNames.INFO_SIGN}
          title={'Search for an Item'}
        />
        <Formik onSubmit={onSubmit}>
          {props => {
            const {
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
            } = props;
            const clearSelection = (
              <Button
                icon={
                  <Icon icon={IconNames.CROSS} iconSize={Icon.SIZE_LARGE} />
                }
                style={{ borderRadius: '50%' }}
                intent={Intent.PRIMARY}
                minimal
                onClick={() => {
                  fetchItemSeachDetails(fields);
                  setFieldValue('seachTerm', '');
                }}
              />
            );
            return (
              <Form className="AddItem__Form" style={{ marginBottom: '15%' }}>
                <ControlGroup fill={true}>
                  <Field
                    id="seachTerm"
                    placeholder={'Search Items'}
                    component={FormInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    large
                    fill
                    rightElement={clearSelection}
                    value={values.seachTerm}
                  />
                  <Button
                    type="button"
                    intent="primary"
                    style={{
                      marginLeft: '2%',
                      height: '40px',
                      borderRadius: '5px',
                    }}
                    icon={IconNames.SEARCH}
                    disabled={isSubmitting}
                    onClick={() => {
                      const searchValues = {
                        ...fields,
                        searchKey: values.seachTerm,
                      };
                      fetchItemSeachDetails(searchValues);
                    }}
                  />
                </ControlGroup>
                {showErrorMessage && (
                  <div>
                    <H4 className={'AddItem__Errors'}> No items selected.</H4>
                  </div>
                )}
                {searchItemList &&
                  searchItemList.map((item, i) => {
                    return renderItemDetails(item, i);
                  })}
                <div className="AddItem__ButtonGroup">
                  <Button
                    className="AddItem__Form--fields"
                    style={{ marginRight: '2%', width: '46%' }}
                    type="submit"
                    text={t('salesOrder.addItem.newItem.success')}
                    large="true"
                    intent="success"
                    disabled={isSubmitting}
                    fill
                  />
                  <Button
                    className="AddItem__Form--fields"
                    type="button"
                    style={{ marginRight: '2%', width: '46%' }}
                    text={t('commons.cancel')}
                    large="true"
                    intent="danger"
                    disabled={isSubmitting}
                    fill
                    onClick={() => onCancel()}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

SearchSalesOrderItem.propTypes = {
  history: PropTypes.any.isRequired,
  setSalesOrderSearchItemData: PropTypes.func.isRequired,
};

SearchSalesOrderItem.defaultProps = {};

export default SearchSalesOrderItem;
