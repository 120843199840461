import axios from 'axios';
import apiService from '../../shared/service/apiService';
import timesheetsActionTypes from './timesheetsActionTypes';
import { getValue } from '../../shared/service/localStorage';
import constants from '../../shared/constants';
import history from '../../shared/service/history';
import { setToast } from '../home/homeActions';

const fetchTimesheetsGrid = () => {
  return dispatch => {
    const fetchTimesheetsGridUrl = apiService.endpoints.app.generateTimesheetGridUrl();
    dispatch({ type: timesheetsActionTypes.TIMESHEETS.pending });
    const payload = {
      token:
        getValue(constants.LOCAL_STORAGE.TOKEN) || constants.EMPTY_STRING,
      loadBalancer:
        getValue(constants.LOCAL_STORAGE.LOADBALANCER) ||
        constants.EMPTY_STRING,
      payload: {
        companyId:
          getValue(constants.LOCAL_STORAGE.ORG_ID) || constants.EMPTY_STRING,
        userId:
          getValue(constants.LOCAL_STORAGE.USER_ID) || constants.EMPTY_STRING,
        apiType: constants.API_TYPES.TIME_SHEET_GRID_TYPE_API,
        search: '',
        current: 0,
        sortBy: '',
        sortDirection: '',
        rowCount: 100,
      },
    };

    return axios
      .post(fetchTimesheetsGridUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        dispatch({ type: timesheetsActionTypes.TIMESHEETS.fulfilled });
        dispatch({
          type: timesheetsActionTypes.SET_TIMESHEETS_GRID,
          timesheetsGrid: response.data,
        });
      })
      .catch(err => {
        dispatch({ type: timesheetsActionTypes.TIMESHEETS.rejected });
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          const message = err.response
            ? err.response.data.message
            : constants.SERVER_UNAVAILABLE;
          dispatch(
            setToast({
              variant: constants.TOAST.VARIANTS.ERROR,
              message,
              isOpen: true,
            }),
          );
        }
      });
  };
};

export { fetchTimesheetsGrid };
