import React, { Component } from 'react';
import DesktopTable from './desktop';
import './styles.scss';
import PropTypes from 'prop-types';
import constants from '../../../shared/constants';
import { Spinner, Intent } from '@blueprintjs/core';

class MyTasks extends Component {
  componentWillMount() {
    this.props.getTasks();
  }

  render() {
    const { tasks, history, setSelectedTask, promise } = this.props;
    return (
      (tasks && (
        <div className="container">
          <h4>{constants.TITLES.MY_TASKS}</h4>
          <DesktopTable
            tasks={tasks}
            history={history}
            setSelectedTask={setSelectedTask}
            promise={promise}
          />
        </div>
      )) || <Spinner intent={Intent.PRIMARY} className="progress" />
    );
  }
}

MyTasks.propTypes = {
  getTasks: PropTypes.func.isRequired,
  setSelectedTask: PropTypes.func.isRequired,
  promise: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object,
  tasks: PropTypes.array.isRequired,
};

export default MyTasks;
