import React from 'react';
import FormInput from '../form/FormInput';
import { Field } from 'formik';
import FormSelect from '../form/FormSelect';
import FormMultiSelect from '../form/FormMultiSelect';
import moment from 'moment';
import { Intent } from '@blueprintjs/core';
import FormTextArea from '../form/FormTextArea';

const DynamicField = props => {
  const { data, type, prevProps, dynamicFields } = props;
  const {
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = prevProps;
  const fetchOptions = data => {
    if (data.commonAttributes && data.commonAttributes.attributeValues)
      return data.commonAttributes.attributeValues.map(option => {
        return {
          value: option.valueId,
          label: option.values,
        };
      });
    else
      return data.attributeValues.map(option => {
        return {
          value: option.valueId,
          label: option.values,
        };
      });
  };

  const fetchDefaultOptions = (org, subset) => {
    return (
      subset && org && subset.map(ele => org.find(val => val.value === ele))
    );
  };

  const renderSwitch = (type, fieldName, isMandatory, data, i) => {
    switch (type) {
      case 'Date & Time':
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            type="datetime-local"
            component={FormInput}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              dynamicFields
                ? moment(
                    new Date(dynamicFields[`attr_id_${data.attributeId}`]),
                  ).format(moment.HTML5_FMT.DATETIME_LOCAL)
                : moment(new Date()).format(moment.HTML5_FMT.DATETIME_LOCAL)
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
      case 'Date':
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            type="date"
            component={FormInput}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              dynamicFields
                ? moment(
                    new Date(dynamicFields[`attr_id_${data.attributeId}`]),
                  ).format(moment.HTML5_FMT.DATE)
                : moment(new Date()).format(moment.HTML5_FMT.DATE)
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
      case 'Drop Down':
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            component={FormSelect}
            onChange={e =>
              setFieldValue(
                `dynamicFields.attr_id_${data.attributeId}`,
                e.value,
              )
            }
            onBlur={handleBlur}
            options={fetchOptions(data)}
            value={
              dynamicFields && dynamicFields[`attr_id_${data.attributeId}`]
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
      case 'Multi Select':
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            component={FormMultiSelect}
            onChange={e => {
              setFieldValue(
                `dynamicFields.attr_id_${data.attributeId}`,
                e.map(ele => ele.value),
              );
            }}
            onBlur={handleBlur}
            options={fetchOptions(data)}
            value={
              dynamicFields && dynamicFields[`attr_id_${data.attributeId}`]
            }
            defaultValue={
              dynamicFields &&
              fetchDefaultOptions(
                fetchOptions(data),
                dynamicFields[`attr_id_${data.attributeId}`],
              )
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
      case 'Text Area':
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            component={FormTextArea}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              dynamicFields && dynamicFields[`attr_id_${data.attributeId}`]
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
      default:
        return (
          <Field
            className="AddExpense__Form--fields"
            id={`dynamicFields.attr_id_${data.attributeId}`}
            label={fieldName}
            component={FormInput}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              dynamicFields && dynamicFields[`attr_id_${data.attributeId}`]
            }
            large
            required={isMandatory}
            helperText={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? errors[`dynamicFields.attr_id_${data.attributeId}`]
                : null
            }
            intent={
              errors[`dynamicFields.attr_id_${data.attributeId}`] &&
              touched[`dynamicFields.attr_id_${data.attributeId}`]
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        );
    }
  };

  return (
    <div>{renderSwitch(type, data.fieldName, data.isMandatory, data)}</div>
  );
};

export default DynamicField;
