import addTimesheetsInitialState from './addTimesheetsInitialState';
import addTimesheetsActionTypes from './addTimesheetsActionTypes';
import setPromiseState from '../../../shared/service/promiseState';

const addTimesheetsReducer = (state = addTimesheetsInitialState, action) => {
  switch (action.type) {
    case addTimesheetsActionTypes.ADD_TIMESHEETS_DETAILS.fulfilled:
      return state.setIn(
        ['promise', 'addTimesheetsDetails'],
        setPromiseState(false, true, false),
      );
    case addTimesheetsActionTypes.ADD_TIMESHEETS_DETAILS.pending:
      return state.setIn(
        ['promise', 'addTimesheetsDetails'],
        setPromiseState(true, false, false),
      );
    case addTimesheetsActionTypes.ADD_TIMESHEETS_DETAILS.rejected:
      return state.setIn(
        ['promise', 'addTimesheetsDetails'],
        setPromiseState(false, false, true),
      );
    case addTimesheetsActionTypes.SET_ADD_TIMESHEETS_DETAILS:
      return state.set('addTimesheetsDetails', action.addTimesheetsDetails);

    case addTimesheetsActionTypes.IS_FETCHING_ENTITY_DETAILS:
      return state.set(
        'isFetchingEntityDetails',
        action.isFetchingEntityDetails,
      );
    case addTimesheetsActionTypes.SET_ENTITY_DETAILS:
      return state
        .set('projectCodeList', action.entityDetails.projectCodeList)
        .set('workflowList', action.entityDetails.workflowList);

    case addTimesheetsActionTypes.SET_ITEM_DATA:
      return state.set('itemData', action.itemData);
    case addTimesheetsActionTypes.RESET_ITEM_DATA:
      return state.set('itemData', addTimesheetsInitialState.itemData);

    case addTimesheetsActionTypes.SET_NEW_TIMESHEETS_FORM_VALUES:
      return state.set(
        'newTimesheetsFormValues',
        action.newTimesheetsFormValues,
      );
    case addTimesheetsActionTypes.RESET_NEW_TIMESHEETS_FORM_VALUES:
      return state.set(
        'newTimesheetsFormValues',
        addTimesheetsInitialState.newTimesheetsFormValues,
      );

    case addTimesheetsActionTypes.SET_NEW_ITEM_FORM_VALUES:
      return state.set('newItemFormValues', action.newItemFormValues);

    case addTimesheetsActionTypes.SET_SELECTED_CATEGORY:
      return state.set('selectedCategory', action.selectedCategory);
    case addTimesheetsActionTypes.SET_TASKS:
      return state.set('tasksList', action.tasksList)
    default:
      return state;
  }
};

export default addTimesheetsReducer;
