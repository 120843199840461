import { connect } from 'react-redux';
import NewItem from './NewSalesOrder';
import {
  setSalesOrderItemData,
  deleteInSalesOrderItemData,
} from '../addSalesOrderActions';
import t from '../../../../shared/service/translations';
import {
  selectSalesOrderItemData,
  selectNewSalesOrderFormValues,
  selectNewItemFormValues,
  selectSalesOrderFormValues,
} from '../addSalesOrderSelector';

const mapStateToProps = state => {
  return {
    t,
    selectNewSalesOrderFormValues: selectNewSalesOrderFormValues(state),
    itemData: selectSalesOrderItemData(state),
    newItemValues: selectNewItemFormValues(state),
    salesOderFormValues: selectSalesOrderFormValues(state),
  };
};

const mapDispatchToProps = {
  setSalesOrderItemData,
  deleteInSalesOrderItemData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewItem);
