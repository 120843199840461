/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Button, Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as Yup from 'yup';
import * as moment from 'moment';
import FormInput from '../../../common/form/FormInput';
import FormCheckBox from '../../../common/form/FormCheckBox';
import FormFileInput from '../../../common/form/FormFileInput';
import '../style.scss';
import constants from '../constants';
import Tree from '../../../common/tree/Tree';
import DialogComponent from '../../../common/dialog/Dialog';

const NewItem = newProps => {
  const {
    t,
    history,
    itemData,
    setItemData,
    newItemValues,
    deleteInItemData,
    categoriesTree,
    categories,
    setSelectedCategory,
    selectedCategory,
  } = newProps;

  const { index, approvalMode, item, type } = history.location.state;
  const itemType = type;
  const indexExists = index || index === 0;
  const isClaim = itemType === constants.type.claim;
  const initialValues = (approvalMode && item) ||
    (indexExists
      ? itemData && itemData[index]
      : { ...newItemValues, isClaim });
  let dialogOpened = false;
  const handleOpen = () => {
    dialogOpened = true;
  };
  const handleClose = () => {
    dialogOpened = false;
  };

  const getSelectedCategoryText = () => {
    let text = '';
    if (!_.isEmpty(selectedCategory)) {
      const category = categories.find(cat => {
        return cat.id === selectedCategory.value;
      });
      text = category.text || '';
    } else text = initialValues && initialValues.category;
    return text;
  };

  const onSubmit = (values, actions) => {
    const data = {
      ...values,
      category:
        (selectedCategory.label && selectedCategory.label) ||
        (initialValues && initialValues.category),
      categoryId:
        (selectedCategory.value && selectedCategory.value) ||
        (initialValues && initialValues.categoryId),
      comments: values.description,
    };
    setItemData(data, indexExists, index, history);
    actions.setSubmitting(false);
  };

  const validations = Yup.object().shape({
    amount: Yup.number()
      .required(t('expenses.addItem.newItem.validations.amount'))
      .positive(t('expenses.addItem.newItem.validations.positiveAmount')),
  });

  const handleCategorySelection = node => {
    setSelectedCategory(node);
  };

  return (
    <div className="AddItem container">
      <Callout
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title={approvalMode ? 'Item Details' : 'Add Item'}
      >
        {!approvalMode && `Create a new ${itemType}`}
      </Callout>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validations}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
          } = props;
          return (
            <Form className="AddItem__Form">
              <Field
                className="AddItem__Form--fields"
                id="categoryId"
                label={t('expenses.addItem.newItem.category')}
                readOnly
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={e => {
                  handleOpen();
                  handleChange(e);
                }}
                disabled={approvalMode}
                large
                value={getSelectedCategoryText()}
                helperText={
                  errors.category && touched.category ? errors.category : null
                }
                intent={
                  errors.category && touched.category
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />

              <Field
                component={DialogComponent}
                icon={IconNames.TREE}
                onClose={() => {
                  handleClose();
                  setFieldValue('categoryId', getSelectedCategoryText());
                }}
                disabled={approvalMode}
                title={t('expenses.addItem.newItem.selectCategory')}
                isOpen={dialogOpened}
                className="AddItem__Dialog"
                dialogBody={
                  <Tree
                    data={categoriesTree}
                    handleCategorySelection={handleCategorySelection}
                  />
                }
              />

              <Field
                className="AddItem__Form--fields"
                id="description"
                label={t('expenses.addItem.newItem.description')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                disabled={approvalMode}
                value={values.description}
                helperText={
                  errors.description && touched.description
                    ? errors.description
                    : null
                }
                intent={
                  errors.description && touched.description
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              {isClaim && (
                <Field
                  className="AddItem__Form--fields"
                  id="merchant"
                  label={t('expenses.addItem.newItem.merchant')}
                  component={FormInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  large
                  disabled={approvalMode}
                  value={values.merchant}
                  helperText={
                    errors.merchant && touched.merchant ? errors.merchant : null
                  }
                  intent={
                    errors.merchant && touched.merchant
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {isClaim && (
                <Field
                  className="AddItem__Form--fields"
                  id="date"
                  label={t('expenses.addItem.newItem.date')}
                  type="date"
                  component={FormInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  large
                  disabled={approvalMode}
                  value={moment(new Date(values.date)).format(
                    moment.HTML5_FMT.DATE,
                  )}
                  helperText={errors.date && touched.date ? errors.date : null}
                  intent={
                    errors.date && touched.date ? Intent.DANGER : Intent.NONE
                  }
                />
              )}
              {isClaim &&
                approvalMode &&
                initialValues &&
                initialValues.uploadedFileName && (
                  <div className="AddItem__Form--fields">
                    <p>Attachment</p>
                    <li>
                      <a
                        href={initialValues.attachmentPath}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {initialValues.uploadedFileName}
                      </a>
                    </li>
                  </div>
                )}
              {isClaim && !approvalMode && (
                <Field
                  className="AddItem__Form--fields"
                  id="file"
                  label={t('expenses.addItem.newItem.file')}
                  component={FormFileInput}
                  text={
                    values.file && values.file.name
                      ? values.file.name
                      : t('commons.chooseFile')
                  }
                  disabled={approvalMode}
                  onChange={e => {
                    setFieldValue('file', e.target.files[0]);
                  }}
                  onBlur={handleBlur}
                  large
                  helperText={errors.file && touched.file ? errors.file : null}
                  intent={
                    errors.file && touched.file ? Intent.DANGER : Intent.NONE
                  }
                />
              )}
              <Field
                className="AddItem__Form--fields"
                id="billable"
                label={t('expenses.addItem.newItem.billable')}
                checked={values.billable}
                onBlur={handleBlur}
                component={FormCheckBox}
                onChange={handleChange}
                large
                disabled={approvalMode}
                helperText={
                  errors.billable && touched.billable ? errors.billable : null
                }
                intent={
                  errors.billable && touched.billable
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddItem__Form--fields"
                id="amount"
                label={t('expenses.addItem.newItem.amount')}
                type="number"
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                disabled={approvalMode}
                value={values.amount}
                helperText={
                  errors.amount && touched.amount ? errors.amount : null
                }
                intent={
                  errors.amount && touched.amount ? Intent.DANGER : Intent.NONE
                }
              />
              {!approvalMode && (
                <Button
                  className="AddItem__Form--fields"
                  type="submit"
                  intent="success"
                  text={
                    indexExists
                      ? t('expenses.addItem.newItem.update')
                      : t('expenses.addItem.newItem.submit')
                  }
                  large
                  fill
                  disabled={isSubmitting}
                />
              )}
              {!approvalMode && indexExists && (
                <Button
                  className="AddItem__Form--fields"
                  type="button"
                  intent="danger"
                  text={t('commons.delete')}
                  large
                  fill
                  disabled={isSubmitting}
                  onClick={() => deleteInItemData(index, history)}
                />
              )}
              <Button
                className="AddItem__Form--fields"
                type="button"
                text={t('commons.cancel')}
                large="true"
                disabled={isSubmitting}
                fill
                onClick={() => history.goBack()}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

NewItem.propTypes = {
  history: PropTypes.any.isRequired,
  setItemData: PropTypes.func.isRequired,
  deleteInItemData: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  categoriesTree: PropTypes.array,
  categories: PropTypes.array,
  selectNewExpenseFormValues: PropTypes.object,
};

NewItem.defaultProps = {
  categoriesTree: [],
  categories: [],
  selectNewExpenseFormValues: {},
};

export default NewItem;
