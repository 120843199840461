import React from 'react';
import PropTypes from 'prop-types';
import '../asset.scss';
import { filterString } from '../../../../shared/utils/string';
import globalConstants from '../../../../shared/constants';
import constants from '../../common/constants';
import Table from '../../../common/table/Table';

const AssetLine = props => {
  const { handleLineItemChange, lines } = props;
  const renderEditable = cellInfo => {
    return (
      <input
        type="text"
        className="Asset__Line--edit"
        contentEditable
        suppressContentEditableWarning
        value={lines[cellInfo.index][cellInfo.column.id]}
        onChange={e =>
          handleLineItemChange(
            cellInfo.index,
            cellInfo.column.id,
            e.target.value,
          )
        }
      />
    );
  };

  const columns = [
    {
      Header: constants.labels.lineItem,
      accessor: constants.accessor.lineItem,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemNo,
      accessor: constants.accessor.itemNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemDescription,
      accessor: constants.accessor.header,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.uom,
      accessor: constants.accessor.uom,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.quantity,
      accessor: constants.accessor.qty,
      filterMethod: (filter, row) => filterString(filter, row),
      Cell: renderEditable,
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 150,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = line => {
    return [
      {
        property: constants.labels.lineItem,
        value: line.lineItem,
      },
      {
        property: constants.labels.itemNo,
        value: line.itemNo,
      },
      {
        property: constants.labels.itemDescription,
        value: line.header,
      },
      {
        property: constants.labels.uom,
        value: line.uom,
      },
      {
        property: constants.labels.quantity,
        value: line.qty,
      },
    ];
  };

  return (
    <div className="Asset__Line">
      <h4>{globalConstants.TITLES.LINE_ITEMS}</h4>
      <div className="Asset__Line--desktop">
        <Table
          data={lines}
          columns={columns}
          onClick={() => {}}
          disableFooter
        />
      </div>
      {lines.map(task => (
        <div className="Asset__Line--mobile">
          <Table
            data={getMobileData(task)}
            columns={mobileColumns}
            pageSize={getMobileData(task).length}
            onClick={() => {}}
            disableHeader
          />
        </div>
      ))}
    </div>
  );
};

AssetLine.propType = {
  line: PropTypes.object.isRequired,
  lines: PropTypes.array.isRequired,
  handleLineItemChange: PropTypes.func,
};

export default AssetLine;
