import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';
import { Tag } from '@blueprintjs/core';
import { getIntString } from '../../../../shared/utils/string';
import constants from '../../common/constants';
import FormInput from '../../../common/form/FormInput';

const Price = props => {
  const { po } = props;
  return (
    <div>
      <div className="Form--fields">
        <FormInput
          id={constants.ids.poTotalNetPrice}
          label={constants.labels.totalNetPrice}
          name={constants.ids.poTotalNetPrice}
          large
          disabled
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={getIntString(po.totalNetPrice)}
        />
        <FormInput
          id={constants.ids.poTotalTax}
          label={constants.labels.totalTax}
          name={constants.ids.poTotalTax}
          large
          disabled
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={getIntString(po.totalTax)}
        />
        <FormInput
          id={constants.ids.poTradeDiscount}
          label={constants.labels.TradeDiscount}
          name={constants.ids.poTradeDiscount}
          large
          disabled
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={getIntString(po.tradingDiscount)}
        />
        <FormInput
          id={constants.ids.poSubTotal}
          label={constants.labels.poSubTotal}
          name={constants.ids.poSubTotal}
          large
          disabled
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={getIntString(po.subTotal)}
        />
        {!po.isGlobalPo && (
          <FormInput
            id={constants.ids.poDiscount}
            label={constants.labels.Discount}
            name={constants.ids.poDiscount}
            large
            disabled
            rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
            value={getIntString(po.discount)}
          />
        )}
        <FormInput
          id={constants.ids.poTotalTax}
          label={constants.labels.grandTotal}
          name={constants.ids.poTotalTax}
          large
          disabled
          rightElement={<Tag minimal>{constants.labels.rupees}</Tag>}
          value={getIntString(po.grandTotal)}
        />
      </div>
    </div>
  );
};

Price.propType = {
  lines: PropTypes.array.isRequired,
};

export default Price;
