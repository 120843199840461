import React from 'react';
import PropTypes from 'prop-types';
import { filterString } from '../../../../shared/utils/string';
import globalConstants from '../../../../shared/constants';
import constants from '../../common/constants';
import Table from '../../../common/table/Table';
import '../pickUp.scss';

const PickUpLine = props => {
  const { lines, history } = props;

  const showLineDetails = item => {
    history.push({
      pathname: '/home/tasks/pickUp/line',
      state: { line: item },
    });
  };

  const columns = [
    {
      Header: constants.labels.lineItemId,
      accessor: constants.accessor.lineItemId,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemNo,
      accessor: constants.accessor.itemNo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.itemDescription,
      accessor: constants.accessor.itemDescription,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.uom,
      accessor: constants.accessor.uom,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.mrpQty,
      accessor: constants.accessor.mrpQty,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.pickedupQty,
      accessor: constants.accessor.pickedupQty,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.quantity,
      accessor: constants.accessor.qty,
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: constants.labels.bundleInfo,
      accessor: constants.accessor.bundleInfo,
      filterMethod: (filter, row) => filterString(filter, row),
    },
  ];
  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 150,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = line => {
    return [
      {
        property: constants.labels.itemNo,
        value: line.itemNo,
      },
      {
        property: constants.labels.itemDescription,
        value: line.itemDescription,
      },
    ];
  };
  return (
    <div className="PickUp__Line">
      <h4>{globalConstants.TITLES.LINE_ITEMS}</h4>
      <div className="PickUp__Line--desktop">
        <Table
          data={JSON.parse(lines)}
          columns={columns}
          onClick={() => {}}
          disableFooter
        />
      </div>
      {JSON.parse(lines).map((pickUp, index) => (
        <div className="PickUp__Line--mobile" key={index}>
          <Table
            data={getMobileData(pickUp)}
            columns={mobileColumns}
            pageSize={getMobileData(pickUp).length}
            onClick={() => showLineDetails(pickUp)}
            disableHeader
          />
        </div>
      ))}
    </div>
  );
};

PickUpLine.propType = {
  line: PropTypes.object.isRequired,
  lines: PropTypes.array.isRequired,
  handleLineItemChange: PropTypes.func,
};

export default PickUpLine;
