import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Intent, TextArea } from '@blueprintjs/core';
import './form.scss';

const FormTextArea = props => {
  const {
    disabled,
    helperText,
    inline,
    intent,
    label,
    labelInfo,
    onChange,
    onBlur,
    placeholder,
    small,
    large,
    id,
    value,
    className,
    readOnly,
  } = props;
  return (
    <FormGroup
      disabled={disabled}
      helperText={helperText}
      inline={inline}
      intent={intent}
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
    >
      <TextArea
        id={id}
        className={`${readOnly ? 'readonly' : ''} ${className}`}
        placeholder={placeholder}
        disabled={disabled}
        intent={intent}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        large={large}
        small={small}
        fill
      />
    </FormGroup>
  );
};

FormTextArea.propTypes = {
  disabled: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  intent: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  large: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FormTextArea.defaultProps = {
  disabled: false,
  helperText: '',
  inline: false,
  intent: Intent.NONE,
  label: '',
  labelInfo: '',
  placeholder: '',
  small: false,
  large: false,
};

export default FormTextArea;
