import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Button, Callout, Intent, H5, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import FormInput from '../../../common/form/FormInput';
import FormTextArea from '../../../common/form/FormTextArea';
import * as moment from 'moment';
import '../style.scss';
import Card from '../../../common/card/Card';

const NewItem = props => {
  const { t, tasksList, history, submitTimesheets } = props;
  let totalDays = 1;
  let showErrorMessage = false;
  const listTasks = (index, handleChange, handleBlur) => {
    let tasks = [];
    tasksList.tasks.forEach((task, i) => {
      tasks.push(
        <Field
          key={i}
          id={`tasks[${index}].task.'${task.resourceTaskId}'`}
          className="AddItem__Form--fields"
          label={task.name}
          type="number"
          component={FormInput}
          max={12}
          min={0}
          defaultValue={0}
          onChange={handleChange}
          onBlur={handleBlur}
          inline={true}
          rightElement={<Tag minimal={true}>{'Hours'}</Tag>}
        />,
      );
    });
    return tasks;
  };

  const generateCards = (handleChange, handleBlur) => {
    const fromDate = moment(tasksList.values.fromDate);
    const toDate = moment(tasksList.values.toDate);
    const diffDays = toDate.diff(fromDate, 'days');
    totalDays = diffDays;
    let cards = [];
    for (let i = 0; i <= diffDays; i++) {
      cards.push(
        <div key={i}>
          <Card className={'AddItem__Form--fields'}>
            <H5 align="center">
              {moment(fromDate)
                .add(i, 'days')
                .format('MM/DD/YYYY')}
            </H5>
            {listTasks(i, handleChange, handleBlur)}
            <Field
              id={`tasks[${i}].comments`}
              label={'Comments'}
              className={'AddTimesheets_Form--fields'}
              component={FormTextArea}
              onChange={handleChange}
              onBlur={handleBlur}
              fill={true}
            />
          </Card>
        </div>,
      );
    }
    return cards;
  };

  const onSubmit = (values, actions) => {
    if (
      values &&
      values.tasks &&
      values.tasks.length === totalDays + 1 &&
      tasksList
    ) {
      submitTimesheets(values, tasksList, history);
      showErrorMessage = false;
    } else {
      showErrorMessage = true;
      actions.setSubmitting(false);
    }
  };

  return (
    <div className="AddNewTimesheet container">
      <Callout
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title="Timesheets"
      >
        {`Add Hours`}
      </Callout>
      <Formik onSubmit={onSubmit}>
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props;
          return (
            <Form className="AddItem__Form">
              <div>
                {tasksList &&
                  tasksList.tasks &&
                  generateCards(
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                  )}
              </div>
              {showErrorMessage && (
                <div>
                  <p style={{ color: 'red', margin: '1%' }}>
                    {t('timesheets.addItem.validations.hours')}
                  </p>
                </div>
              )}
              {tasksList && (
                <Button
                  className="AddNewTimesheet__Form--fields"
                  type="submit"
                  intent="success"
                  text={t('commons.submit')}
                  large
                  onClick={() => {
                    values.submitType = 'submit';
                  }}
                  fill
                  disabled={isSubmitting}
                />
              )}
              {tasksList && (
                <Button
                  className="AddNewTimesheet__Form--fields"
                  type="submit"
                  intent="primary"
                  text={t('commons.save')}
                  large
                  onClick={() => {
                    values.submitType = 'save';
                  }}
                  fill
                  disabled={isSubmitting}
                />
              )}
              <Button
                className="AddNewTimesheet__Form--fields"
                type="button"
                intent={tasksList ? 'none' : 'danger'}
                text={tasksList ? t('commons.cancel') : t('commons.back')}
                large="true"
                disabled={isSubmitting}
                fill
                onClick={() => history.goBack()}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

NewItem.propTypes = {
  history: PropTypes.any.isRequired,
};

export default NewItem;
