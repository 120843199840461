import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import TimesheetsDesktop from './desktop';
import t from '../../shared/service/translations';
import { Button, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

class TimesheetsComponent extends Component {
  state = {
    anchorEl: null
  };

  componentWillMount() {
    this.props.fetchTimesheetsGrid();
  }

  render() {
    const { anchorEl } = this.state;
    const { history, timesheetsGrid, timesheetsPromise } = this.props;

    return (
      <div className='Timesheets container'>
        <h4>{t('timesheets.header')}</h4>
        <TimesheetsDesktop
          rows={timesheetsGrid.rows || []}
          promise={timesheetsPromise}
        />

        <Button
          className='Timesheets__Add'
          icon={<Icon icon={IconNames.PLUS} iconSize={Icon.SIZE_LARGE} />}
          intent={Intent.DANGER}
          minimal={true}
          onClick={() => history.push('/home/timesheets/add')}
          aria-owns={anchorEl ? 'timesheets-menu' : undefined}
          aria-haspopup='true'
        />
      </div>
    );
  }
}

TimesheetsComponent.propTypes = {
  fetchTimesheetsGrid: PropTypes.func.isRequired,
  timesheetsGrid: PropTypes.object.isRequired,
  timesheetsPromise: PropTypes.object.isRequired
};

export default TimesheetsComponent;
