import { get } from 'lodash';

const addExpenseDetailsPromise = state =>
  get(state, 'addExpense.promise.addExpenseDetails');
const getAddExpenseDetails = state =>
  get(state, 'addExpense.addExpenseDetails');

const getSelectedTask = state => get(state, 'tasks.selectedTask');

const unflatten = arr => {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = { ...arrElem, name: arrElem.text };
  }

  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      if (parseInt(mappedElem.parent)) {
        if (!mappedArr[parseInt(mappedElem.parent)].children) {
          mappedArr[parseInt(mappedElem.parent)].children = [];
        }
        mappedArr[parseInt(mappedElem.parent)].children.push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

const getCategories = state => {
  const categories =
    getAddExpenseDetails(state).categories ||
    selectExpenseFormValues(state).categories ||
    [];
  return categories.map(category => {
    return { ...category, label: category.text, value: category.id };
  });
};

const getCategoriesTree = state => {
  const categories = getCategories(state);
  return unflatten([...categories]);
};

const isFetchingEntityDetails = state =>
  get(state, 'addExpense.isFetchingEntityDetails');
const selectViewList = state => get(state, 'addExpense.viewList');
const selectWorkflowList = state => get(state, 'addExpense.workflowList');
const selectProjectCodeList = state => get(state, 'addExpense.projectCodeList');
const selectSiteIdList = state => get(state, 'addExpense.siteIdList');
const isFetchingAdvanceDetails = state =>
  get(state, 'addExpense.isFetchingAdvanceDetails');
const selectAdvanceList = state => get(state, 'addExpense.advanceList');
const selectItemData = state => get(state, 'addExpense.itemData');
const selectDynamicColumns = state => get(state, 'addExpense.dynamicColumns');
const selectNewExpenseFormValues = state =>
  get(state, 'addExpense.newExpenseFormValues');
const selectExpenseFormValues = state =>
  get(state, 'addExpense.expenseFormValues');
const selectNewItemFormValues = state =>
  get(state, 'addExpense.newItemFormValues');
const selectSelectedCategory = state =>
  get(state, 'addExpense.selectedCategory');
const selectExpense = state => get(state, 'addExpense.expense');
const selectExpenseUpdateResponse = state =>
  get(state, 'addExpense.expenseUpdateResponse');
const expensePromise = state => get(state, 'addExpense.promise.expense');
const updateExpensePromise = state =>
  get(state, 'addExpense.promise.updateExpense');
const getSiteCodesList = state => get(state, 'addExpense.siteCodeList');
const taskExpensePromise = state =>
  get(state, 'addExpense.promise.taskExpense');
const selectExpenseApprovalResponse = state =>
  get(state, 'addExpense.expenseApprovalResponse');

export {
  addExpenseDetailsPromise,
  getAddExpenseDetails,
  getCategoriesTree,
  selectViewList,
  selectWorkflowList,
  selectProjectCodeList,
  selectSiteIdList,
  isFetchingEntityDetails,
  isFetchingAdvanceDetails,
  selectAdvanceList,
  selectItemData,
  selectDynamicColumns,
  selectNewExpenseFormValues,
  selectExpenseFormValues,
  selectNewItemFormValues,
  getCategories,
  selectSelectedCategory,
  selectExpense,
  selectExpenseUpdateResponse,
  expensePromise,
  updateExpensePromise,
  getSiteCodesList,
  getSelectedTask,
  taskExpensePromise,
  selectExpenseApprovalResponse,
};
