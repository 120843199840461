import React, { Component } from 'react';
import { Treebeard } from 'react-treebeard';
import style from './theme';

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(node, toggled) {
    if (this.state.cursor) {
      this.setState({ cursor: { ...this.state.cursor, active: false } });
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    this.setState({ cursor: node });

    if (!node.children) {
      this.props.handleCategorySelection(node);
    }
  }

  render() {
    return (
      <Treebeard
        data={this.props.data}
        onToggle={this.onToggle}
        style={style}
      />
    );
  }
}

export default Tree;
