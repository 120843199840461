/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Button, Callout, Intent, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as Yup from 'yup';
import FormInput from '../../../common/form/FormInput';
import '../style.scss';

const NewSalesOrder = newProps => {
  const {
    t,
    history,
    itemData,
    setSalesOrderItemData,
    newItemValues,
    deleteInSalesOrderItemData,
  } = newProps;

  const {
    taxColumns,
    item,
    approvalMode,
    index,
    currencySymbol,
  } = history.location.state;
  const indexExists = index || index === 0;
  const initialValues =
    (approvalMode && item) ||
    (indexExists ? itemData && itemData[index] : { ...newItemValues });
  const searchedItem = item && !!item.primaryKey;
  const disablePrice = item && !!item.price;

  const calculateNetPrice = values =>
    parseFloat(values.price || 0) * parseFloat(values.qty || 0);
  const calculateTaxAmount = values =>
    ((parseFloat(values.sgst || 0) +
      parseFloat(values.cgst || 0) +
      parseFloat(values.igst || 0) +
      parseFloat(values.otherTax || 0)) *
      calculateNetPrice(values)) /
    100;
  const calculateTotalAmount = values =>
    calculateNetPrice(values) + calculateTaxAmount(values);

  const calculateEverything = (values, setFieldValue) => {
    if (values.sellPriceWithTax)
      setUnitPrice(values.sellPriceWithTax, values, setFieldValue);
    setFieldValue('netPrice', calculateNetPrice(values));
    setFieldValue('taxAmount', calculateTaxAmount(values));
    setFieldValue('totalAmount', calculateTotalAmount(values));
  };

  const setUnitPrice = (sellPriceWithTax, values, setFieldValue) => {
    const parsedValue = parseFloat(sellPriceWithTax) || 0;
    if (parsedValue) {
      const tax =
        parseFloat(values.sgst || 0) +
        parseFloat(values.cgst || 0) +
        parseFloat(values.igst || 0) +
        parseFloat(values.otherTax || 0);
      const sellPrice = parseFloat(parsedValue * (100 / (100 + tax)));
      values.price = sellPrice;
      setFieldValue('price', sellPrice);
    }
  };

  const setTaxFields = (value, setFieldValue) => {
    if (taxColumns) {
      taxColumns.find(e => e === 'IGST') && setFieldValue('igst', value);
      taxColumns.find(e => e === 'CGST') && setFieldValue('cgst', value);
      taxColumns.find(e => e === 'SGST') && setFieldValue('sgst', value);
    }
  };

  const onSubmit = (values, actions) => {
    const data = {
      ...values,
      taxName: taxColumns,
    };
    setSalesOrderItemData(data, indexExists, index, history);
    actions.setSubmitting(false);
  };

  const validations = Yup.object().shape({
    itemNo: Yup.string().required(
      t('salesOrder.addItem.newItem.validations.itemNo'),
    ),
    desc: Yup.string().required(
      t('salesOrder.addItem.newItem.validations.desc'),
    ),
    qty: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.qty'))
      .min(1, t('salesOrder.addItem.newItem.validations.minQty')),
    price: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.price'))
      .min(1, t('salesOrder.addItem.newItem.validations.minPrice')),
    uom: Yup.string().required(t('salesOrder.addItem.newItem.validations.uom')),
    igst: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.igst'))
      .min(0, t('salesOrder.addItem.newItem.validations.minIgst'))
      .max(100, t('salesOrder.addItem.newItem.validations.maxIgst')),
    cgst: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.cgst'))
      .min(0, t('salesOrder.addItem.newItem.validations.minCgst'))
      .max(100, t('salesOrder.addItem.newItem.validations.maxCgst')),
    sgst: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.sgst'))
      .min(0, t('salesOrder.addItem.newItem.validations.minSgst'))
      .max(100, t('salesOrder.addItem.newItem.validations.maxSgst')),
    otherTax: Yup.number()
      .required(t('salesOrder.addItem.newItem.validations.vat'))
      .min(0, t('salesOrder.addItem.newItem.validations.minVat'))
      .max(100, t('salesOrder.addItem.newItem.validations.maxVat')),
  });

  return (
    <div className="AddItem container">
      <Callout
        intent={Intent.PRIMARY}
        icon={IconNames.INFO_SIGN}
        title={approvalMode ? 'Item Details' : 'Add an Item'}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validations}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
          } = props;
          return (
            <Form className="AddItem__Form">
              <Field
                className="AddItem__Form--fields"
                id="itemNo"
                label={t('salesOrder.addItem.newItem.itemNo')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={approvalMode || searchedItem}
                large
                value={values.itemNo}
                placeholder={t('salesOrder.addItem.placeholders.itemNo')}
                helperText={
                  errors.itemNo && touched.itemNo ? errors.itemNo : null
                }
                intent={
                  errors.itemNo && touched.itemNo ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddItem__Form--fields"
                id="desc"
                label={t('salesOrder.addItem.newItem.item')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                disabled={approvalMode || searchedItem}
                value={values.desc}
                placeholder={t('salesOrder.addItem.placeholders.desc')}
                helperText={errors.desc && touched.desc ? errors.desc : null}
                intent={
                  errors.desc && touched.desc ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddItem__Form--fields"
                id="qty"
                label={t('salesOrder.addItem.newItem.quantity')}
                component={FormInput}
                onChange={handleChange}
                onBlur={() => calculateEverything(values, setFieldValue)}
                large
                type="number"
                disabled={approvalMode}
                value={values.qty}
                helperText={errors.qty && touched.qty ? errors.qty : null}
                intent={errors.qty && touched.qty ? Intent.DANGER : Intent.NONE}
              />
              <Field
                className="AddItem__Form--fields"
                id="sellPriceWithTax"
                label={t('salesOrder.addItem.newItem.sellPriceWithTax')}
                component={FormInput}
                onChange={handleChange}
                onBlur={() => calculateEverything(values, setFieldValue)}
                large
                type="number"
                disabled={approvalMode}
                value={values.sellPriceWithTax}
                helperText={
                  errors.sellPriceWithTax && touched.sellPriceWithTax
                    ? errors.sellPriceWithTax
                    : null
                }
                intent={
                  errors.sellPriceWithTax && touched.sellPriceWithTax
                    ? Intent.DANGER
                    : Intent.NONE
                }
              />
              <Field
                className="AddItem__Form--fields"
                id="price"
                label={t('salesOrder.addItem.newItem.unitPrice')}
                component={FormInput}
                onChange={handleChange}
                onBlur={() => calculateEverything(values, setFieldValue)}
                large
                type="number"
                disabled={approvalMode || disablePrice}
                value={values.price && values.price.toFixed(2)}
                rightElement={<Tag minimal>{currencySymbol}</Tag>}
                helperText={errors.price && touched.price ? errors.price : null}
                intent={
                  errors.price && touched.price ? Intent.DANGER : Intent.NONE
                }
              />
              <Field
                className="AddItem__Form--fields"
                id="netPrice"
                label={t('salesOrder.addItem.newItem.netPrice')}
                component={FormInput}
                onChange={() => calculateEverything(values, setFieldValue)}
                onBlur={handleBlur}
                large
                rightElement={<Tag minimal>{currencySymbol}</Tag>}
                type="number"
                disabled={true}
                value={values.netPrice && values.netPrice.toFixed(2)}
              />
              <Field
                className="AddItem__Form--fields"
                id="uom"
                label={t('salesOrder.addItem.newItem.uom')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                disabled={approvalMode || searchedItem}
                value={values.uom}
                placeholder={t('salesOrder.addItem.placeholders.uom')}
                helperText={errors.uom && touched.uom ? errors.uom : null}
                intent={errors.uom && touched.uom ? Intent.DANGER : Intent.NONE}
              />
              <Field
                className="AddItem__Form--fields"
                id="hsnCode"
                label={t('salesOrder.addItem.newItem.hsn')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                disabled={approvalMode || searchedItem}
                value={values.hsnCode}
                placeholder={t('salesOrder.addItem.placeholders.hsnCode')}
              />
              {taxColumns && taxColumns.find(e => e === 'IGST') && (
                <Field
                  className="AddItem__Form--fields"
                  id="igst"
                  label={t('salesOrder.addItem.newItem.igst')}
                  component={FormInput}
                  onChange={e => setTaxFields(e.target.value, setFieldValue)}
                  onBlur={() => calculateEverything(values, setFieldValue)}
                  large
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  disabled={approvalMode || searchedItem}
                  value={values.igst}
                  helperText={errors.igst && touched.igst ? errors.igst : null}
                  intent={
                    errors.igst && touched.igst ? Intent.DANGER : Intent.NONE
                  }
                />
              )}
              {taxColumns && taxColumns.find(e => e === 'VAT') && (
                <Field
                  className="AddItem__Form--fields"
                  id="otherTax"
                  label={t('salesOrder.addItem.newItem.vat')}
                  component={FormInput}
                  onChange={handleChange}
                  onBlur={() => calculateEverything(values, setFieldValue)}
                  large
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  disabled={approvalMode || searchedItem}
                  value={values.otherTax}
                  helperText={
                    errors.otherTax && touched.otherTax ? errors.otherTax : null
                  }
                  intent={
                    errors.otherTax && touched.otherTax
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                />
              )}
              {taxColumns && taxColumns.find(e => e === 'SGST') && (
                <Field
                  className="AddItem__Form--fields"
                  id="sgst"
                  label={t('salesOrder.addItem.newItem.sgst')}
                  component={FormInput}
                  onChange={e => setTaxFields(e.target.value, setFieldValue)}
                  onBlur={() => calculateEverything(values, setFieldValue)}
                  large
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  disabled={approvalMode || searchedItem}
                  value={values.sgst}
                  helperText={errors.sgst && touched.sgst ? errors.sgst : null}
                  intent={
                    errors.sgst && touched.sgst ? Intent.DANGER : Intent.NONE
                  }
                />
              )}
              {taxColumns && taxColumns.find(e => e === 'CGST') && (
                <Field
                  className="AddItem__Form--fields"
                  id="cgst"
                  label={t('salesOrder.addItem.newItem.cgst')}
                  component={FormInput}
                  onChange={e => setTaxFields(e.target.value, setFieldValue)}
                  onBlur={() => calculateEverything(values, setFieldValue)}
                  large
                  type="number"
                  rightElement={<Tag minimal>%</Tag>}
                  disabled={approvalMode || searchedItem}
                  value={values.cgst}
                  helperText={errors.cgst && touched.cgst ? errors.cgst : null}
                  intent={
                    errors.cgst && touched.cgst ? Intent.DANGER : Intent.NONE
                  }
                />
              )}
              <Field
                className="AddItem__Form--fields"
                id="taxAmount"
                label={t('salesOrder.addItem.newItem.taxAmount')}
                component={FormInput}
                onChange={() => calculateEverything(values, setFieldValue)}
                onBlur={handleBlur}
                large
                rightElement={<Tag minimal>{currencySymbol}</Tag>}
                type="number"
                disabled={true}
                value={values.taxAmount && values.taxAmount.toFixed(2)}
              />
              <Field
                className="AddItem__Form--fields"
                id="totalAmount"
                label={t('salesOrder.addItem.newItem.totalAmount')}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                large
                rightElement={<Tag minimal>{currencySymbol}</Tag>}
                type="number"
                disabled={true}
                value={values.totalAmount && values.totalAmount.toFixed(2)}
              />
              {!approvalMode && (
                <Button
                  className="AddItem__Form--fields"
                  type="submit"
                  intent="success"
                  onClick={() => calculateEverything(values, setFieldValue)}
                  text={
                    indexExists
                      ? t('salesOrder.addItem.newItem.update')
                      : t('salesOrder.addItem.newItem.success')
                  }
                  large
                  fill
                  disabled={isSubmitting}
                />
              )}
              {!approvalMode && indexExists && (
                <Button
                  className="AddItem__Form--fields"
                  type="button"
                  intent="danger"
                  text={t('commons.delete')}
                  large
                  fill
                  disabled={isSubmitting}
                  onClick={() => deleteInSalesOrderItemData(index, history)}
                />
              )}
              <Button
                className="AddItem__Form--fields"
                type="button"
                text={t('commons.cancel')}
                large="true"
                disabled={isSubmitting}
                fill
                onClick={() => history.goBack()}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

NewSalesOrder.propTypes = {
  history: PropTypes.any.isRequired,
  setSalesOrderItemData: PropTypes.func.isRequired,
  deleteInSalesOrderItemData: PropTypes.func.isRequired,
};

NewSalesOrder.defaultProps = {
  categoriesTree: [],
  categories: [],
};

export default NewSalesOrder;
