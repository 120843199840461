import { connect } from 'react-redux';
import SearchSalesOrderItem from './SearchSalesOrderItem';
import {
  setSalesOrderSearchItemData,
  fetchItemSeachDetails,
  deleteInSalesOrderSearchItemData,
} from '../addSalesOrderActions';
import t from '../../../../shared/service/translations';
import { selectSearchItemList } from '../addSalesOrderSelector';

const mapStateToProps = state => {
  return {
    t,
    searchItemList: selectSearchItemList(state),
  };
};

const mapDispatchToProps = {
  setSalesOrderSearchItemData,
  fetchItemSeachDetails,
  deleteInSalesOrderSearchItemData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchSalesOrderItem);
