import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import SalesOrderDesktop from './desktop';
import t from '../../shared/service/translations';
import { Button, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

class SalesOrderComponent extends Component {
  state = {
    anchorEl: null,
  };

  componentWillMount() {
    this.props.fetchSalesOrderGrid();
  }

  render() {
    const { anchorEl } = this.state;
    const { history, salesOrderGrid, salesOrderPromise } = this.props;

    return (
      <div className="SalesOrder container">
        <h4>{t('salesOrder.header')}</h4>
        <SalesOrderDesktop
          rows={salesOrderGrid.rows || []}
          history={history}
          promise={salesOrderPromise}
        />

        <Button
          className="SalesOrder__Add"
          icon={<Icon icon={IconNames.PLUS} iconSize={Icon.SIZE_LARGE} />}
          intent={Intent.DANGER}
          minimal={true}
          onClick={() => history.push('/home/salesOrder/add')}
          aria-owns={anchorEl ? 'salesOrder-menu' : undefined}
          aria-haspopup="true"
        />
      </div>
    );
  }
}

SalesOrderComponent.propTypes = {
  fetchSalesOrderGrid: PropTypes.func.isRequired,
  salesOrderGrid: PropTypes.object.isRequired,
  salesOrderPromise: PropTypes.object.isRequired,
};

export default SalesOrderComponent;
