import React from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation } from '@blueprintjs/core';

const ClaimItem = props => {
  const { t, item, values, index, approvalMode } = props;

  return (
    <Card
      className="ExpenseItemList--Item"
      interactive
      elevation={Elevation.ZERO}
      onClick={() => {
        values.history.push({
          pathname: '/home/expense/add/newItem',
          state: { type: values.type, index, approvalMode, item },
        });
      }}
    >
      <div className="ExpenseItemList--Item__withDate">
        <span className="ExpenseItemList--Item__category bp3-text-overflow-ellipsis">
          {item.category}
        </span>
        <span className="bp3-text-muted">{item.date}</span>
      </div>
      <p className="bp3-text-overflow-ellipsis">
        <span className="bp3-text-muted">
          {t('expenses.addItem.newItem.merchant')}:{' '}
        </span>
        <span className="bp3-ui-text">{item.merchant}</span>
      </p>
      {item.file && (
        <p className="bp3-text-overflow-ellipsis">
          <span className="bp3-text-muted">
            {t('expenses.addItem.newItem.file')}:{' '}
          </span>
          <span className="bp3-ui-text">{item.file.name}</span>
        </p>
      )}
      <p className="bp3-text-muted bp3-text-overflow-ellipsis">
        {item.description}
      </p>
      <span className="bp3-tag .bp3-large bp3-minimal bp3-intent-success">
        {item.billable
          ? t('expenses.addItem.billable')
          : t('expenses.addItem.nonBillable')}{' '}
        {values.currencySymbol}
        {item.amount}
      </span>
    </Card>
  );
};

ClaimItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ClaimItem;
