import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

import LoginContainer from '../../components/login/LoginContainer';
import App from '../../components/app/App';
import configureStore from '../store';
import history from './history';
import HomeContainer from '../../components/home/HomeContainer';

const store = configureStore();

const Routes = () => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <Switch basename={window.location.pathname || ''}>
          <Route path="/login" component={LoginContainer} />
          <Redirect exact from="/home" to="/home/tasks" />
          <Route path="/home" component={HomeContainer} />
          <Route component={App} />
        </Switch>
      </Provider>
    </Router>
  );
};

export default Routes;
