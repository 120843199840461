import React from 'react';
import PropTypes from 'prop-types';
import '../pickUp.scss';
import globalConstants from '../../../../shared/constants';
import constants from '../../common/constants';
import Table from '../../../common/table/Table';
import { Button, Intent } from '@blueprintjs/core';

const PickUpLineDetails = props => {
  const { history } = props;
  const line = history.location.state.line;
  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 150,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = line => {
    const data = [
      {
        property: constants.labels.itemNo,
        value: line.itemNo,
      },
      {
        property: constants.labels.itemDescription,
        value: line.itemDescription,
      },
      {
        property: constants.labels.uom,
        value: line.uom,
      },
      {
        property: constants.labels.mrpQty,
        value: line.mrpQty,
      },
      {
        property: constants.labels.pickedupQty,
        value: line.PickedupQty,
      },
      {
        property: constants.labels.quantity,
        value: line.qty,
      },
      {
        property: constants.labels.bundleInfo,
        value: line.bundleInfo,
      },
    ];
    return data.filter(
      ele => ele.value !== null && ele.value !== '' && ele.value !== undefined,
    );
  };

  return (
    <div className="PO container PO__Line">
      <h4>{globalConstants.TITLES.LINE_DETAILS}</h4>
      <div className="PO__Line--mobile">
        <Table
          data={getMobileData(line)}
          columns={mobileColumns}
          pageSize={getMobileData(line).length}
          onClick={() => {}}
          disableHeader
        />
        <Button
          text="Back"
          className="PO__buttons"
          intent={Intent.PRIMARY}
          onClick={() => history.goBack()}
          large
          fill
        />
      </div>
    </div>
  );
};

PickUpLineDetails.propType = {
  line: PropTypes.object.isRequired,
};

export default PickUpLineDetails;
