import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Intent } from '@blueprintjs/core';

import './form.scss';

const FormFileInput = props => {
  const {
    disabled,
    helperText,
    inline,
    intent,
    label,
    labelInfo,
    text,
    id,
    onChange,
    multiple,
  } = props;
  return (
    <FormGroup
      className="ip-formGroup"
      disabled={disabled}
      helperText={helperText}
      inline={inline}
      intent={intent}
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
    >
      <label
        className={`${
          disabled ? 'bp3-disabled' : ''
        } bp3-file-input bp3-fill bp3-large`}
      >
        <input
          type="file"
          id={id}
          disabled={disabled}
          onChange={onChange}
          accept="image/*;capture=environment"
          multiple={multiple}
        />
        <span className="bp3-file-upload-input">{text}</span>
      </label>
    </FormGroup>
  );
};

FormFileInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  intent: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelInfo: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

FormFileInput.defaultProps = {
  disabled: false,
  helperText: '',
  inline: false,
  intent: Intent.NONE,
  label: '',
  labelInfo: '',
  text: 'Choose File',
  multiple: false,
};

export default FormFileInput;
