import { connect } from 'react-redux';
import POComponent from './component';
import t from '../../../shared/service/translations';
import { getPO, updatePO } from './actions';
import { selectPO, selectPOPromise } from './selector';

const mapStateToProps = state => {
  return {
    t,
    selectedTask: state.tasks.selectedTask,
    promise: selectPOPromise(state),
    errorMessage: state.po.errorMessage,
    po: selectPO(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPO(task) {
      dispatch(getPO(task));
    },
    updatePO(submitType, history) {
      dispatch(updatePO(submitType, history));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(POComponent);
