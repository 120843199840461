import React from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation } from '@blueprintjs/core';
import './styles.scss';

const SalesOrderItemList = props => {
  const { items, values, printMode } = props;
  return (
    <div className="SalesOrderItemList">
      <p className="SalesOrderItemList--title">Items</p>
      {items.map((item, index) => {
        return (
          <Card
            key={index}
            className="SalesOrderItemList--Item"
            interactive
            elevation={Elevation.ZERO}
            onClick={() => {
              !printMode &&
                values.history.push({
                  pathname: '/home/salesOrder/add/newSalesOrder',
                  state: {
                    index,
                    printMode,
                    item,
                    taxColumns: values.taxColumns,
                    currencySymbol: values.currencySymbol,
                  },
                });
            }}
          >
            <div className="SalesOrderItemList--Item__withDate">
              <span className="SalesOrderItemList--Item__category bp3-text-overflow-ellipsis">
                {item.desc}
              </span>
              <span className="bp3-text-muted">
                Qty: {item.qty && item.qty.toFixed(2)}
              </span>
            </div>
            <span className="bp3-tag .bp3-large bp3-minimal bp3-intent-success">
              {values.currencySymbol}
              {item.totalAmount ? item.totalAmount.toFixed(2) : 0.0}
            </span>
          </Card>
        );
      })}
    </div>
  );
};

SalesOrderItemList.propTypes = {
  items: PropTypes.array.isRequired,
};

SalesOrderItemList.defaultProps = {
  items: [],
};

export default SalesOrderItemList;
