import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Intent, Button, Callout } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import PickUpLine from './components/PickUpLine';
import PickUpFooter from './components/PickUpFooter';
import PickUpHeader from './components/PickUpHeader';
import { IconNames } from '@blueprintjs/icons';

class PickUpComponent extends Component {
  componentDidMount() {
    const history = this.props.history;
    const selectedTask =
      history.location.state && history.location.state.selectedTask;
    if (!selectedTask) this.props.history.push('/home/tasks');
    else this.props.getPickUp(selectedTask);
  }

  render() {
    const { pickUp, promise, history, updatePickUp } = this.props;
    const selectedTask =
      history.location.state && history.location.state.selectedTask;
    const onSubmit = (values, { setSubmitting }) => {
      values.companyId = selectedTask.companyId;
      values.id = selectedTask.pickUpRequestId;
      updatePickUp(values);
      setSubmitting(false);
    };

    return (
      promise.isFulfilled && (
        <Formik onSubmit={onSubmit}>
          {props => {
            const { values, isSubmitting, handleChange, handleSubmit } = props;
            return (
              <div className="PickUp container">
                <Form onSubmit={handleSubmit}>
                  <Callout
                    intent={Intent.PRIMARY}
                    icon={IconNames.INFO_SIGN}
                    title="Pickup"
                  >
                    Approve or Reject : <b>{selectedTask.requestNo}</b>
                  </Callout>

                  <PickUpHeader pickUp={pickUp} />
                  <PickUpLine
                    lines={pickUp.pickupLineItems}
                    history={history}
                  />
                  <PickUpFooter
                    pickUp={pickUp}
                    handleChange={handleChange}
                    values={values}
                  />
                  <div className="Form--fields PickUp__actions">
                    <Button
                      className="PO__buttons"
                      text="Approve"
                      type="submit"
                      large
                      disabled={isSubmitting}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        values.submitType = 'approve';
                      }}
                    />
                    <Button
                      className="PO__buttons"
                      text="Reject"
                      type="submit"
                      intent={Intent.DANGER}
                      large
                      disabled={isSubmitting}
                      onClick={() => {
                        values.submitType = 'reject';
                      }}
                    />
                    <Button
                      text="Cancel"
                      className="PO__buttons"
                      disabled={isSubmitting}
                      onClick={() => history.goBack()}
                      large
                    />
                  </div>
                </Form>
                {promise.isPending && (
                  <Spinner intent={Intent.PRIMARY} className="progress" />
                )}
              </div>
            );
          }}
        </Formik>
      )
    );
  }
}

PickUpComponent.propTypes = {
  getPickUp: PropTypes.func.isRequired,
  updatePickUp: PropTypes.func.isRequired,
  selectedTask: PropTypes.object.isRequired,
  pickUp: PropTypes.object.isRequired,
  promise: PropTypes.object.isRequired,
};

export default PickUpComponent;
