import React from 'react';
import PropTypes from 'prop-types';
import '../invoice.scss';
import FormTextArea from '../../../common/form/FormTextArea';

const InvoiceFooter = props => {
  const { invoice, handleChange } = props;
  return (
    <div className="Invoice__footer">
      <FormTextArea
        id="paymentTerms"
        label="Payment Terms"
        value={invoice.paymentTerms}
        name="paymentTerms"
        className="Invoice__footer--field"
        onChange={handleChange}
        large
      />
      <FormTextArea
        id="comments"
        label="Comments"
        value={invoice.comments}
        name="comments"
        className="Invoice__footer--field"
        onChange={handleChange}
        large
      />
    </div>
  );
};

InvoiceFooter.propType = {
  handleChange: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default InvoiceFooter;
