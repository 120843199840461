import { connect } from 'react-redux';
import TimesheetsComponent from './TimesheetsComponent';
import { fetchTimesheetsGrid } from './timesheetsActions';
import { selectTimesheetsGrid, timesheetsPromise } from './timesheetsSelector';

const mapStateToProps = state => {
  return {
    timesheetsGrid: selectTimesheetsGrid(state),
    timesheetsPromise: timesheetsPromise(state),
    errorMessage: state.timesheets.errorMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTimesheetsGrid: () => {
      dispatch(fetchTimesheetsGrid());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimesheetsComponent);
