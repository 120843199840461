import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Intent, Button } from '@blueprintjs/core';
import constants from '../../../shared/constants';
import AssetHeader from './components/AssetHeader';
import AssetLine from './components/AssetLine';
import AssetFooter from './components/AssetFooter';

class AssetComponent extends Component {
  componentDidMount() {
    this.props.getAsset(this.props.selectedTask);
  }

  render() {
    const {
      asset,
      promise,
      updateFieldValue,
      updateLineFieldValue,
      history,
      updateAsset,
      updateAssetFieldValue,
    } = this.props;

    const handleChange = event => {
      updateFieldValue({ key: event.target.name, value: event.target.value });
    };

    const handleLineItemChange = y => event => {
      updateLineFieldValue({
        index: y,
        key: event.target.name,
        value: event.target.value,
      });
    };

    const handleAssetUpdate = event => {
      updateAssetFieldValue({
        key: event.target.name,
        value: event.target.value,
      });
    };
    return (
      <div className="Asset container">
        {promise.isFulfilled && (
          <div>
            <h4>Asset Amendment</h4>
            <AssetHeader asset={asset} handleChange={handleChange} />
            <AssetLine
              handleLineItemChange={handleLineItemChange}
              lines={asset.assetLineItems}
            />
            <AssetFooter asset={asset} handleChange={handleAssetUpdate} />
            <div className="Form--fields Asset__actions">
              <Button
                text={constants.tasks.columns.cancel}
                className="Asset__buttons"
                onClick={() => history.goBack()}
                large
              />
              <Button
                text={constants.tasks.columns.reject}
                className="Asset__buttons"
                intent={Intent.DANGER}
                onClick={() =>
                  updateAsset(constants.tasks.actions.REJECT, history)
                }
                large
              />
              <Button
                text={constants.tasks.columns.completeApproval}
                className="Asset__buttons"
                intent={Intent.PRIMARY}
                onClick={() =>
                  updateAsset(constants.tasks.actions.APPROVE, history)
                }
                large
              />
            </div>
          </div>
        )}
        {promise.isPending && (
          <Spinner intent={Intent.PRIMARY} className="progress" />
        )}
      </div>
    );
  }
}

AssetComponent.propTypes = {
  getAsset: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  updateLineFieldValue: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
  selectedTask: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  promise: PropTypes.object.isRequired,
  updateAssetFieldValue: PropTypes.func.isRequired,
};

export default AssetComponent;
