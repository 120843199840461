import seamlessImmutable from 'seamless-immutable';
import * as moment from 'moment';
import setPromiseState from '../../../shared/service/promiseState';

const addTimesheetsInitialState = seamlessImmutable({
  promise: {
    addTimesheetsDetails: setPromiseState(),
  },
  addTimesheetsDetails: {},
  isFetchingEntityDetails: false,
  projectCodeList: [],
  workflowList: [],
  isFetchingItemData: false,
  itemData: [],
  newTimesheetsFormValues: {
    entityId: '',
    projectCodeId: '',
    workflowId: '',
    fromDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
    toDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
  },
  selectedCategory: {},
});

export default addTimesheetsInitialState;
