import React from 'react';
import PropTypes from 'prop-types';
import { Intent, Spinner } from '@blueprintjs/core';
import * as moment from 'moment';
import Table from '../common/table/Table';
import { filterString, getDateFromObject } from '../../shared/utils/string';

const TimesheetsDesktop = props => {
  const { rows, promise } = props;

  const columns = [
    {
      Header: 'Entity',
      accessor: 'ENTITY_NAME',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      accessor: 'PROJECT_CODE',
      Header: 'Project Code',
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'From Date',
      id: 'START_DATE',
      accessor: d =>
        moment(getDateFromObject(d.START_DATE_STRING)).format('MM/DD/YYYY'),
      filterMethod: (filter, row) => filterString(filter, row),
    },
    {
      Header: 'To Date',
      id: 'END_DATE',
      accessor: d => moment(getDateFromObject(d.END_DATE_STRING)).format('MM/DD/YYYY'),
      filterMethod: (filter, row) => filterString(filter, row),
    },
  ];

  const mobileColumns = [
    {
      Header: 'Property',
      accessor: 'property',
      width: 130,
      Cell: ci => {
        return `${ci.value}:`;
      },
      style: {
        whiteSpace: 'initial',
        textAlign: 'right',
        fontWeight: 'bold',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ci => {
        return `${ci.value}`;
      },
      style: {
        whiteSpace: 'initial',
        lineHeight: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  ];

  const getMobileData = task => {
    return [
      {
        property: 'Entity',
        value: task.ENTITY_NAME,
      },
      {
        property: 'Project Code',
        value: task.PROJECT_CODE,
      },
      {
        property: 'From Date',
        value: moment(getDateFromObject(task.START_DATE_STRING)).format('MM/DD/YYYY'),
      },
      {
        property: 'To Date',
        value: moment(getDateFromObject(task.END_DATE_STRING)).format('MM/DD/YYYY'),
      },
    ];
  };
  return (
    <div>
      {promise.isPending && (
        <Spinner intent={Intent.PRIMARY} className="progress" />
      )}
      {promise.isFulfilled &&
        (rows.length ? (
          rows.map((task, i) => (
            <div key={i} className="MyTimesheets__mobile">
              <Table
                data={getMobileData(task)}
                columns={mobileColumns}
                pageSize={getMobileData(task).length}
                onClick={() => {}}
                disableHeader
              />
            </div>
          ))
        ) : (
          <div className="MyTimesheets__mobile">
            <p
              style={{
                background: '#f9f7f7',
                height: '300px',
                textAlign: 'center',
                fontSize: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              No Records...
            </p>
          </div>
        ))}

      {promise.isFulfilled && (
        <div className="MyTimesheets__desktop">
          <Table data={rows} columns={columns} filterable onClick={() => {}} />
        </div>
      )}
    </div>
  );
};

TimesheetsDesktop.propTypes = {
  rows: PropTypes.array.isRequired,
  promise: PropTypes.object.isRequired,
};

export default TimesheetsDesktop;
