export const getString = value => value || '-';

export const getExactString = value => value || '';

export const getIntString = value => value || 0;

export const getDateString = value => {
  if (value) {
    const x = value.split('/');
    return `${x[2]}-${x[0]}-${x[1]}`;
  }
  return '';
};

export const filterString = (filter, row) =>
  row[filter.id] &&
  row[filter.id].toLowerCase().includes(filter.value.toLowerCase());

export const getDateFromObject = dateObj => {
  switch (typeof dateObj) {
    case 'object':
      return new Date(
        dateObj.year > 1900 ? dateObj.year : dateObj.year + 1900,
        dateObj.month,
        dateObj.date,
      );
    case 'string':
      return new Date(dateObj);
    case 'number':
      return new Date(dateObj);
    default:
      return;
  }
};
