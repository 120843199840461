import { get } from 'lodash';

const addSalesOrderDetailsPromise = state =>
  get(state, 'addSalesOrder.promise.addSalesOrderDetails');
const getAddSalesOrderDetails = state =>
  get(state, 'addSalesOrder.addSalesOrderDetails');
const getSelectedTask = state => get(state, 'tasks.selectedTask');
const isFetchingEntityDetails = state =>
  get(state, 'addSalesOrder.isFetchingEntityDetails');
const selectViewList = state =>
  get(state, 'addSalesOrder.customerDetails.viewList');
const selectQuotationList = state =>
  get(state, 'addSalesOrder.viewDetails.quotationList');
const selectSearchItemList = state =>
  get(state, 'addSalesOrder.searchItemDetails');
const selectWorkflowList = state => get(state, 'addSalesOrder.workflowList');
const selectOrderSeqList = state => get(state, 'addSalesOrder.orderSeqList');
const selectSiteIdList = state => get(state, 'addSalesOrder.siteIdList');
const isFetchingAdvanceDetails = state =>
  get(state, 'addSalesOrder.isFetchingAdvanceDetails');
const selectCustomerList = state => get(state, 'addSalesOrder.customerList');
const selectSalesOrderItemData = state => get(state, 'addSalesOrder.itemData');
const selectDynamicColumns = state =>
  get(state, 'addSalesOrder.dynamicColumns');
const selectNewSalesOrderFormValues = state =>
  get(state, 'addSalesOrder.newSalesOrderFormValues');
const selectSalesOrderFormValues = state =>
  get(state, 'addSalesOrder.salesOrderFormValues');
const selectNewItemFormValues = state =>
  get(state, 'addSalesOrder.newItemFormValues');
const selectSelectedCategory = state =>
  get(state, 'addSalesOrder.selectedCategory');
const selectSalesOrder = state => get(state, 'addSalesOrder.salesOrder');
const selectSalesOrderUpdateResponse = state =>
  get(state, 'addSalesOrder.salesOrderUpdateResponse');
const salesOrderPromise = state =>
  get(state, 'addSalesOrder.promise.salesOrder');
const updateSalesOrderPromise = state =>
  get(state, 'addSalesOrder.promise.updateSalesOrder');
const getSiteCodesList = state => get(state, 'addSalesOrder.siteCodeList');
const taskSalesOrderPromise = state =>
  get(state, 'addSalesOrder.promise.taskSalesOrder');
const selectSalesOrderApprovalResponse = state =>
  get(state, 'addSalesOrder.salesOrderApprovalResponse');

export {
  addSalesOrderDetailsPromise,
  getAddSalesOrderDetails,
  selectViewList,
  selectWorkflowList,
  selectOrderSeqList,
  selectSiteIdList,
  isFetchingEntityDetails,
  isFetchingAdvanceDetails,
  selectCustomerList,
  selectSalesOrderItemData,
  selectDynamicColumns,
  selectNewSalesOrderFormValues,
  selectSalesOrderFormValues,
  selectNewItemFormValues,
  selectSelectedCategory,
  selectSalesOrder,
  selectSalesOrderUpdateResponse,
  salesOrderPromise,
  updateSalesOrderPromise,
  getSiteCodesList,
  getSelectedTask,
  taskSalesOrderPromise,
  selectSalesOrderApprovalResponse,
  selectQuotationList,
  selectSearchItemList,
};
